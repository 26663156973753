import React, { Component } from 'react';
import InputField from '../common/InputField';
import { connect } from 'react-redux';
import * as AuthAction from '../../store/actions/AuthAction';

import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { createMuiTheme } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";

import LinearProgress from '@material-ui/core/LinearProgress';
import { TrainRounded } from '@material-ui/icons';



const styles = theme => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    table: {
        // minWidth: 650,
    },
    tableHead: {
        background: "#b9b9b9"
    },

    row: {
        '&:hover': {
            background: "#CACACA"
        }
    },
    cell: {
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    modal: {
        display: 'block',
        position: 'absolute',
        overflow: 'scroll',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 500,
    },
    buttonback: {
        backgroundColor: "#b9b9b9",
        border: '0px solid #000',
        boxShadow: theme.shadows[0],
        padding: theme.spacing(1.5, 1.5, 1.5),

    },
    formControlS: {
        margin: theme.spacing(1),
        width: 120,
    },
    formControlM: {
        margin: theme.spacing(1),
        width: 300
    },
    formControlL: {
        margin: theme.spacing(1),
        width: 800
    },
    grammar: {
        width: 800
    },
    grammarPreview: {
        width: 800
    },
    cell_short: {
        fontSize: "10px",
        width: 50,
    },
    cell_medium: {
        fontSize: "10px",
        width: 100,
    },
    cell_clickable: {
        cursor: "pointer"
    },
    cell_searched: {
        background: "#FA8072"
    },
    gridItem: {
    },
    button: {
        background: 'white',
        borderRadius: 3,
        border: 0,
        color: 'black',
        padding: '0 20px',
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    container: {
        maxHeight: 600,
    },
});

const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
    },
})



class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            UsernameOrEmail: "",
            Password: "",
            OpenPasswordModal: false,
            email: '',
            emailvalid: false,
            emailInvalid: false,
            message:null,
            sendingEmail:false
        }
    }

    handleModalClose = () => {
        this.setState({ OpenPasswordModal: false })
    };


    render() {


        const { classes } = this.props;

        return (
            <div id="layoutAuthentication">
                <div id="layoutAuthentication_content">
                    <main>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5">
                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-header"><h3 className="text-center font-weight-light my-4">Accesso</h3></div>
                                        <div className="card-body">
                                            <form>
                                                <InputField
                                                    label="Username or Email"
                                                    id="UsernameOrEmail"
                                                    type="text"
                                                    placeholder="Enter username or email address"
                                                    value={this.state.UsernameOrEmail}
                                                    onChange={(e) => { 
                                                        this.setState({ "UsernameOrEmail": e.target.value }) 
                                                        this.setState({ "message": null }) 
                                                    }}
                                                />
                                                <InputField
                                                    label="Password"
                                                    id="Password"
                                                    type="password"
                                                    placeholder="Enter password"
                                                    value={this.state.Password}
                                                    onChange={(e) => { 
                                                        this.setState({ "Password": e.target.value }) 
                                                        this.setState({ "message": null }) 
                                                    }}
                                                />
                                                <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                                                    <button type="button" className="btn btn-secondary" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({ OpenPasswordModal: true })

                                                    }}>Forgot Password?</button>
                                                    <button type="button" className="btn btn-primary" onClick={() => {
                                                        this.props.loginAction(
                                                            this.state.UsernameOrEmail, 
                                                            this.state.Password, 
                                                            (message)=>{
                                                                this.setState({"message":message})
                                                            });
                                                    }}>Login</button>
                                                </div>
                                                {this.state.message &&
                                                <p><b><font color="#F50057">{this.state.message}</font></b></p>}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div id="layoutAuthentication_footer">
                    <footer >
                        <div >
                            <div className="d-flex align-items-center justify-content-between small">
                                <div className="text-muted">Copyright &copy; izan-jp.com</div>
                            </div>
                        </div>
                    </footer>
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.state.OpenPasswordModal}
                    onClose={this.handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.OpenPasswordModal}>
                        <div className={classes.modalpaper}>
                            <form className={classes.root} >
                                <Grid container justify="left"  >
                                    <Grid item xs={12}  >
                                        <h4 id="transition-modal-title">Ripristina password</h4>
                                    </Grid>

                                    <Grid item xs={12}  >
                                        <FormControl className={classes.formControlM}>
                                            <TextField
                                                id="standard-basic"
                                                label='email'
                                                value={this.state.email}
                                                onChange={(e) => {
                                                    e.preventDefault()

                                                    this.setState({ emailvalid: false })
                                                    this.setState({ emailInvalid: false })
                                                    this.setState({ email: e.target.value })
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} >
                                        <Button variant="contained" disabled={this.state.submitDisabled}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ sendingEmail : TrainRounded })
                                                this.props.resetPassword(
                                                    this.state.email,
                                                    (result) => {

                                                        this.setState({ sendingEmail : false })
                                                        if (result) {
                                                            this.setState({ emailvalid: true })
                                                        } else {
                                                            this.setState({ emailInvalid: true })
                                                        }
                                                    })
                                                //this.handleModalClose()
                                            }}>Invia email per reimpostare la password</Button>
                                    </Grid>
                                    <Grid item xs={12} >
                                        {this.state.sendingEmail && <LinearProgress/>}
                                        {this.state.emailvalid && <h4>L'email è stata inviata</h4>}
                                        {this.state.emailInvalid && <h4>L'utente con l'email : {this.state.email} non è registrato </h4>}
                                    </Grid>
                                </Grid >
                            </form>
                        </div>
                    </Fade>
                </Modal >
            </div>
        );
    }




}


const mapStateToProps = (state) => {


}

const mapDispatchToProps = (dispatch) => {
    return {
        loginAction: function (usernameOrEmail, password,cb) {
            dispatch(AuthAction.login(usernameOrEmail, password,cb))
        },
        resetPassword: (email, cb) => {
            dispatch(AuthAction.resetPassword(email, cb))
        }
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Login));