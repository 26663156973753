import React, { Component } from 'react'
import { DropzoneDialog } from 'material-ui-dropzone'
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux';
import * as WebSocketAction from '../../../store/actions/WebSocketAction';
import * as FileManageAction from '../../../store/actions/FileManageAction';
import * as ModelAction from '../../../store/actions/ModelAction';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Searcher from '../../common/Searcher'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SendMailModal from '../../common/SendMailModal';
import * as StrTool from '../../../util/StrTool';
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';


import { modelProps as modelPropsCantieri } from '../../../model/Cantieri'

import { modelProps as modelPropsClienti } from '../../../model/Clienti'



import { modelProps as modelPropsEmailPreventivi } from '../../../model/EmailPreventivi'
import { modelProps as modelPropsPreventivi } from '../../../model/Preventivi'


import { styles } from '../../css/commonstyles'
class Preventivi extends Component {
    constructor(props) {
        super(props);
        //this.state = {
        //    open: false,
        //    files: [],
        //    pageSize: 100,
        //    afterPagenation: false,
        //    numDataCantieri: 300,
        //    Cantieri: null
        //};


        this.state = {
            open: false,
            files: [],
            page: 1,
            numData: modelPropsPreventivi.DefaultNum ? modelPropsPreventivi.DefaultNum : 200,
            searchedNum: 0,
            searchText: '',
            Cantieri: null

        };
    }

    componentDidMount() {


        this.props.getModelCount(modelPropsPreventivi, this.props.logininfo.id)
        this.props.getModelList(modelPropsPreventivi, 1, this.props.modelList[modelPropsPreventivi.modelname + "_count"], this.props.logininfo.id);

        this.props.getModelCount(modelPropsCantieri, this.props.logininfo.id)
        this.props.getModelList(modelPropsCantieri, 1, this.props.modelList[this.props.modelName + "_count"], this.props.logininfo.id);

        //Clientiを全件取得
        this.props.getModelListALL(modelPropsClienti, this.props.logininfo.id);
        //EmailPreventiviを全件取得
        //this.props.getModelListALL(modelPropsEmailPreventivi, this.props.logininfo.id);

    }

    onChangeInputNumData = (e) => {
        e.preventDefault();

        var num = e.target.value;

        var total = this.props.modelList[modelPropsPreventivi.modelname + "_count"]

        if (num >= total) {
            num = total;
        } else {
            //全件１ページ表示字のみ検索可
            this.setState({ searchword: '' })

        }

        this.setState({ numData: num });

        if (num == null || num == "") {
            this.props.getModelList(modelPropsPreventivi, this.state.page, 1, this.props.logininfo.id);
        } else {
            this.props.getModelList(modelPropsPreventivi, this.state.page, num, this.props.logininfo.id);
        }

    }

    onChangePagination = (page) => {
        this.setState({ page: page });
        this.props.getModelList(modelPropsPreventivi, page, this.state.numData, this.props.logininfo.id);
    }

    handleClose() {
        this.setState({
            open: false
        });
    }

    handleSave(files) {
        //Saving files to state for further use and closing Modal.
        this.setState({
            files: files,
            open: false
        });

        //this.props.uploadfile(this.props.socket, 'Util', this.state.files[0], this.props.logininfo.id)
        this.props.uploadfilewithcd(
            this.props.socket,
            files[0],
            this.props.logininfo.id,
            this.state.Cantieri.id,
            modelPropsPreventivi,
            1,
            this.state.numData
        )
    }

    handleOpen() {
        this.setState({
            open: true,
        });
    }


    getEmailAndNameOfClienti = (cantieri) => {

        if (cantieri == null) {
            return null
        } else {
            var email = '';
            var name = '';

            this.props.modelList['Clienti'].forEach((c) => {

                if (c.id == cantieri.ClientiId) {
                    email = c['EMail']
                    if (c['Cognome'] != null) {
                        name = c['Nome'] + ' ' + c['Cognome']
                    } else {
                        name = c['Nome']
                    }
                }
            })

            if (email == null) {
                return null
            } else {
                return { email: email, name: name }
            }


        }
    }




    render() {


        const { classes } = this.props;

        const dialogTitle = () => (
            <>
                <h6>Cantieri* {this.state.Cantieri && this.state.Cantieri['Descrizione']}</h6>


            </>
        );

        return (
            <div className={classes.backgroundStylefafafa} >
                <div className={classes.spacingStyle3} >
                    <Grid container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start">

                        <Grid item xs={2}  >
                            <Searcher
                                modelProps={modelPropsCantieri}
                                onSelect={(cantieri) => {

                                    this.setState({ 'Cantieri': cantieri })



                                }}
                                onClear={() => {
                                    this.setState({ 'Cantieri': null })

                                }}
                                showpropArr={['Descrizione']} />

                        </Grid>
                        <Grid item xs={10}  >
                            <Button
                                disabled={this.state.Cantieri == null ? true : false}
                                onClick={this.handleOpen.bind(this)}>
                                Aggiungi
                            </Button>


                            <DropzoneDialog
                                dialogTitle={dialogTitle()}
                                cancelButtonText={"CANCEL"}
                                submitButtonText={"UPLOAD"}
                                open={this.state.open}
                                onSave={this.handleSave.bind(this)}
                                acceptedFiles={[
                                    'image/jpeg',
                                    'image/png',
                                    'application/pdf',
                                    'application/xml',
                                    'text/plain',
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                ]}
                                showPreviews={true}
                                showFileNamesInPreview={true}
                                maxFileSize={5000000}
                                onClose={this.handleClose.bind(this)}
                                filesLimit={1}
                                dropzoneText="support : .jpeg .png .pdf .txt .xlsx .xml"


                            />

                            <Grid item xs={12}  >
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    {this.state.Cantieri == null &&
                                        <Grid item xs={12}  >

                                            <Grid
                                                container
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="center"
                                            >
                                                <Pagination
                                                    onChange={(e, page) => { e.preventDefault(); this.onChangePagination(page) }}
                                                    page={this.state.page}
                                                    count={this.props.modelList
                                                        ? Math.ceil(this.props.modelList[modelPropsPreventivi.modelname + "_count"] / this.state.numData)
                                                        : 3}
                                                />
                                                <TextField
                                                    id="standard-basic"
                                                    label="number of datas"
                                                    value={this.state.numData}
                                                    onChange={(e) => { this.onChangeInputNumData(e) }}
                                                />
                                                <Typography variant="h6" >total:{this.props.modelList[modelPropsPreventivi.modelname + "_count"]}</Typography>
                                            </Grid>
                                        </Grid>
                                    }


                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <div >
                                    <TableContainer className={classes.container} >
                                        <Table stickyHeader aria-label="a dense table"  >
                                            <TableHead className={classes.tableHead} >
                                                <TableRow >
                                                    <TableCell key={'hd_filename'} align="left" style={{ backgroundColor: '#BDBDBD' }} ></TableCell>
                                                    <TableCell key={'hd_filename'} align="left" style={{ backgroundColor: '#BDBDBD' }} >File</TableCell>
                                                    <TableCell key={'hd_cantieri'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Cantieri</TableCell>
                                                    <TableCell key={'hd_uploadedat'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Uploaded</TableCell>
                                                    <TableCell key={'hd_'} align="left" style={{ backgroundColor: '#BDBDBD' }} ></TableCell>
                                                    <TableCell key={'hd_'} align="left" style={{ backgroundColor: '#BDBDBD' }} ></TableCell>
                                                    <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody >
                                                {this.props.modelList[modelPropsPreventivi.modelname].map((m) => {

                                                    var showData = false;

                                                    if (this.state.Cantieri == null) {
                                                        showData = true;
                                                    } else {

                                                        if (m['Cantieri'] && m['Cantieri'].id == this.state.Cantieri.id) {
                                                            showData = true;
                                                        }

                                                    }


                                                    if (showData) {

                                                        var emailResultPostFormDefault = {
                                                            'Preventivi': m
                                                        }

                                                        return (




                                                            <TableRow key={m.id} className={classes.row}>
                                                                <TableCell
                                                                    align="left"
                                                                    className={classNames(classes.cell, classes.cell_short, classes.cell_clickable)}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.props.downloadfile(this.props.socket, m['FileName'], m['GdriveFileId'], m['mimeType'], this.props.logininfo.id)
                                                                    }}>
                                                                    <ArrowDownwardIcon />
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    className={classNames(classes.cell)}
                                                                >
                                                                    {m['FileName']}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    className={classNames(classes.cell)}
                                                                >
                                                                    {m['Cantieri'] && m['Cantieri']['Descrizione']}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    className={classNames(classes.cell)}
                                                                >
                                                                    {m['UploadedAt']}
                                                                </TableCell>

                                                                <TableCell
                                                                    align="left"
                                                                    className={classNames(classes.cell)}
                                                                >

                                                                    {m['EmailPreventivi'] && m['EmailPreventivi'].length > 0
                                                                        ? m['EmailPreventivi'].map((e) => {
                                                                            return (
                                                                                <div>{StrTool.createStrDD_MM_YYYY(e.DataInvio)} {e.Result} to {e.To}</div>
                                                                            )
                                                                        })
                                                                        : '-----'}


                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    className={classNames(classes.cell)}
                                                                >
                                                                    <SendMailModal
                                                                        From='euroalbekinfo<euroalbekapp@gmail.com>'
                                                                        To={this.getEmailAndNameOfClienti(m['Cantieri']) == null
                                                                            ? []
                                                                            : [this.getEmailAndNameOfClienti(m['Cantieri'])]}
                                                                        Subject={'preventivo (' + m['UploadedAt'] + ')'}
                                                                        Html={'here is preventivo...'}
                                                                        fileName={m['FileName']}
                                                                        fileId={m['GdriveFileId']}
                                                                        postFormDefault={emailResultPostFormDefault}
                                                                        getModelProps={modelPropsPreventivi}
                                                                        postModelProps={modelPropsEmailPreventivi}

                                                                    />
                                                                </TableCell>






                                                                <TableCell
                                                                    align="right"
                                                                    className={classNames(classes.cell, classes.cell_short)}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        if (window.confirm("deleting file : " + m['FileName'])) {

                                                                            this.props.deletefilewithcd(
                                                                                null,
                                                                                m['GdriveFileId'],
                                                                                this.props.logininfo.id,
                                                                                modelPropsPreventivi,
                                                                                1,
                                                                                this.state.numData)
                                                                        }
                                                                    }}>
                                                                    <DeleteForeverIcon className={classNames(classes.cell_clickable)} />
                                                                </TableCell>
                                                            </TableRow>

                                                        )



                                                    } else {

                                                        return null;
                                                    }


                                                })}


                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </Grid >

                        </Grid>
                    </Grid>




                </div>

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        modelList: state.model,
        socket: state.webSocket.socket,
        logininfo: state.auth.logininfo,
        gdrivefile: state.gdrivefile.GDriveFiles,
        gdriveNextPageToken: state.gdrivefile.GDriveFilesNextPageToken,
        group: 'uncategolized'  //TODO
    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        getModelListALL: (getModelProps, access_token) => {
            dispatch(ModelAction.apiGetALL(getModelProps, access_token))
        },

        deletefilewithcd: (socket, fileid, access_token, getModelProps, page, numData) => {

            dispatch(FileManageAction.DeleteFileWithCB(socket, fileid, access_token, getModelProps, page, numData))

        },
        downloadfile: (socket, filename, fileid, mimeType, access_token) => {

            dispatch(FileManageAction.DownloadFile(socket, filename, fileid, mimeType, access_token))

        },
        uploadfilewithcd: (socket, file, access_token, group, getModelProps, page, numData) => {

            dispatch(FileManageAction.UploadFileWithCB(socket, file, access_token, group, getModelProps, page, numData))

        },

        getModelList: (getModelProps, page, numData, access_token) => {
            dispatch(ModelAction.apiGet(getModelProps, page, numData, access_token))
        },
        getModelCount: (getModelProps, access_token) => {
            dispatch(ModelAction.apiGetCount(getModelProps, access_token))
        },






    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Preventivi));




