import React, { Component } from 'react';

import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import SimpleCrud from '../../common/SimpleCrud'
import { modelProps } from '../../../model/Clienti';




const styles = theme => ({

    root: {
        flexGrow: 1,
        margin: 20
    },
    paper: {
        height: 100,
        width: 800,
        margin: 10,
        padding: 10,
        '&:hover': {
            background: "#CACACA"
        }
    },
    control: {
        padding: theme.spacing(2),
    }
});


class Clienti extends Component {

    constructor(props) {
        super(props);

    }



    render() {

        const { classes } = this.props;

        return (
            <SimpleCrud
                modelName="Clienti"
                modelNameSingolare="Clienti"
                modelProps={modelProps}
            />
        );
    }
}


const mapStateToProps = (state) => {
    return {

    }

}

const mapDispatchToProps = (dispatch) => {

    return {


    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Clienti));