import React, { Component } from 'react';

import classNames from 'classnames';
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import CrudForUserStipendi from './crud/Stipendi/CrudForUserStipendi'
import { modelProps } from '../../../model/Stipendi';

import { styles } from '../../css/commonstyles'





class Stipendi extends Component {

    constructor(props) {
        super(props);

    }



    render() {

        const { classes } = this.props;

        return (
            <div className={classes.backgroundStylefafafa} >
                <div className={classes.spacingStyle3} >
                    <CrudForUserStipendi
                        modelName="Stipendi"
                        modelNameSingolare="Stipendi"
                        modelProps={modelProps}
                    />
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {

    }

}

const mapDispatchToProps = (dispatch) => {

    return {


    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Stipendi));