import React, { Component } from 'react';

import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import CrudForUserSpeseDitta from './crud/SpeseDitta/CrudForUserSpeseDitta'
import { modelProps } from '../../../model/SpeseDitta';

import { styles } from '../../css/commonstyles'





class SpeseDitta extends Component {

    constructor(props) {
        super(props);

    }



    render() {

        const { classes } = this.props;

        return (
            <div className={classes.backgroundStylefafafa} >
                <div className={classes.spacingStyle3} >
                    <CrudForUserSpeseDitta
                        modelName="SpeseDitta"
                        modelNameSingolare="SpeseDitta"
                        modelProps={modelProps}
                    />
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {

    }

}

const mapDispatchToProps = (dispatch) => {

    return {


    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SpeseDitta));