import React, { Component } from 'react'
import {DropzoneDialog} from 'material-ui-dropzone'
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux';
import * as WebSocketAction from '../../store/actions/WebSocketAction';
import * as FileManageAction from '../../store/actions/FileManageAction';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
 
 


const styles = theme => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    table: {
        // minWidth: 650,
    },
    tableHead: {
        background: "#b9b9b9"
    },

    row: {
        '&:hover': {
            background: "#CACACA"
        }
    },
    cell: {
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    modal: {
        display: 'block',
        position: 'absolute',
        overflow: 'scroll',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 900,
    },
    buttonback: {
        backgroundColor: "#b9b9b9",
        border: '0px solid #000',
        boxShadow: theme.shadows[0],
        padding: theme.spacing(1.5, 1.5, 1.5),

    },
    formControlS: {
        margin: theme.spacing(1),
        width: 120,
    },
    formControlM: {
        margin: theme.spacing(1),
        width: 300
    },
    formControlL: {
        margin: theme.spacing(1),
        width: 800
    },
    grammar: {
        width: 800
    },
    grammarPreview: {
        width: 800
    },
    cell_short: {
        fontSize: "10px",
        width: 50,
    },
    cell_medium: {
        fontSize: "10px",
        width: 100,
    },
    cell_clickable: {
        cursor: "pointer"
    },
    cell_searched: {
        background: "#FA8072"
    },
    gridItem: {
    },
    button: {
        background: 'white',
        borderRadius: 3,
        border: 0,
        color: 'black',
        padding: '0 20px',
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    container: {
        maxHeight: 600,
    },
});

class FileManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            files: [],
            pageSize: 100,
            afterPagenation: false
        };
    }

    componentDidMount() {

        console.log("componentDidMount")
        this.props.getfilelist(this.props.socket, this.props.logininfo.id,this.state.pageSize,null,this.props.group)
        
    }
 
    handleClose() {
        this.setState({
            open: false
        });
    }
 
    handleSave(files) {
        //Saving files to state for further use and closing Modal.
        this.setState({
            files: files,
            open: false
        });
        console.log(this.props.logininfo.id)
        //this.props.uploadfile(this.props.socket, 'Util', this.state.files[0], this.props.logininfo.id)
        this.props.uploadfile(this.props.socket, files[0], this.props.logininfo.id,this.state.pageSize,this.props.group)
    }
 
    handleOpen() {
        this.setState({
            open: true,
        });
    }
 
    render() {

        
        const { classes } = this.props;

        return (
            <div>
                <Button onClick={this.handleOpen.bind(this)}>
                  +
                </Button>
                <DropzoneDialog
                    cancelButtonText={"CANCEL"}
                    submitButtonText={"UPLOAD"}
                    open={this.state.open}
                    onSave={this.handleSave.bind(this)}
                    acceptedFiles={[
                        'image/jpeg', 
                        'image/png', 
                        'application/pdf', 
                        'application/xml',
                        'text/plain',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    ]}
                    showPreviews={true}
                    showFileNamesInPreview={true}
                    maxFileSize={5000000}
                    onClose={this.handleClose.bind(this)}
                    filesLimit={1}
                    dropzoneText="support : .jpeg .png .pdf .txt .xlsx .xml"

                  
                />
                {this.state.afterPagenation == false  //最初のページ 
                    ? this.props.gdriveNextPageToken
                        ? <Button onClick={(e)=>{
                            e.preventDefault();
                            this.setState({afterPagenation : true})
                            this.props.getfilelist(this.props.socket, this.props.logininfo.id,this.state.pageSize,this.props.gdriveNextPageToken,this.props.group)
                        }}>NEXT PAGE</Button> 
                        : null
                    : this.props.gdriveNextPageToken 
                        ? <Button onClick={(e)=>{
                            e.preventDefault();
                            this.setState({afterPagenation : true})
                            this.props.getfilelist(this.props.socket, this.props.logininfo.id,this.state.pageSize,this.props.gdriveNextPageToken,this.props.group)
                        }}>NEXT PAGE</Button>
                        : <Button onClick={(e)=>{
                            e.preventDefault();
                            this.setState({afterPagenation : true})
                            this.props.getfilelist(this.props.socket, this.props.logininfo.id,this.state.pageSize,this.props.gdriveNextPageToken,this.props.group)
                        }}>FIRST PAGE</Button>}
            <div class="card shadow mb-4">
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="left">name</TableCell>
                                        <TableCell align="left">uploaded</TableCell>
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="left"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.gdrivefile &&
                                     this.props.gdrivefile.map((f) => {
                                        return (
                                            <TableRow key={f.id} className={classes.row}>
                                                <TableCell
                                                    align="left"
                                                    className={classNames(classes.cell, classes.cell_short, classes.cell_clickable)}
                                                    onClick={(e) => { 
                                                        e.preventDefault();
                                                        this.props.downloadfile(this.props.socket,f['name'],f['id'],f['mimeType'],this.props.logininfo.id)
                                                    }}>
                                                    <ArrowDownwardIcon />
                                                </TableCell>
                                                <TableCell align="left" className={classNames(classes.cell)} >
                                                    {f['name']}
                                                </TableCell>
                                                <TableCell align="left" className={classNames(classes.cell)} >
                                                    {f['appProperties']['uploaded']}
                                                </TableCell>
                                                <TableCell align="left" className={classNames(classes.cell)}>
                                                    {f['']}
                                                </TableCell>
                                                <TableCell align="left" className={classNames(classes.cell)}>
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    className={classNames(classes.cell, classes.cell_short, classes.cell_clickable)}
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        if (window.confirm("deleting file : " + f['name'])){
                                                            this.props.deletefile(this.props.socket,f['id'],this.props.logininfo.id,this.state.pageSize,this.props.group)
                                                        }
                                                        
                                                    }}>
                                                    <DeleteForeverIcon />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}


                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>



            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        modelList: state.model,
        socket: state.webSocket.socket,
        logininfo: state.auth.logininfo,
        gdrivefile: state.gdrivefile.GDriveFiles,
        gdriveNextPageToken: state.gdrivefile.GDriveFilesNextPageToken,
        group:'uncategolized'  //TODO
    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        deletefile : (socket, fileid,access_token,pageSize,group)=>{

            dispatch(FileManageAction.DeleteFile(socket,fileid,access_token,pageSize,group))

        },
        downloadfile : (socket, filename,fileid, mimeType,access_token)=>{

            dispatch(FileManageAction.DownloadFile(socket,filename,fileid,mimeType,access_token))

        },
        uploadfile : (socket, file, access_token,pageSize,group)=>{

            dispatch(FileManageAction.UploadFile(socket, file, access_token,pageSize,group))

        },
        getfilelist : (socket,access_token,pageSize,pageToken,group)=>{

            //dispatch(FileManageAction.GetFileList(socket,  access_token))
            dispatch(FileManageAction.GetFileList(socket,  access_token,pageSize,pageToken,group))

        },






    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(FileManager));




