import * as NumberTool from '../../src/util/NumberTool'

export const modelProps = {

  "modelname": "Stipendi",
  "props": {
    "id": {
      "type": "string"
    },
    "Dal": {
      "type": "date"
    },
    "Al": {
      "type": "date"
    },
    "hLav": {
      "type": "number"
    },
    "CostoOrario": {
      "type": "number"
    },
    "Stipendio": {
      "type": "number"
    },
    "DataCalcoloStipendio": {
      "type": "date"
    },
    "DescrizioneStipendio": {
      "type": "string"
    },
    "Acconto1": {
      "type": "number",
    },
    "AccontoConfermato1": {
      "type": "date"
    },
    "Acconto2": {
      "type": "number",
    },
    "AccontoConfermato2": {
      "type": "date"
    },
    "Acconto3": {
      "type": "number",
    },
    "AccontoConfermato3": {
      "type": "date"
    },
    "Acconto4": {
      "type": "number",
    },
    "AccontoConfermato4": {
      "type": "date"
    },
    "Acconto5": {
      "type": "number",
    },
    "AccontoConfermato5": {
      "type": "date"
    },
    "Saldo": {
      "type": "number",
    },
    "DataPag": {
      "type": "date"
    },
    "PagatoConfermato": {
      "type": "date"
    },
    "Descrizione": {
      "type": "string"
    },
    "LastModifiedBy": {
      "type": "string"
    },
    "LastModifiedAt": {
      "type": "date"
    },
    "DipendentiId": {
      "type": "number"
    },
    "UserId": {
      "type": "number"
    }
  },
  "arrInclude": ["Dipendenti"],
  "order": ["DipendentiId"],



}

export const checkInputRequired = (formValue) => {



  console.log('ここheckInputRequired', formValue)

  var unfilledRequiredFIeld = false

  if (formValue.Dal == null) {
    unfilledRequiredFIeld = true
  }

  if (formValue.Dal === '') {
    unfilledRequiredFIeld = true
  }

  if (formValue.Al == null) {
    unfilledRequiredFIeld = true
  }

  if (formValue.Al === '') {
    unfilledRequiredFIeld = true
  }



  if (formValue.DipendentiId == null) {
    unfilledRequiredFIeld = true
  }

  if (formValue.Stipendio == null) {
    unfilledRequiredFIeld = true
  }

  //空文字と 0 を 等価演算子 (==) で比較すると true になる。0を許可したいので厳密等価演算子 (===) で比較
  if (formValue.Stipendio === '') {
    unfilledRequiredFIeld = true
  }



  return unfilledRequiredFIeld


}


export const calcSaldo = (Stipendio, Acconto1, Acconto2, Acconto3, Acconto4, Acconto5,
  AccontoConfermato1, AccontoConfermato2, AccontoConfermato3, AccontoConfermato4, AccontoConfermato5) => {

    var s = Stipendio
    if (AccontoConfermato1 != null) {
        s = NumberTool.calcSubtraction(s, Acconto1)
    }
    if (AccontoConfermato2 != null) {
        s = NumberTool.calcSubtraction(s, Acconto2)
    }
    if (AccontoConfermato3 != null) {
        s = NumberTool.calcSubtraction(s, Acconto3)
    }
    if (AccontoConfermato4 != null) {
        s = NumberTool.calcSubtraction(s, Acconto4)
    }
    if (AccontoConfermato5 != null) {
        s = NumberTool.calcSubtraction(s, Acconto5)
    }

    return s
 
}

