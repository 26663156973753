import React, { Component } from 'react';

import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import * as StipendiAction from '../../../../../store/actions/StipendiAction'
import { connect } from 'react-redux';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { createMuiTheme } from '@material-ui/core'
import * as DateTool from '../../../../../util/DateTool';
import * as StrTool from '../../../../../util/StrTool';
import Pagination from '@material-ui/lab/Pagination';
import CrudModalForUserCreateStipendi from './CrudModalForUserCreateStipendi'
import CrudModalForUserUpdateStipendi from './CrudModalForUserUpdateStipendi'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import RowAccontoForTable from '../../../../common/RowAccontoForTable'

import Button from '@material-ui/core/Button';



import { styles } from '../../../../css/commonstyles'

class CrudForUserDettagliModalita extends Component {

    constructor(props) {
        super(props);



        this.state = null;
        this.state = {
            page: 1,
            numData: this.props.modelProps.DefaultNum ? this.props.modelProps.DefaultNum : 200,
            searchedNum: 0,
            searchText: ''

        };

    }

    createMsgPagamento = (saldo) => {
        return 'Conferma pagamento di ' + saldo + ' euro?'
    }

    componentDidMount() {
        this.props.getModelCount(this.props.role == 'euroalbek' ? null : this.props.logininfo.userId, this.props.logininfo.id)
        this.props.getModelList(this.props.role == 'euroalbek' ? null : this.props.logininfo.userId, this.state.page, this.state.numData, this.props.logininfo.id);
        //this.props.setupWebSocket(this.props.logininfo.id);
    }



    onChangeInputNumData = (num) => {
        

        //var num = e.target.value;

        var total = this.props.modelList[this.props.modelName + "_count"]

        if (num >= total) {
            num = total;
        } else {
            //全件１ページ表示字のみ検索可
            this.setState({ searchText: '' })

        }

        this.setState({ numData: num });

        if (num == null || num === "") {
            this.props.getModelList(this.props.role == 'euroalbek' ? null : this.props.logininfo.userId, this.state.page, total, this.props.logininfo.id);
        } else {
            this.props.getModelList(this.props.role == 'euroalbek' ? null : this.props.logininfo.userId, this.state.page, num, this.props.logininfo.id);
        }

    }

    onChangePagination = (page) => {
        this.setState({ page: page });
        this.props.getModelList(this.props.role == 'euroalbek' ? null : this.props.logininfo.userId, page, this.state.numData, this.props.logininfo.id);
    }


    render() {

        var keys = Object.keys(this.props.modelProps);

        const { classes } = this.props;

        const nums = [];
        for (let i = 1; i <= 50; i++) {
            nums.push(i);
        }

        return (
            <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
            >

                <Grid item xs={12}  >
                    <Grid
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                    >
                        <Grid item xs={12}  >
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                            >
                                {this.props.role == "euroalbek" &&

                                    <CrudModalForUserCreateStipendi
                                        modelProps={this.props.modelProps}
                                        modelName={this.props.modelName}
                                    />

                                }
                                {this.props.role == "euroalbek" &&
                                    <div>
                                        <InputLabel htmlFor="input-text">cerca dipendenti...</InputLabel>
                                        <Input
                                            id="input-text"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            }
                                            onChange={(e) => {
                                                this.setState({ searchText: e.target.value })

                                                var total = this.props.modelList[this.props.modelName + "_count"]
                                                this.onChangeInputNumData(total)


                                            }}
                                            value={this.state.searchText}
                                            className={classNames(classes.formControlM)}
                                        />
                                    </div>
                                }


                                <Pagination
                                    onChange={(e, page) => { e.preventDefault(); this.onChangePagination(page) }}
                                    page={this.state.page}
                                    count={this.props.modelList
                                        ? Math.ceil(this.props.modelList[this.props.modelName + "_count"] / this.state.numData)
                                        : 3}
                                />
                                <TextField
                                    id="standard-basic"
                                    label="number of datas"
                                    value={this.state.numData}
                                    onChange={(e) => { e.preventDefault();
                                        this.onChangeInputNumData(e.target.value) }}
                                />
                                <Typography variant="h6" >total:{this.props.modelList[this.props.modelName + "_count"]}</Typography>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <div >
                                <TableContainer className={classes.container} >
                                    <Table stickyHeader aria-label="a dense table"  >
                                        <TableHead className={classes.tableHead} >
                                            <TableRow >
                                                {this.props.role == 'euroalbek' &&
                                                    <TableCell key={'hd_dipendenti'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Dipendenti</TableCell>
                                                }
                                                <TableCell key={'hd_Dal'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Dal</TableCell>
                                                <TableCell key={'hd_Al'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Al</TableCell>
                                                <TableCell key={'hd_Stipendio'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Stipendio</TableCell>
                                                <TableCell key={'hd_DescrizioneStipendio'} align="left" style={{ backgroundColor: '#BDBDBD' }} ></TableCell>
                                                <TableCell key={'hd_Acconto'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Acconto</TableCell>
                                                <TableCell key={'hd_Saldo'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Saldo</TableCell>
                                                <TableCell key={'hd_Pagato'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Pagato</TableCell>
                                                <TableCell key={'hd_Descrizione'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Descrizione</TableCell>
                                                {this.props.role == 'euroalbek' &&
                                                    <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }} ></TableCell>
                                                }
                                                {this.props.role == 'euroalbek' &&
                                                    <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }}></TableCell>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {this.props.modelList[this.props.modelName].map((m) => {

                                                var showData = false;

                                                if (this.state.searchText.trim() == '') {
                                                    showData = true;
                                                } else {

                                                    if (m['Dipendenti']['Nome'].toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }

                                                    if (m['Dipendenti']['Cognome'] != null &&
                                                        m['Dipendenti']['Cognome'].toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }

                                                    if (m['Dipendenti']['Cognome'] != null &&
                                                        (m['Dipendenti']['Nome'] + ' ' + m['Dipendenti']['Cognome']).toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }
                                                }


                                                if (showData) {
                                                    return (
                                                        <TableRow key={m.id} className={classes.row}>
                                                            {this.props.role == 'euroalbek' &&
                                                                <TableCell
                                                                    align="left"
                                                                    className={classNames(classes.cell)}
                                                                >
                                                                    {m['Dipendenti']['Nome'] + ' ' + m['Dipendenti']['Cognome']}
                                                                </TableCell>}
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {StrTool.createStrDD_MM_YYYY(m['Dal'])}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {StrTool.createStrDD_MM_YYYY(m['Al'])}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Stipendio']}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                <Grid container justify="left"  >
                                                                    <Grid item xs={12}  >
                                                                        {m['DescrizioneStipendio']}
                                                                    </Grid>
                                                                    <Grid item xs={12}  >
                                                                        {m['DataCalcoloStipendio'] && 'Data di calcolo:' + StrTool.createStrDD_MM_YYYY(m['DataCalcoloStipendio'])}

                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                <Grid container justify="left"  >
                                                                    <RowAccontoForTable
                                                                        m={m}
                                                                        index={1}
                                                                        numData={this.state.numData}
                                                                        page={this.state.page}
                                                                    />
                                                                    <RowAccontoForTable
                                                                        m={m}
                                                                        index={2}
                                                                        numData={this.state.numData}
                                                                        page={this.state.page}
                                                                    /><RowAccontoForTable
                                                                        m={m}
                                                                        index={3}
                                                                        numData={this.state.numData}
                                                                        page={this.state.page}
                                                                    /><RowAccontoForTable
                                                                        m={m}
                                                                        index={4}
                                                                        numData={this.state.numData}
                                                                        page={this.state.page}
                                                                    /><RowAccontoForTable
                                                                        m={m}
                                                                        index={5}
                                                                        numData={this.state.numData}
                                                                        page={this.state.page}
                                                                    />


                                                                </Grid>
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Saldo']}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >

                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="flex-end">
                                                                    <Grid item xs={12} >
                                                                        {StrTool.createStrDD_MM_YYYY(m['DataPag'])}
                                                                    </Grid>
                                                                    <Grid item xs={12} >
                                                                        {m['DataPag']
                                                                            ? m['PagatoConfermato']
                                                                                ? <span className={classNames(classes.color_blue)}>confermato {StrTool.createStrDD_MM_YYYY(m['PagatoConfermato'])}</span>
                                                                                : this.props.role == 'euroalbek'
                                                                                    ? <span className={classNames(classes.color_red)} >Da confermare</span>
                                                                                    : <Button
                                                                                        variant="contained"
                                                                                        className={classes.formControlXS}
                                                                                        onClick={(e) => {

                                                                                            var formValue = m
                                                                                            formValue["PagatoConfermato"] = DateTool.returnItalianTime();
                                                                                            console.log('formValue', formValue)

                                                                                            if (window.confirm(this.createMsgPagamento(m['Saldo']))) {
                                                                                                this.props.submitCreate(
                                                                                                    this.props.logininfo.userId,
                                                                                                    this.props.page,
                                                                                                    this.props.numData,
                                                                                                    formValue,
                                                                                                    this.props.logininfo.id)
                                                                                            }

                                                                                        }}>Confermo</Button>
                                                                            : null}
                                                                    </Grid>
                                                                </Grid>



                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Descrizione']}
                                                            </TableCell>
                                                            {this.props.role == 'euroalbek' &&
                                                                <TableCell
                                                                    align="left"
                                                                    className={classNames(classes.cell, classes.cell_short,)}
                                                                >
                                                                    <CrudModalForUserUpdateStipendi
                                                                        modelProps={this.props.modelProps}
                                                                        modelName={this.props.modelName}
                                                                        modelValue={m} />
                                                                </TableCell>
                                                            }
                                                            {this.props.role == 'euroalbek' &&
                                                                <TableCell
                                                                    align="right"
                                                                    className={classNames(classes.cell, classes.cell_short)}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();

                                                                        var name = this.props.role == 'euroalbek'
                                                                            ? m['Dipendenti']['Nome'] + ' ' + m['Dipendenti']['Cognome']
                                                                            : ''
                                                                        if (window.confirm("Vuoi eliminare il dato ? : "
                                                                            + StrTool.createStrDD_MM_YYYY(m['Dal'])
                                                                            + '-'
                                                                            + StrTool.createStrDD_MM_YYYY(m['Al'])
                                                                            + name)) {
                                                                            this.props.submitDelete(
                                                                                this.props.socket,
                                                                                this.props.modelProps,
                                                                                this.state.page,
                                                                                this.state.numData,
                                                                                m.id,
                                                                                this.props.modelProps,
                                                                                this.props.logininfo.id
                                                                            )
                                                                        }
                                                                    }}>
                                                                    <DeleteForeverIcon className={classNames(classes.cell_clickable)} />
                                                                </TableCell>
                                                            }
                                                        </TableRow>

                                                    )



                                                } else {

                                                    return null;
                                                }


                                            })}


                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Grid >
                    </Grid >
                </Grid>

            </Grid>





        );
    }
}


const mapStateToProps = (state) => {
    return {
        modelList: state.model,
        logininfo: state.auth.logininfo,
        role: state.auth.role

    }
}

const mapDispatchToProps = (dispatch) => {

    return {


        getModelList: (userId, page, numData, access_token) => {
            dispatch(StipendiAction.apiGet(userId, page, numData, access_token))
        },
        getModelCount: (userId, access_token) => {
            dispatch(StipendiAction.apiGetCount(userId, access_token))
        },
        submitDelete: (userId, numData, stipendiId, access_token) => {
            dispatch(StipendiAction.apiDelete(userId, numData, stipendiId, access_token))
        },
        submitCreate: (userId, page, numData, formValue, access_token) => {
            dispatch(StipendiAction.apiPostOrPut(userId, page, numData, formValue, access_token))
        },

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CrudForUserDettagliModalita));