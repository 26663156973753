import React, { Component } from 'react';

import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import * as ModelAction from '../../../../../store/actions/ModelAction'
import { connect } from 'react-redux';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { createMuiTheme } from '@material-ui/core'
import * as DateTool from '../../../../../util/DateTool';
import * as StrTool from '../../../../../util/StrTool';
import Pagination from '@material-ui/lab/Pagination';
import CrudModalForUserCreateDipendenti from './CrudModalForUserCreateDipendenti'
import CrudModalForUserUpdateDipendenti from './CrudModalForUserUpdateDipendenti'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { styles } from '../../../../css/commonstyles'





const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
    },
})




class CrudForUserDipendenti extends Component {

    constructor(props) {
        super(props);



        this.state = null;
        this.state = {
            page: 1,
            numData: this.props.modelProps.DefaultNum ? this.props.modelProps.DefaultNum : 200,
            searchedNum: 0,
            searchText: ''

        };

    }

    componentDidMount() {
        this.props.getModelCount(this.props.modelProps, this.props.logininfo.id)
        this.props.getModelList(this.props.modelProps, this.state.page, this.state.numData, this.props.logininfo.id);
        //this.props.setupWebSocket(this.props.logininfo.id);
    }



    onChangeInputNumData = (num) => {
       

        var total = this.props.modelList[this.props.modelName + "_count"]

        if (num >= total) {
            num = total;
        } else {
            //全件１ページ表示字のみ検索可
            this.setState({ searchText: '' })

        }

        this.setState({ numData: num });

        if (num == null || num == "") {
            this.props.getModelList(this.props.modelProps, this.state.page, total, this.props.logininfo.id);
        } else {
            this.props.getModelList(this.props.modelProps, this.state.page, num, this.props.logininfo.id);
        }

    }

    onChangePagination = (page) => {
        this.setState({ page: page });
        this.props.getModelList(this.props.modelProps, page, this.state.numData, this.props.logininfo.id);
    }





    render() {

        const { classes } = this.props;

        return (
            <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
            >

                <Grid item xs={12}  >
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={12}  >
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                            >

                                <CrudModalForUserCreateDipendenti
                                    modelProps={this.props.modelProps}
                                    modelName={this.props.modelName}
                                />

                                <div>
                                    <InputLabel htmlFor="input-text">cerca dipendenti...</InputLabel>
                                    <Input
                                        id="input-text"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        }
                                        onChange={(e) => {
                                            this.setState({ searchText: e.target.value })
                                            var total = this.props.modelList[this.props.modelName + "_count"]
                                            this.onChangeInputNumData(total)
                                        }}
                                        value={this.state.searchText}
                                        className={classNames(classes.formControlM)}
                                    />
                                </div>



                                <Pagination
                                    onChange={(e, page) => { e.preventDefault(); this.onChangePagination(page) }}
                                    page={this.state.page}
                                    count={this.props.modelList
                                        ? Math.ceil(this.props.modelList[this.props.modelName + "_count"] / this.state.numData)
                                        : 3}
                                />
                                <TextField
                                    id="standard-basic"
                                    label="number of datas"
                                    value={this.state.numData}
                                    onChange={(e) => { e.preventDefault();
                                        this.onChangeInputNumData(e.target.value) }}
                                />
                                <Typography variant="h6" >total:{this.props.modelList[this.props.modelName + "_count"]}</Typography>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <div >
                                <TableContainer className={classes.container} >
                                    <Table stickyHeader aria-label="a dense table"  >
                                        <TableHead className={classes.tableHead} >
                                            <TableRow >
                                                <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }} ></TableCell>
                                                <TableCell key={'hd_Nome'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Nome</TableCell>
                                                <TableCell key={'hd_Cognome'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Cognome</TableCell>
                                                <TableCell key={'hd_Indirizzo'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Indirizzo</TableCell>
                                                <TableCell key={'hd_Prov'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Prov</TableCell>
                                                <TableCell key={'hd_Localita'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Localita</TableCell>
                                                <TableCell key={'hd_CAP'} align="left" style={{ backgroundColor: '#BDBDBD' }} >CAP</TableCell>
                                                <TableCell key={'hd_DataDiNascita'} align="left" style={{ backgroundColor: '#BDBDBD' }} >DataDiNascita</TableCell>
                                                <TableCell key={'hd_ProvDiNascita'} align="left" style={{ backgroundColor: '#BDBDBD' }} >ProvDiNascita</TableCell>
                                                <TableCell key={'hd_LocalitaDiNascita'} align="left" style={{ backgroundColor: '#BDBDBD' }} >LocalitaDiNascita</TableCell>
                                                <TableCell key={'hd_CodFisc'} align="left" style={{ backgroundColor: '#BDBDBD' }} >CodFisc</TableCell>
                                                <TableCell key={'hd_PIva'} align="left" style={{ backgroundColor: '#BDBDBD' }} >PIva</TableCell>
                                                <TableCell key={'hd_IBAN'} align="left" style={{ backgroundColor: '#BDBDBD' }} >IBAN</TableCell>
                                                <TableCell key={'hd_Telefono1'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Telefono1</TableCell>
                                                <TableCell key={'hd_Telefono2'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Telefono2</TableCell>
                                                <TableCell key={'hd_Cel1'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Cel1</TableCell>
                                                <TableCell key={'hd_Cel2'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Cel2</TableCell>
                                                <TableCell key={'hd_FAX'} align="left" style={{ backgroundColor: '#BDBDBD' }} >FAX</TableCell>
                                                <TableCell key={'hd_EMail'} align="left" style={{ backgroundColor: '#BDBDBD' }} >EMail</TableCell>
                                                <TableCell key={'hd_CostoOrario'} align="left" style={{ backgroundColor: '#BDBDBD' }} >CostoOrario</TableCell>
                                                <TableCell key={'hd_CostoMensile'} align="left" style={{ backgroundColor: '#BDBDBD' }} >CostoMensile</TableCell>
                                                <TableCell key={'hd_Contratto'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Contratto</TableCell>
                                                <TableCell key={'hd_Matricola'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Matricola</TableCell>
                                                <TableCell key={'hd_DataAssunzione'} align="left" style={{ backgroundColor: '#BDBDBD' }} >DataAssunzione</TableCell>
                                                <TableCell key={'hd_DataLicenziamento'} align="left" style={{ backgroundColor: '#BDBDBD' }} >DataLicenziamento</TableCell>
                                                <TableCell key={'hd_UserId'} align="left" style={{ backgroundColor: '#BDBDBD' }} >UserId</TableCell>


                                                <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }}></TableCell>





                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {this.props.modelList[this.props.modelName].map((m) => {

                                                var showData = false;

                                                if (this.state.searchText.trim() == '') {
                                                    showData = true;
                                                } else {

                                                    if (m['Nome'].toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }

                                                    if (m['Cognome'] != null &&
                                                        m['Cognome'].toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }

                                                    if (m['Cognome'] != null &&
                                                        (m['Nome'] + ' ' + m['Cognome']).toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }
                                                }


                                                if (showData) {

                                                    return (
                                                        <TableRow key={m.id} className={classes.row}>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell, classes.cell_short,)}
                                                            >
                                                                <CrudModalForUserUpdateDipendenti
                                                                    modelProps={this.props.modelProps}
                                                                    modelName={this.props.modelName}
                                                                    modelValue={m} />
                                                            </TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['Nome']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['Cognome']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['Indirizzo']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['Prov']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['Localita']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['CAP']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{StrTool.createStrDD_MM_YYYY(m['DataDiNascita'])}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['ProvDiNascita']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['LocalitaDiNascita']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['CodFisc']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['PIva']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['IBAN']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['Telefono1']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['Telefono2']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['Cel1']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['Cel2']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['FAX']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['EMail']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['CostoOrario']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['CostoMensile']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['Contratto']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['Matricola']}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{StrTool.createStrDD_MM_YYYY(m['DataAssunzione'])}</TableCell>  {StrTool.createStrDD_MM_YYYY(m['DataPag'])}
                                                            <TableCell align="left" className={classNames(classes.cell)} >{StrTool.createStrDD_MM_YYYY(m['DataLicenziamento'])}</TableCell>
                                                            <TableCell align="left" className={classNames(classes.cell)} >{m['UserId']}</TableCell>
                                                            <TableCell
                                                                align="right"
                                                                className={classNames(classes.cell, classes.cell_short)}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (window.confirm("deleting data id : " + m['Nome'])) {
                                                                        this.props.submitDelete(
                                                                            this.props.socket,
                                                                            this.props.modelProps,
                                                                            this.state.page,
                                                                            this.state.numData,
                                                                            m.id,
                                                                            this.props.modelProps,
                                                                            this.props.logininfo.id
                                                                        )
                                                                    }
                                                                }}>
                                                                <DeleteForeverIcon className={classNames(classes.cell_clickable)} />
                                                            </TableCell>
                                                        </TableRow>

                                                    )



                                                } else {

                                                    return null;
                                                }


                                            })}


                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Grid >
                    </Grid >
                </Grid>

            </Grid>





        );
    }
}


const mapStateToProps = (state) => {
    return {
        modelList: state.model,
        socket: state.webSocket.socket,
        logininfo: state.auth.logininfo

    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        getModelList: (getModelProps, page, numData, arrInclude, order, access_token) => {
            dispatch(ModelAction.apiGet(getModelProps, page, numData, arrInclude, order, access_token))
        },
        getModelCount: (getModelProps, access_token) => {
            dispatch(ModelAction.apiGetCount(getModelProps, access_token))
        },
        submitDelete: (socket, getModelProps, page, numData, id, deleteModelProps, access_token) => {
            dispatch(ModelAction.apiDelete(socket, getModelProps, page, numData, id, deleteModelProps, access_token))
        },

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CrudForUserDipendenti));