export const modelProps = {

    "modelname": "EmailLavoriEseguiti",
    "props": {
        "DataInvio": {
            "type": "date"
        },
        "To": {
            "type": "string"
        },
        "Result": {
            "type": "string"
        },
        "Detail": {
            "type": "string"
        }
    }
}
