

const defaultState = {
    Artigiani: [],
    Cantieri: [],
    Clienti: [],
    DettagliModalita: [],
    Dipendenti: [],
    LavoriEseguiti: [],
    Presenze: [],
    Stipendi: [],
    SpeseDitta: [],
    EmailPreventivi: [],
    Preventivi: [],

}

export const model = (state = defaultState, action) => {

    console.log('action★★★★★★★', action.value)


    switch (action.type) {

        case 'GET_ALL_Artigiani':
            return { ...state, Artigiani: action.value }
        case 'GET_COUNT_Artigiani':
            return { ...state, Artigiani_count: action.value }

        case 'GET_ALL_Cantieri':
            return { ...state, Cantieri: action.value }
        case 'GET_COUNT_Cantieri':
            return { ...state, Cantieri_count: action.value }

        case 'GET_ALL_Clienti':
            return { ...state, Clienti: action.value }
        case 'GET_COUNT_Clienti':
            return { ...state, Clienti_count: action.value }


        case 'GET_ALL_DettagliModalita':
            return { ...state, DettagliModalita: action.value }
        case 'GET_COUNT_DettagliModalita':
            return { ...state, DettagliModalita_count: action.value }



        case 'GET_ALL_Dipendenti':
            return { ...state, Dipendenti: action.value }
        case 'GET_COUNT_Dipendenti':
            return { ...state, Dipendenti_count: action.value }


        case 'GET_ALL_LavoriEseguiti':
            return { ...state, LavoriEseguiti: action.value }
        case 'GET_COUNT_LavoriEseguiti':
            return { ...state, LavoriEseguiti_count: action.value }


        case 'GET_ALL_Presenze':
            return { ...state, Presenze: action.value }
        case 'GET_COUNT_Presenze':
            return { ...state, Presenze_count: action.value }


        case 'GET_ALL_Stipendi':
            return { ...state, Stipendi: action.value }
        case 'GET_COUNT_Stipendi':
            return { ...state, Stipendi_count: action.value }

        case 'GET_ALL_EmailPreventivi':
            return { ...state, EmailPreventivi: action.value }


        case 'GET_ALL_Preventivi':
            return { ...state, Preventivi: action.value }
        case 'GET_COUNT_Preventivi':
            return { ...state, Preventivi_count: action.value }


        case 'GET_ALL_SpeseDitta':
            return { ...state, SpeseDitta: action.value }
        case 'GET_COUNT_SpeseDitta':
            return { ...state, SpeseDitta_count: action.value }




        default: return state
    }
}

