import React, { Component } from 'react';

import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';


const styles = theme => ({

    paper: {
        height: 100,
        width: 800,
        margin: 10,
        padding: 10,
        '&:hover': {
            background: "#CACACA"
        }
    },
    control: {
        padding: theme.spacing(2),
    }
});


class Dashboard extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }





    render() {

        const { classes } = this.props;

        return (
            <div>
                <h1></h1>
                
            </div>

        );
    }
}


const mapStateToProps = (state) => {
    return {

        logininfo: state.auth.logininfo,

    }

}

const mapDispatchToProps = (dispatch) => {

    return {

    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Dashboard));