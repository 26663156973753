export const modelProps = {
  "modelname":"DettagliModalita",
  "props": {
    "id": {
      "type": "string"
    },
    "TipoPag": {
      "type": "select",
      "inputRequired": true,
      "option": [
        {
            "label": "assegno",
            "val": "assegno"
        },
        {
            "label": "c/c",
            "val": "c/c"
        }
    ],
    },
    "DataPag": {
      "type": "date"
    },
    "Importo": {
      "type": "number",
      "inputRequired": true
    },
    "DataScad": {
      "type": "date"
    },
    "Assegno": {
      "type": "number"
    },
    "LastModifiedBy": {
      "type": "string"
    },
    "LastModifiedAt": {
      "type": "date"
    },
    "DipendentiId": {
      "type": "number"
    },
    "ArtigianiId": {
      "type": "number"
    }
  },
  "arrInclude": ["Dipendenti","Artigiani"],
  "order": ["DataScad DESC"],

}


export const checkInputRequired = (formValue) => {

  var unfilledRequiredFIeld = false

  if (formValue.Dipendenti == null && formValue.Artigiani == null) {
    unfilledRequiredFIeld = true
  }

  if (formValue.Importo == null) {
    unfilledRequiredFIeld = true
  }

  return unfilledRequiredFIeld


}
