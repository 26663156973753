

const defaultState = {
    data_for_somma_Artigiani: [],
    data_for_somma_Dipendenti: [],
    data_for_somma_SpeseDitta: []


}

export const somma = (state = defaultState, action) => {


    switch (action.type) {

        case 'GET_SOMMA_Artigiani':
            return { ...state, data_for_somma_Artigiani: action.value }

        case 'GET_SOMMA_Dipendenti':
            return { ...state, data_for_somma_Dipendenti: action.value }


        case 'GET_SOMMA_SpeseDitta':
            return { ...state, data_for_somma_SpeseDitta: action.value }




        default: return state
    }
}
