import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import * as EmailAction from '../../store/actions/EmailAction'
import * as ModelAction from '../../store/actions/ModelAction'
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import * as DateTool from '../../util/DateTool';
import LinearProgress from '@material-ui/core/LinearProgress';
import SelectToModal from './SelectToModal'
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';


const styles = theme => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            // width: '25ch',
        },
    },
    table: {
        // minWidth: 650,
    },
    tableHead: {
        background: "#b9b9b9"
    },

    row: {
        '&:hover': {
            background: "#CACACA"
        }
    },
    cell: {
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    modal: {
        display: 'block',
        position: 'absolute',
        overflow: 'scroll',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 900,
    },
    buttonback: {
        backgroundColor: "#b9b9b9",
        border: '0px solid #000',
        boxShadow: theme.shadows[0],
        padding: theme.spacing(1.5, 1.5, 1.5),

    },
    formControlS: {
        margin: theme.spacing(1),
        width: 120,
    },
    formControlM: {
        margin: theme.spacing(1),
        width: 300
    },
    formControlL: {
        margin: theme.spacing(1),
        width: 800
    },
    ToArea: {
        border: '0px solid #b9b9b9',
        margin: theme.spacing(1),
        width: 800,
        background: "#fce79c",
        borderRadius: 10,
    },
    grammar: {
        width: 800
    },
    grammarPreview: {
        width: 800
    },
    cell_short: {
        fontSize: "10px",
        width: 50,
    },
    cell_medium: {
        fontSize: "10px",
        width: 100,
    },
    cell_clickable: {
        cursor: "pointer"
    },
    cell_searched: {
        background: "#FA8072"
    },
    gridItem: {
    },
    button: {
        background: 'white',
        borderRadius: 3,
        border: 0,
        color: 'black',
        padding: '0 20px',
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    container: {
        maxHeight: 600,
    },

    buttonSend: { width: 270, height: 50 }
});

const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
    },
})




var defaultFormValue = {};

class SendMailModal extends Component {

    constructor(props) {
        super(props);

        var submitDisabled = false

        this.state = null;
        this.state = {
            formValue: defaultFormValue,
            openModal: false,
            From: this.props.From,
            To: this.props.To,　　//配列
            Subject: this.props.Subject,
            Html: this.props.Html,
            fileName: this.props.fileName,
            fileId: this.props.fileId,
            emailsent: null,

            unfilledRequiredFIeld: this.props.To.length === 0 ? true : false,


        };

        console.log('this.props.To.length' ,console.log(this.props.To.length))
    

        console.log('this.props.To',this.props.To)

    }

    componentDidMount() {

    }


    handlModalOpen = () => {
        this.setState({
            openModal: true,
            formValue: Object.create(defaultFormValue),
            To: this.props.To,
            Subject: this.props.Subject,
            Html: this.props.Html,
            fileName: this.props.fileName,
            fileId: this.props.fileId,
            emailsent: null,
            unfilledRequiredFIeld: this.props.To.length === 0 ? true : false,

        })
    };



    handleModalClose = () => {
        this.setState({ openModal: false })
    };





    render() {


        const { classes } = this.props;

        return (
            <div>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => { e.preventDefault(); this.handlModalOpen() }} >
                    <Grid container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center" >
                        <Grid item xs={2}><SendIcon className={classes.black} /></Grid>
                        <Grid item xs={10}>inviare via e-mail</Grid>
                    </Grid>
                </Button>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.state.openModal}
                    onClose={this.handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openModal}>
                        <div className={classes.modalpaper}>
                            <form className={classes.root} noValidate autoComplete="on">

                                <Grid container justify="left"  >
                                    <Grid item xs={11}>
                                        <h4 id="transition-modal-title">Inviare via e-mail  </h4>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                                this.handleModalClose();
                                            }} >
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>

                                    <Grid container key={'form_To'}>
                                        <Grid item xs={12} >
                                            {this.state.To.map((t, i) => {
                                                return (
                                                    <Grid item xs={12} className={classes.ToArea}>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            alignItems="center"
                                                        >

                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={(e) => {
                                                                    e.preventDefault();

                                                                    var arrTo = this.state.To
                                                                    arrTo.splice(i, 1)
                                                                    this.setState({ To: arrTo })

                                                                    this.setState({ 'unfilledRequiredFIeld': arrTo.length == 0 ? true : false })

                                                                }} >
                                                                <CloseIcon />
                                                            </IconButton>
                                                            {t.email}
                                                            {t.name == null || t.name == ''
                                                                ? null
                                                                : '(' + t.name + ')'}

                                                        </Grid>
                                                    </Grid>
                                                )

                                            })}
                                        </Grid>
                                        <Grid item xs={12} >
                                            <SelectToModal
                                                addToArr={(email, name) => {
                                                    var arrTo = this.state.To
                                                    arrTo.push({ email, name })
                                                    this.setState({ To: arrTo })
                                                    this.setState({ 'unfilledRequiredFIeld': arrTo.length == 0 ? true : false })

                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container key={'form_Subject'}>
                                    <Grid item xs={12} >
                                        <FormControl className={classes.formControlL}>
                                            <TextField
                                                className={classes.grammar}
                                                id="outlined-basic"
                                                label='Subject'
                                                variant="outlined"
                                                value={this.state.Subject}
                                                onChange={(e) => {
                                                    e.preventDefault()
                                                    this.setState({ 'Subject': e.target.value });

                                                }} />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container key={'form_Html'}>
                                    <Grid item xs={12} >
                                        <FormControl className={classes.formControlL}>
                                            <TextareaAutosize
                                                id="standard-basic"
                                                aria-label="LavoriEseguiti*"
                                                minRows={10}
                                                maxRows={10}
                                                variant="outlined"
                                                value={this.state.Html}
                                                onChange={(e) => {
                                                    e.preventDefault()
                                                    this.setState({ 'Html': e.target.value });

                                                }} />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} >
                                    <Button variant="contained"
                                        disabled={this.state.unfilledRequiredFIeld}
                                        className={classes.buttonSend}
                                        onClick={(e) => {
                                            e.preventDefault();

                                            this.setState({ emailsent: "progress" })


                                            var toArr = [];
                                            this.state.To.forEach((e, i) => {
                                                toArr.push(e.email)
                                            })

                                            this.props.sendEmail(
                                                this.props.From,
                                                toArr,
                                                this.state.Subject,
                                                '<pre>' + this.state.Html + '</pre>',
                                                this.props.fileId,
                                                this.props.fileName,
                                                this.props.logininfo.id,
                                                (success, accepted, rejected) => {
                                                    console.log('accepted', accepted)


                                                    if (success) {


                                                        this.setState({ emailsent: "ok" })

                                                        this.state.To.forEach((e, i) => {

                                                            if (accepted.includes(e.email)) {

                                                                console.log('accepted', e.email)

                                                                //JSON.parse(JSON.stringify(this.props.postFormDefault))
                                                                //var postForm = this.props.postFormDefault
                                                                var postForm = JSON.parse(JSON.stringify(this.props.postFormDefault))
                                                                postForm['DataInvio'] = DateTool.createJSONDateWithoutTime(new Date());
                                                                postForm['To'] = e.email
                                                                postForm['Detail'] = ''


                                                                postForm['Result'] = 'inviato'

                                                                this.props.submitCreate(
                                                                    null,
                                                                    this.props.getModelProps,
                                                                    //this.props.pages,
                                                                    1,
                                                                    //this.props.numData,
                                                                    null,
                                                                    postForm,
                                                                    this.props.postModelProps,
                                                                    this.props.logininfo.id
                                                                )
                                                            }

                                                        })

                                                    } else {



                                                        this.setState({ emailsent: "ng" })
                                                        //処理未構築


                                                    }

                                                });


                                            //this.handleModalClose();


                                        }}>inviare</Button>

                                </Grid>
                                <Grid item xs={12} >
                                    {this.state.emailsent == "progress"
                                        ? <LinearProgress />
                                        : this.state.emailsent == "ok"
                                            ? <h4>inviato</h4>
                                            : this.state.emailsent == "ng"
                                                ? <h4>Errore!! Si prega di inviare manualmente</h4>
                                                : null
                                    }
                                </Grid>
                            </form>
                        </div>
                    </Fade>
                </Modal >

            </div >

        );
    }
}


const mapStateToProps = (state) => {
    return {
        logininfo: state.auth.logininfo

    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        sendEmail: (from, to, subject, html, fileid, filename, access_token, cb) => {
            dispatch(EmailAction.sendEmail(from, to, subject, html, fileid, filename, access_token, cb))
        },
        submitCreate: (socket, getModelProps, page, numData, formValue, postModelProps, access_token) => {
            dispatch(ModelAction.apiPost(socket, getModelProps, page, numData, formValue, postModelProps, access_token))

        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SendMailModal));