export const modelProps = {

    "modelname": "SpeseDitta",
    "props": {
        "id": {
            "type": "string"
        },
        "TipoSpesa": {
            "type": "string",
            "option": [
                {
                    "label": "pasti",
                    "val": "pasti"
                },
                {
                    "label": "benzina",
                    "val": "benzina"
                },
                {
                    "label": "assicurazione",
                    "val": "assicurazione"
                },
                {
                    "label": "bollo",
                    "val": "bollo"
                },
                {
                    "label": "mezzi",
                    "val": "mezzi"
                },
                {
                    "label": "ufficio",
                    "val": "ufficio"
                },
                {
                    "label": "abbigliamento",
                    "val": "abbigliamento"
                },
                {
                    "label": "altro",
                    "val": "altro"
                }
            ],
        },
        "Descrizione": {
            "type": "string",
        },
        "Importo": {
            "type": "number",
        },
        "Data": {
            "type": "date"
        },
        "LastModifiedBy": {
            "type": "string"
        },
        "LastModifiedAt": {
            "type": "date"
        }
    },
    "arrInclude": [],
    "order": ["Data DESC"],

}