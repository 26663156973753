export const auth = (state = { logininfo: null, usernameOrEmail: null, role: null, accesstoken_expired: false }, action) => {

    switch (action.type) {
        case 'LOGIN':

            console.log({ ...state, logininfo: action.value, usernameOrEmail: action.usernameOrEmail });
            return {
                ...state,
                logininfo: action.value,
                usernameOrEmail: action.usernameOrEmail,
                role: action.role,
                accesstoken_expired: false
            }

        case 'LOGOUT': return { ...state, logininfo: null, usernameOrEmail: null, role: null, accesstoken_expired: false }
        case 'ACCESSTOKEN_EXPIRED':
            return { ...state, accesstoken_expired: true }
        default: return state
    }
}