import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as ModelAction from '../../../../../store/actions/ModelAction'
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import * as DateTool from '../../../../../util/DateTool';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Searcher from '../../../../common/Searcher'

import * as NumberTool from '../../../../../util/NumberTool';
import * as StrTool from '../../../../../util/StrTool';
import { modelProps as modelPropsDipendenti } from '../../../../../model/Dipendenti';
import { modelProps as modelPropsCantieri } from '../../../../../model/Cantieri';

import { checkInputRequired } from '../../../../../model/Presenze';


import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import itLocale from "date-fns/locale/it";
import DateFnsUtils from '@date-io/date-fns';


const styles = theme => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    table: {
        // minWidth: 650,
    },
    tableHead: {
        background: "#b9b9b9"
    },

    row: {
        '&:hover': {
            background: "#CACACA"
        }
    },
    cell: {
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    modal: {
        display: 'block',
        position: 'absolute',
        overflow: 'scroll',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 900,
    },
    buttonback: {
        backgroundColor: "#b9b9b9",
        border: '0px solid #000',
        boxShadow: theme.shadows[0],
        padding: theme.spacing(1.5, 1.5, 1.5),

    },
    formControlS: {
        margin: theme.spacing(1),
        width: 120,
    },
    formControlM: {
        margin: theme.spacing(1),
        width: 300
    },
    formControlL: {
        margin: theme.spacing(1),
        width: 800
    },
    grammar: {
        width: 800
    },
    grammarPreview: {
        width: 800
    },
    cell_short: {
        fontSize: "10px",
        width: 50,
    },
    cell_medium: {
        fontSize: "10px",
        width: 100,
    },
    cell_clickable: {
        cursor: "pointer"
    },
    cell_searched: {
        background: "#FA8072"
    },
    gridItem: {
    },
    button: {
        background: 'white',
        borderRadius: 3,
        border: 0,
        color: 'black',
        padding: '0 20px',
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    container: {
        maxHeight: 600,
    },
});

const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
    },
})




var defaultFormValue = {


};

class CrudModalForUserCreateDipendenti extends Component {

    constructor(props) {

        super();
        this.state = {
            formValue: Object.create(defaultFormValue),
            unfilledRequiredFIeld: true,
            openModal: false,
            invalidEmail: false,

        };


    }

    componentDidMount() {



    }


    handlCreateModalOpen = () => {
        this.setState({
            openModal: true,
            formValue: Object.create(defaultFormValue),
            unfilledRequiredFIeld: true,
            invalidEmail: false,
        })

        //初期値
        this.onChangeInputCommon('Nome', null)
        this.onChangeInputCommon('Cognome', null)
        this.onChangeInputCommon('Indirizzo', null)
        this.onChangeInputCommon('Prov', null)
        this.onChangeInputCommon('Localita', null)
        this.onChangeInputCommon('CAP', null)
        this.onChangeInputCommon('DataDiNascita', null)
        this.onChangeInputCommon('ProvDiNascita', null)
        this.onChangeInputCommon('LocalitaDiNascita', null)
        this.onChangeInputCommon('CodFisc', null)
        this.onChangeInputCommon('PIva', null)
        this.onChangeInputCommon('IBAN', null)
        this.onChangeInputCommon('Telefono1', null)
        this.onChangeInputCommon('Telefono2', null)
        this.onChangeInputCommon('Cel1', null)
        this.onChangeInputCommon('Cel2', null)
        this.onChangeInputCommon('FAX', null)
        this.onChangeInputCommon('EMail', null)
        this.onChangeInputNumCommon('CostoOrario', '')
        this.onChangeInputNumCommon('CostoMensile', '')
        this.onChangeInputCommon('Contratto', null)
        this.onChangeInputCommon('Matricola', null)
        this.onChangeInputCommon('DataAssunzione', null)
        this.onChangeInputCommon('DataLicenziamento', null)
        this.onChangeInputCommon('UserId', null)

    };



    handleModalClose = () => {
        this.setState({ openModal: false })
    };



    onChangeInputCommon = (key, value) => {

        var newFormValue = this.state.formValue;
        newFormValue[key] = value;
        this.setState({ formValue: newFormValue });

    }

    


    onChangeInputCommonEmail = (key, value) => {

        var newFormValue = this.state.formValue;
        newFormValue[key] = value == '' ? null : value ; //空文字はnullへ置き換える
        this.setState({ formValue: newFormValue });
    }


    onChangeInputNumCommon = (key, value) => {
        this.setState({ formValue: NumberTool.validateAndSetNewFormValue(key, value, this.state.formValue) });
    }


    handleDateTimeChange = (datetime, key) => {

        var newFormValue = this.state.formValue;

        if (datetime == null || datetime == "") {
            newFormValue[key] = null;
        } else {
            newFormValue[key] = DateTool.createJSONDateWithoutTime(datetime);
        }
        this.setState({ formValue: newFormValue });

    }


    render() {

        var keys = Object.keys(this.props.modelProps);

        const { classes } = this.props;

        const nums = [];
        for (let i = 1; i <= 50; i++) {
            nums.push(i);
        }

        return (
            <div>
                <List>
                    <ListItem button key="Lessons" onClick={(e) => { e.preventDefault(); this.handlCreateModalOpen() }}>
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary="Aggiunta" />
                    </ListItem>
                </List>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.state.openModal}
                    onClose={this.handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openModal}>
                        <div className={classes.modalpaper}>

                            <Grid container >
                                <Grid item xs={12}  >
                                    <h4 id="transition-modal-title">{this.props.modelName}</h4>
                                </Grid>
                                <Grid item xs={12}  >
                                    <form className={classes.root} noValidate autoComplete="on">
                                        <Grid container justify="left"  >

                                            <Grid item xs={12} key={'form_Nome'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Nome*'
                                                        value={this.state.formValue['Nome']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Nome', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Cognome'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Cognome'
                                                        value={this.state.formValue['Cognome']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Cognome', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Indirizzo'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Indirizzo'
                                                        value={this.state.formValue['Indirizzo']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Indirizzo', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Prov'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Prov'
                                                        value={this.state.formValue['Prov']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Prov', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Localita'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Localita'
                                                        value={this.state.formValue['Localita']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Localita', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_CAP'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='CAP'
                                                        value={this.state.formValue['CAP']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('CAP', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_DataDiNascita'} >
                                                <FormControl className={classes.formControlM}>
                                                    <MuiThemeProvider theme={customTheme}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                id="date-picker-dialog"
                                                                label='DataDiNascita'
                                                                format="dd/MM/yyyy"
                                                                value={this.state.formValue['DataDiNascita']}
                                                                onChange={(e) => { this.handleDateTimeChange(e, 'DataDiNascita') }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </MuiThemeProvider>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} key={'form_ProvDiNascita'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='ProvDiNascita'
                                                        value={this.state.formValue['ProvDiNascita']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('ProvDiNascita', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_LocalitaDiNascita'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='LocalitaDiNascita'
                                                        value={this.state.formValue['LocalitaDiNascita']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('LocalitaDiNascita', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_CodFisc'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='CodFisc'
                                                        value={this.state.formValue['CodFisc']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('CodFisc', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_PIva'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='PIva'
                                                        value={this.state.formValue['PIva']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('PIva', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_PIva'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='IBAN'
                                                        value={this.state.formValue['IBAN']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('IBAN', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Telefono1'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Telefono1'
                                                        value={this.state.formValue['Telefono1']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Telefono1', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Telefono2'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Telefono2'
                                                        value={this.state.formValue['Telefono2']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Telefono2', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Cel1'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Cel1'
                                                        value={this.state.formValue['Cel1']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Cel1', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Cel2'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Cel2'
                                                        value={this.state.formValue['Cel2']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Cel2', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_FAX'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='FAX'
                                                        value={this.state.formValue['FAX']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('FAX', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_EMail'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='EMail'
                                                        value={this.state.formValue['EMail']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommonEmail('EMail', e.target.value)
                                                            this.setState({ invalidEmail: !StrTool.validateEmail(e.target.value) })

                                                        }} /> </FormControl>
                                                {this.state.invalidEmail && <h5 style={{ color: 'red' }}>email non valido</h5>}
                                            </Grid>
                                            <Grid item xs={12} key={'form_CostoOrario'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='CostoOrario'
                                                        value={this.state.formValue['CostoOrario']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputNumCommon('CostoOrario', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_CostoMensile'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='CostoMensile'
                                                        value={this.state.formValue['CostoMensile']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputNumCommon('CostoMensile', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Contratto'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Contratto'
                                                        value={this.state.formValue['Contratto']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Contratto', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Matricola'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Matricola'
                                                        value={this.state.formValue['Matricola']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Matricola', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_DataAssunzione'} >
                                                <FormControl className={classes.formControlM}>
                                                    <MuiThemeProvider theme={customTheme}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                id="date-picker-dialog"
                                                                label='DataAssunzione'
                                                                format="dd/MM/yyyy"
                                                                value={this.state.formValue['DataAssunzione']}
                                                                onChange={(e) => { this.handleDateTimeChange(e, 'DataAssunzione') }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </MuiThemeProvider>
                                                </FormControl> </Grid>

                                            <Grid item xs={12} key={'form_DataLicenziamento'} >
                                                <FormControl className={classes.formControlM}>
                                                    <MuiThemeProvider theme={customTheme}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                id="date-picker-dialog"
                                                                label='DataLicenziamento'
                                                                format="dd/MM/yyyy"
                                                                value={this.state.formValue['DataLicenziamento']}
                                                                onChange={(e) => { this.handleDateTimeChange(e, 'DataLicenziamento') }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </MuiThemeProvider>
                                                </FormControl></Grid>
                                            <Grid item xs={12} key={'form_UserId'} >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='UserId'
                                                        value={this.state.formValue['UserId']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('UserId', e.target.value)
                                                        }} /> </FormControl> </Grid>





                                            <Grid item xs={12} >
                                                <Button
                                                    variant="contained"
                                                    disabled={this.state.formValue.Nome == null ||
                                                        this.state.formValue.Nome.trim() == '' ||
                                                        (this.state.formValue.Email != '' && this.state.invalidEmail)
                                                        ? true
                                                        : false}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        //最終更新者・日時を追加
                                                        var newFormValue = this.state.formValue;
                                                        newFormValue["LastModifiedBy"] = this.props.logininfo.userId;
                                                        newFormValue["LastModifiedAt"] = DateTool.returnItalianTime();

                                                        if (this.state.formValue.CostoMensile == '') {
                                                            newFormValue["CostoMensile"] = null
                                                        }
                                                        if (this.state.formValue.CostoOrario == '') {
                                                            newFormValue["CostoOrario"] = null
                                                        }


                                                        this.setState({ formValue: newFormValue });

                                                        this.props.submitCreate(
                                                            this.props.socket,
                                                            this.props.modelProps,
                                                            this.state.page,
                                                            this.state.numData,
                                                            this.state.formValue,
                                                            this.props.modelProps,
                                                            this.props.logininfo.id
                                                        );
                                                        this.handleModalClose();


                                                    }}>Aggiunta</Button>
                                            </Grid>
                                        </Grid >
                                    </form>
                                </Grid>
                            </Grid>



                        </div>
                    </Fade>
                </Modal >

            </div>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        logininfo: state.auth.logininfo,

    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        submitCreate: (socket, getModelProps, page, numData, formValue, postModelProps, access_token) => {
            dispatch(ModelAction.apiPost(socket, getModelProps, page, numData, formValue, postModelProps, access_token))
        },


    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CrudModalForUserCreateDipendenti));