export const modelProps = {

    "modelname": "Dipendenti",
    "props": {
        "id": {
            "type": "string"
        },
        "Cognome": {
            "type": "string"
        },
        "Nome": {
            "type": "string",
            "inputRequired": true
        },
        "Indirizzo": {
            "type": "string"
        },
        "Prov": {
            "type": "string"
        },
        "Localita": {
            "type": "string"
        },
        "CAP": {
            "type": "string"
        },
        "DataDiNascita": {
            "type": "date"
        },
        "ProvDiNascita": {
            "type": "string"
        },
        "LocalitaDiNascita": {
            "type": "string"
        },
        "CodFisc": {
            "type": "string"
        },
        "PIva": {
            "type": "string"
        },
        "IBAN": {
            "type": "string"
        },
        "Telefono1": {
            "type": "string"
        },
        "Telefono2": {
            "type": "string"
        },
        "Cel1": {
            "type": "string"
        },
        "Cel2": {
            "type": "string"
        },
        "FAX": {
            "type": "string"
        },
        "EMail": {
            "type": "string"
        },
        "CostoOrario": {
            "label": "Costo Orario",
            "type": "number"
        },
        "CostoMensile": {
            "label": "Cost Mensile",
            "type": "number"
        },
        "Contratto": {
            "type": "string"
        },
        "Matricola": {
            "type": "string"
        },
        "DataAssunzione": {
            "type": "date"
        },
        "DataLicenziamento": {
            "type": "date"
        },
        "UserId": {
            "type": "string"
        },
        "LastModifiedBy": {
            "type": "string"
        },
        "LastModifiedAt": {
            "type": "date"
        }
    }

}