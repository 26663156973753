import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { withStyles } from "@material-ui/core/styles";
import * as StipendiAction from '../../store/actions/StipendiAction'

import { createMuiTheme } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';


import Button from '@material-ui/core/Button';
import * as DateTool from '../../util/DateTool'
import * as StrTool from '../../util/StrTool'

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';


//commonstylesの上書き
const styles = theme => ({

    formControlS: {
        //margin: theme.spacing(1),
        width: 180,
    },
    formControlM: {
        margin: theme.spacing(1),
        width: 370
    },
    color_red: {
        color: 'red'
    },
    color_blue: {
        color: 'blue'
    }

});

const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
        text: {
            disabled: 'black',
        },
    },
})


class RowAccontoForForm extends Component {

    constructor(props) {

        super(props);
        this.state = {};

    }

    createMsgAcconto = (acconto) => {
        return 'Conferma un anticipo di ' + acconto + ' euro?'
    }

    render() {

        const { classes } = this.props;

        return (
            <div>
                {this.props.index == 1 ||
                    this.props.formValue[['Acconto' + this.props.index]] != null && this.props.formValue[['Acconto' + this.props.index]] !== '' ||
                    this.props.formValue[['Acconto' + (this.props.index - 1)]] != null && this.props.formValue[['Acconto' + (this.props.index - 1)]] !== ''
                    ? <Grid item xs={12} key={'form_Acconto' + this.props.index} >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-end"
                            className={classes.formControlM}>
                            <Grid item xs={6} >
                                <FormControl className={classes.formControlS}>
                                    <TextField
                                        id="standard-basic"
                                        label='Acconto'
                                        value={this.props.formValue['Acconto' + this.props.index]}
                                        onChange={(e) => {
                                            e.preventDefault()
                                            this.props.onChangeInputNumCommon('Acconto' + this.props.index, e.target.value)
                                            this.props.calcSaldo(e.target.value)   //残高再計算
                                        }}
                                        disabled={this.props.formValue['AccontoConfermato' + this.props.index] ? true : false} //confermatoの場合は修正できない
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} >

                                <FormControl className={classes.formControlS}>
                                    {this.props.formValue['AccontoConfermato' + this.props.index]
                                        ? <TextField
                                            id="standard-basic"
                                            label='Confermato'
                                            value={StrTool.createStrDD_MM_YYYY(this.props.formValue['AccontoConfermato' + this.props.index])}
                                            disabled
                                        />
                                        : this.props.formValue['Acconto' + this.props.index] != null && this.props.formValue['Acconto' + this.props.index] !== ''
                                            ? <span className={classNames(classes.color_red)}>Da confermare</span>
                                            : null
                                    }
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    : null
                }

            </div>


        )
    }

}



const mapStateToProps = (state) => {
    return {
        logininfo: state.auth.logininfo,
        role: state.auth.role
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        submitCreate: (userId, page, numData, formValue, access_token) => {
            dispatch(StipendiAction.apiPostOrPut(userId, page, numData, formValue, access_token))
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(RowAccontoForForm));