export const modelProps = {
    "modelname":"Cantieri",
    "props": {
        "id": {
            "type": "string"
        },
        "Descrizione": {
            "type": "string",
            "inputRequired": true
        },
        "Indirizzo": {
            "type": "string"
        },
        "Descrizione": {
            "type": "string"
        },
        "Indirizzo": {
            "type": "string"
        },
        "Prov": {
            "type": "string"
        },
        "Localita": {
            "type": "string"
        },
        "Cap": {
            "type": "string"
        },
        "DataInizioLavori": {
            "type": "date"
        },
        "DataFineLavori": {
            "type": "date"
        },
        "Note": {
            "type": "string"
        },
        "LastModifiedBy": {
            "type": "string"
        },
        "LastModifiedAt": {
            "type": "date"
        },
        "ClientiId": {
            "type": "number"
        }


    },
    "arrInclude": ["Clienti"],
    "order": ["id"],
}



export const checkInputRequired = (formValue) => {

    var unfilledRequiredFIeld = false
  
    if (formValue.Descrizione == null) {
      unfilledRequiredFIeld = true
    }
  
    //if (formValue.Clienti == null) {
    //  unfilledRequiredFIeld = true
    //}
  
    return unfilledRequiredFIeld
  
  
  }