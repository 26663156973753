import zIndex from '@material-ui/core/styles/zIndex';
import axios from 'axios';
import * as LoopbackCall from '../../util/LoopbackCall'
import * as StrTool from '../../util/StrTool';


export const sendEmail = (from, to, subject, html, fileid, filename, access_token, cb) => {

    return (dispatch) => {

        console.log('to', to)
        console.log('subject', subject)

        console.log('sendEmail filename', filename)
        console.log('sendEmail fileid', fileid)
        axios.post(LoopbackCall.url + 'Util/send_email',
            null,
            {
                params: {
                    from: from,
                    to: to,
                    subject: subject,
                    html: html,
                    fileid: fileid,
                    filename: filename,
                    access_token: access_token
                }
            })
            .then((response) => {
                console.log(response)

                //{"response":{"accepted":["ryam53@gmail.com","nicola.rossi@gmail.com","euroalbekapp@gmail.com"],"rejected":[],"envelopeTime":209,"messageTime":2543,"messageSize":3955527,"response":"250 2.0.0 OK  1685792779 pg6-20020a170907204600b0096f89c8a2f7sm1872713ejb.90 - gsmtp","envelope":{"from":"euroalbekapp@gmail.com","to":["ryam53@gmail.com","nicola.rossi@gmail.com","euroalbekapp@gmail.com"]},"messageId":"<af8112d5-355d-9113-0a84-93fcf8c55d14@gmail.com>"}}
                if (response.status == 200) {


                    cb(true,response.data.response.accepted,response.data.response.rejected)

                } else {
                    cb(false,null,null)
                }


            }).catch((error) => {

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("Err" + error)

            })
    }
}

