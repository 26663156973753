import axios from 'axios';
import * as LoopbackCall from '../../util/LoopbackCall';


export const login = (usernameOrEmail, password, cb) => {

    return (dispatch) => {

        axios.post(LoopbackCall.url + `Users/login`, {
            username: usernameOrEmail,
            password: password
        }).then((response) => {

            if (response.status == 200) {
                console.log("認証成功ユーザ名", response.data.id);


                var access_token = response.data.id
                var userId = response.data.userId

                axios.post(LoopbackCall.url + `AuthUtil/getrole`,
                    null,
                    {
                        params: {
                            userId: userId, //ログイン者のロールを取得
                            access_token: access_token
                        }
                    }
                ).then((getrole_response) => {

                    if (getrole_response.status == 200) {
                        dispatch({
                            type: "LOGIN",
                            value: response.data,
                            usernameOrEmail: usernameOrEmail,
                            role: getrole_response.data.role
                        })
                    }


                }).catch((error) => {

                    //'ロールが取得できない'
                    cb("user's role was not found. please contact system manager")

                })


            }
        }).catch((error) => {

            axios.post(LoopbackCall.url + `Users/login`, {
                email: usernameOrEmail,
                password: password
            }).then((response) => {

                if (response.status == 200) {
                    console.log("認証成功メールアドレス", response.data.id);

                    var access_token = response.data.id
                    var userId = response.data.userId

                    axios.post(LoopbackCall.url + `AuthUtil/getrole`,
                        null,
                        {
                            params: {
                                userId: userId, //ログイン者のロールを取得
                                access_token: access_token
                            }
                        }
                    ).then((getrole_response) => {

                        if (getrole_response.status == 200) {
                            dispatch({
                                type: "LOGIN",
                                value: response.data,
                                usernameOrEmail: usernameOrEmail,
                                role: getrole_response.data.role
                            })
                        }


                    }).catch((error) => {

                        //'ロールが取得できない'
                        cb("user's role was not found. please contact system manager")

                    })


                }
            }).catch((error) => {
                console.log("認証失敗", error)
                cb('Wrong User or Password')
            })
        })

    }



}

export const logout = (logininfo) => {

    return (dispatch) => {

        axios.post(LoopbackCall.url + `Users/logout`, null, { params: { 'access_token': logininfo.id } }).then((response) => {
            if (response.status == 204) {
                console.log("ログアウト");
            }
            window.location.href = '/';

        }).catch((error) => {
            console.log("ログアウト失敗", error)
            window.location.href = '/';

        })

        dispatch({
            type: "LOGOUT"
        })
    }

}

export const searchUserWithVerificationToken = (email, verificationToken, cb) => {

    return (dispatch) => {

        axios.post(LoopbackCall.url + `ManageUser/find_with_varificationtoken`, {
            username: email,
            verificationToken: verificationToken
        }).then((response) => {

            if (response.status == 200) {
                console.log('response.data ', response.data)
                cb(response.data.valid)

            }
        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            cb(false)

        })

    }



}

export const setNewPasswordWithVarificationToken = (email, verificationToken, password, cb) => {

    return (dispatch) => {


        console.log(setNewPasswordWithVarificationToken)
        axios.post(LoopbackCall.url + `ManageUser/set_new_password_with_varificationtoken`, {
            username: email,
            verificationToken: verificationToken,
            password: password
        }).then((response) => {

            if (response.status == 200) {
                console.log('response.data ', response.data)
                cb(response.data.result)

            }
        }).catch((error) => {
            cb(false)

        })

    }



}

export const setNewPassword = (email, password, cb) => {

    return (dispatch) => {


        console.log(setNewPassword)
        axios.post(LoopbackCall.url + `ManageUser/set_new_password`, {
            username: email,
            password: password
        }).then((response) => {

            if (response.status == 200) {
                console.log('response.data ', response.data)
                cb(response.data.result)

            }
        }).catch((error) => {
            cb(false)

        })

    }



}


export const resetPassword = (email, cb) => {

    return (dispatch) => {

        axios.get(LoopbackCall.url + 'ManageUser/reset_password',
            { params: { email: email } }
        ).then((response) => {
            if (response.status == 200) {

                console.log("完了")

                cb(response.data.valid)
            }
        }).catch((error) => {
            console.log(error)
        })
    }



}


