import axios from 'axios';
import * as LoopbackCall from '../../util/LoopbackCall';
import * as StrTool from '../../util/StrTool';

import * as NumberTool from '../../util/NumberTool';





export const apiGet_presenza_by_month = (userId, firstdayofmonth, page, numData, access_token) => {

    return (dispatch) => {

        axios.post(LoopbackCall.url + 'MemberApi/get_presenza_by_month',
            null, {
            params: {
                userId,
                firstdayofmonth,
                page,
                numData,
                access_token
            }
        }).then((response) => {

            if (response.status == 200) {
                dispatch({
                    type: "GET_ALL_Presenze",
                    value: response.data
                })

                var totalOre = 0;
                var stipendio = 0;

                for (var i = 0; i < response.data.length; i++) {
                    console.log(response.data[i]);
                    console.log('response.data[i].CostoOrario', response.data[i].CostoOrario);
                    console.log('response.data[i].Ore', response.data[i].Ore);

                    if (response.data[i].Confermata) {

                        if (response.data[i].Ore == null || response.data[i].Ore === '') {

                            //出席だけ記録して場合
                        } else {
                            totalOre = NumberTool.calcAdd(totalOre, response.data[i].Ore)

                            if (response.data[i].CostoOrario == null || response.data[i].CostoOrario === '') {
                                //時給入ってない
                            } else {
                                stipendio = NumberTool.calcAdd(stipendio, NumberTool.calcMultiple(response.data[i].CostoOrario, response.data[i].Ore))
                            }
                        }
                    }
                }

                dispatch({
                    type: "Stipendi_From_Presenza_Monthly",
                    value: { stipendio, totalOre }
                })
            }
        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            console.log("get_presenza_by_month失敗", error)
        })
    }
}





/*

POST /StaffApi/get_presenza_by_month

userId	
firstdayofmonth	
page	
numData	
access_token	
*/
export const apiGet_stipendi_from_presenza = (userId, dal, al, access_token, cb) => {


    return (dispatch) => {

        axios.post(LoopbackCall.url + 'MemberApi/get_presenza_by_term',
            null, {
            params: {
                userId,
                dal,
                al,
                access_token
            }
        }).then((response) => {

            if (response.status == 200) {

                console.log('response.data', response.data)

                var totalOre = 0;
                var costoOrarioArr = [];
                var costoOrarioMsg = '';
                var stipendio = 0;
                var existEmptydata = null;


                //cosoOrarioをどうするか　TODO
                if (response.data.length == 0) {
                    cb(true, null, 0, costoOrarioMsg, 0)
                }



                for (var i = 0; i < response.data.length; i++) {
                    console.log(response.data[i]);
                    console.log('response.data[i].CostoOrario', response.data[i].CostoOrario);
                    console.log('response.data[i].Ore', response.data[i].Ore);

                    if (response.data[i].CostoOrario == null || response.data[i].CostoOrario === '' || response.data[i].Ore == null || response.data[i].Ore === '') {
                        stipendio = stipendio;  //出席だけ記録して場合
                        existEmptydata = 'Ci sono giorni in cui non sono registrate le ore lavorative o il costo orario.';
                    } else {
                        stipendio = NumberTool.calcAdd(stipendio, NumberTool.calcMultiple(response.data[i].CostoOrario, response.data[i].Ore))
                    }

                    if (response.data[i].Ore == null || response.data[i].Ore === '') {

                    } else {
                        totalOre = NumberTool.calcAdd(totalOre, response.data[i].Ore)
                    }


                    if (!costoOrarioArr.includes(response.data[i].CostoOrario)) {
                        costoOrarioArr.push(response.data[i].CostoOrario)
                    }



                    console.log('totalOre', totalOre)
                    console.log('stipendio', stipendio)
                }

                if (costoOrarioArr.length == 1) {
                    costoOrarioMsg = costoOrarioArr[0]
                } else {

                    for (var i = 1; i < costoOrarioArr.length; i++) {
                        costoOrarioMsg = costoOrarioMsg + '/' + costoOrarioArr[i]
                    }
                }




                cb(true, existEmptydata, totalOre, costoOrarioMsg, stipendio)


            } else {
                cb(false, '', null, null, null)
            }
        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            console.log("ここでapiGet_presenza_by_term失敗", error, null, null, null)
            cb(false, 'apiGet_presenza_by_term失敗', null, null, null)
        })
    }
}





/*
POST / StaffApi / get_count_presenza_by_month

userId
firstdayofmonth
access_token
*/
export const apiGetCount = (userId, firstdayofmonth, access_token) => {

    return (dispatch) => {

        axios.post(LoopbackCall.url + 'MemberApi/get_count_presenza_by_month',
            null, {
            params: {
                userId,
                firstdayofmonth,
                access_token
            }
        }).then((response) => {

            if (response.status == 200) {
                dispatch({
                    type: "GET_COUNT_Presenze",
                    value: response.data['count']
                })
            }

        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            console.log("get_count_presenza_by_month失敗", error)
        })

    }
}


/*

POST / StaffApi / post_or_put_presenza

presenza
access_token
*/
export const apiPostOrPut = (userId, firstdayofmonth, page, numData, formValue, access_token) => {

    console.log('ここの　apiPostOrPut formValue', formValue)


    return (dispatch) => {

        axios.post(LoopbackCall.url + 'MemberApi/post_or_put_presenza',
            {
                "id": formValue["id"],
                "Data": formValue["Data"],
                "CantieriId": formValue["Cantieri"] == null ? null : formValue["Cantieri"]["id"],
                "UserId": formValue["UserId"],
                "StaffId": formValue["StaffId"],
                "Presenza": formValue["Presenza"],
                "Ore": formValue["Ore"],
                "CostoOrario": formValue["CostoOrario"],
                "Confermata": formValue["Confermata"],
                "Descrizione": formValue["Descrizione"]
            },
            {
                params: {
                    //presenza: formValue,
                    access_token
                }
            }).then((response) => {


                axios.post(LoopbackCall.url + 'MemberApi/get_count_presenza_by_month',
                    null, {
                    params: {
                        userId: userId ? userId : formValue["UserId"],
                        firstdayofmonth,
                        access_token
                    }
                }).then((response) => {

                    if (response.status == 200) {

                        dispatch({
                            type: "GET_COUNT_Presenze",
                            value: response.data.count
                        })


                        axios.post(LoopbackCall.url + 'MemberApi/get_presenza_by_month',
                            null, {
                            params: {
                                userId: userId ? userId : formValue["UserId"],
                                firstdayofmonth,
                                page,
                                numData,
                                access_token
                            }
                        }).then((response) => {

                            if (response.status == 200) {
                                dispatch({
                                    type: "GET_ALL_Presenze",
                                    value: response.data
                                })

                                var totalOre = 0;
                                var stipendio = 0;

                                for (var i = 0; i < response.data.length; i++) {
                                    console.log(response.data[i]);
                                    console.log('response.data[i].CostoOrario', response.data[i].CostoOrario);
                                    console.log('response.data[i].Ore', response.data[i].Ore);

                                    if (response.data[i].Confermata) {

                                        if (response.data[i].Ore == null || response.data[i].Ore === '') {

                                            //出席だけ記録して場合
                                        } else {

                                            if (response.data[i].Presenza == 'p') {
                                                totalOre = NumberTool.calcAdd(totalOre, response.data[i].Ore)

                                                if (response.data[i].CostoOrario == null || response.data[i].CostoOrario === '') {
                                                    //時給入ってない
                                                } else {
                                                    stipendio = NumberTool.calcAdd(stipendio, NumberTool.calcMultiple(response.data[i].CostoOrario, response.data[i].Ore))
                                                }
                                            }

                                        }
                                    }
                                }

                                dispatch({
                                    type: "Stipendi_From_Presenza_Monthly",
                                    value: { stipendio, totalOre }
                                })
                            } else {
                                dispatch({
                                    type: "GET_ALL_Presenze",
                                    value: []
                                })
                            }

                        }).catch((error) => {
                            if (error.response.status === 401) {
                                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                                dispatch({
                                    type: "ACCESSTOKEN_EXPIRED"
                                })
                            }
                            console.log("get_presenza_by_month失敗", error)
                            dispatch({
                                type: "GET_ALL_Presenze",
                                value: []
                            })
                        })
                    } else {
                        dispatch({
                            type: "GET_COUNT_Presenze",
                            value: 0
                        })
                    }

                }).catch((error) => {

                    if (error.response.status === 401) {
                        console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                        dispatch({
                            type: "ACCESSTOKEN_EXPIRED"
                        })
                    }
                    console.log("get_count_presenza_by_month失敗", error)
                })




            }).catch((error) => {

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("登録失敗", error)
            })



    }
}



export const apiDelete = (userId, firstdayofmonth, numData, presenzaId, access_token) => {
    return (dispatch) => {


        axios.post(LoopbackCall.url + 'MemberApi/delete_presenza_by_id',
            null,
            {
                params: {
                    presenzaId,
                    access_token
                }
            }).then((response) => {


                axios.post(LoopbackCall.url + 'MemberApi/get_count_presenza_by_month',
                    null, {
                    params: {
                        userId,
                        firstdayofmonth,
                        access_token
                    }
                }).then((response) => {

                    if (response.status == 200) {

                        dispatch({
                            type: "GET_COUNT_Presenze",
                            value: response.data.count
                        })


                        axios.post(LoopbackCall.url + 'MemberApi/get_presenza_by_month',
                            null, {
                            params: {
                                userId,
                                firstdayofmonth,
                                page: 1,
                                numData,
                                access_token
                            }
                        }).then((response) => {

                            if (response.status == 200) {
                                dispatch({
                                    type: "GET_ALL_Presenze",
                                    value: response.data
                                })

                                var totalOre = 0;
                                var stipendio = 0;

                                for (var i = 0; i < response.data.length; i++) {
                                    console.log(response.data[i]);
                                    console.log('response.data[i].CostoOrario', response.data[i].CostoOrario);
                                    console.log('response.data[i].Ore', response.data[i].Ore);

                                    if (response.data[i].Confermata) {

                                        if (response.data[i].Ore == null || response.data[i].Ore === '') {

                                            //出席だけ記録して場合
                                        } else {
                                            totalOre = NumberTool.calcAdd(totalOre, response.data[i].Ore)

                                            if (response.data[i].CostoOrario == null || response.data[i].CostoOrario === '') {
                                                //時給入ってない
                                            } else {
                                                stipendio = NumberTool.calcAdd(stipendio, NumberTool.calcMultiple(response.data[i].CostoOrario, response.data[i].Ore))
                                            }
                                        }
                                    }
                                }

                                dispatch({
                                    type: "Stipendi_From_Presenza_Monthly",
                                    value: { stipendio, totalOre }
                                })
                            } else {
                                dispatch({
                                    type: "GET_ALL_Presenze",
                                    value: []
                                })

                                dispatch({
                                    type: "Stipendi_From_Presenza_Monthly",
                                    value: { stipendio: null, totalOre: null }
                                })
                            }

                        }).catch((error) => {

                            if (error.response.status === 401) {
                                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                                dispatch({
                                    type: "ACCESSTOKEN_EXPIRED"
                                })
                            }
                            console.log("get_presenza_by_month失敗", error)
                            dispatch({
                                type: "GET_ALL_Presenze",
                                value: []
                            })

                            dispatch({
                                type: "Stipendi_From_Presenza_Monthly",
                                value: { stipendio: null, totalOre: null }
                            })
                        })
                    } else {
                        dispatch({
                            type: "GET_COUNT_Presenze",
                            value: 0
                        })
                    }

                }).catch((error) => {

                    if (error.response.status === 401) {
                        console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                        dispatch({
                            type: "ACCESSTOKEN_EXPIRED"
                        })
                    }
                    console.log("get_count_presenza_by_month失敗", error)
                })




            }).catch((error) => {

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("登録失敗", error)
            })



    }
}


