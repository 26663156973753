export const modelProps = {

    "modelname": "Preventivi",
    "props": {
        "id": {
            "type": "string"
        },
        "GdriveFileId": {
            "type": "string"
        },
        "FileName": {
            "type": "string"
        },
        "UploadedAt": {
            "type": "string"
        },
        "mimeType": {
            "type": "string"
        }
    },
    "arrInclude": ["EmailPreventivi","Cantieri"],
    "where":{"Trashed" : false}


}