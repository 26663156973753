import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { withStyles } from "@material-ui/core/styles";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import * as ModelAction from '../../store/actions/ModelAction'



const styles = theme => ({

    margin: {
        margin: theme.spacing(1),
    },
    List: {
        width: 200
    },
    formControlM: {
        width: 200
    },
    enhanceSelected: {
        //background: 'linear-gradient(45deg, #BE9401, #feca16)',
        background: '#feca16'
    }


});




class Searcher extends Component {

    constructor(props) {

        super(props);
        this.state = { searchText: "", showList: true };

    }

    componentDidMount() {
        this.props.getModelCount(this.props.modelProps, this.props.logininfo.id)
        this.props.getModelList(this.props.modelProps, 1, this.props.modelList[this.props.modelProps.modelname + "_count"], this.props.logininfo.id);

    }

    select(e, model) {
        e.preventDefault();
        this.props.onSelect(model); //コールバック
    }


    render() {

        const { classes } = this.props;
        return (
            <div>
                <InputLabel htmlFor="input-text">cerca {this.props.modelProps.modelname.toLowerCase()}...</InputLabel>
                <Input
                    id="input-text"
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    onChange={(e) => {
                        this.setState({ searchText: e.target.value })
                        this.setState({ enhanceSelected: false })
                        this.props.onSelect(null);
                        this.setState({ showList: true })

                        if (e.target.value.trim() == '') {
                            this.props.onClear && this.props.onClear()
                        }
                    }
                    }
                    value={this.state.searchText}
                    className={classNames(classes.formControlM, this.state.enhanceSelected ? classes.enhanceSelected : null)}
                />
                {this.state.showList &&
                    <List component="nav" >
                        {this.props.modelList &&
                            this.props.modelList[this.props.modelProps.modelname] &&
                            this.props.modelList[this.props.modelProps.modelname].map((model) => {

                                var showprop = '';

                                this.props.showpropArr.forEach((p) => {

                                    if (model[p] != null) {
                                        showprop = showprop + model[p] + ' '
                                    }
                                })



                                if (this.state.searchText == "") {

                                    return (
                                        <ListItem
                                            className={classNames(classes.List)}
                                            button
                                            onClick={(e) => {
                                                this.select(e, model)
                                                this.setState({ 'searchText': showprop })
                                                this.setState({ enhanceSelected: true })
                                                this.setState({ showList: false })
                                            }}
                                        >
                                            <ListItemText primary={showprop} />
                                        </ListItem>
                                    )

                                } else {

                                    if (showprop.toLowerCase().indexOf(this.state.searchText.toLowerCase()) != -1) {
                                        return (
                                            <ListItem
                                                className={classNames(classes.List)}
                                                button
                                                onClick={(e) => {
                                                    this.setState({ 'searchText': showprop })
                                                    this.select(e, model)
                                                    this.setState({ enhanceSelected: true })
                                                    this.setState({ showList: false })
                                                }}
                                            >
                                                <ListItemText primary={showprop} />
                                            </ListItem>
                                        )
                                    }
                                }
                            })}
                    </List>}

            </div>

        )
    }

}



const mapStateToProps = (state) => {
    return {
        modelList: state.model,
        logininfo: state.auth.logininfo
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        getModelList: (getModelProps, page, numData, access_token) => {
            dispatch(ModelAction.apiGet(getModelProps, page, numData, access_token))
        },
        getModelCount: (getModelProps, access_token) => {
            dispatch(ModelAction.apiGetCount(getModelProps, access_token))
        },

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Searcher));