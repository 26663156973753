import React, { Component } from 'react';

import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import * as ModelAction from '../../../../../store/actions/ModelAction'
import { connect } from 'react-redux';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { createMuiTheme } from '@material-ui/core'
import * as DateTool from '../../../../../util/DateTool';
import * as StrTool from '../../../../../util/StrTool';
import Pagination from '@material-ui/lab/Pagination';
import CrudModalForUserCreateDettagliModalita from './CrudModalForUserCreateDettagliModalita'
import CrudModalForUserUpdateDettagliModalita from './CrudModalForUserUpdateDettagliModalita'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';



import { styles } from '../../../../css/commonstyles'


const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
    },
})




class CrudForUserDettagliModalita extends Component {

    constructor(props) {
        super(props);



        this.state = null;
        this.state = {
            page: 1,
            numData: this.props.modelProps.DefaultNum ? this.props.modelProps.DefaultNum : 200,
            searchedNum: 0,
            searchText: ''

        };

    }

    componentDidMount() {
        this.props.getModelCount(this.props.modelProps, this.props.logininfo.id)
        this.props.getModelList(this.props.modelProps, this.state.page, this.state.numData, this.props.logininfo.id);
        //this.props.setupWebSocket(this.props.logininfo.id);
    }



    onChangeInputNumData = (num) => {


        var total = this.props.modelList[this.props.modelName + "_count"]

        if (num >= total) {
            num = total;
        } else {
            //全件１ページ表示字のみ検索可
            this.setState({ searchText: '' })

        }

        this.setState({ numData: num });

        if (num == null || num == "") {
            this.props.getModelList(this.props.modelProps, this.state.page, total, this.props.logininfo.id);
        } else {
            this.props.getModelList(this.props.modelProps, this.state.page, num, this.props.logininfo.id);
        }

    }

    onChangePagination = (page) => {
        this.setState({ page: page });
        this.props.getModelList(this.props.modelProps, page, this.state.numData, this.props.logininfo.id);
    }





    render() {

        var keys = Object.keys(this.props.modelProps);

        const { classes } = this.props;

        const nums = [];
        for (let i = 1; i <= 50; i++) {
            nums.push(i);
        }

        return (
            <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
            >

                <Grid item xs={12}  >
                    <Grid
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                    >
                        <Grid item xs={12}  >
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                            >

                                <CrudModalForUserCreateDettagliModalita
                                    modelProps={this.props.modelProps}
                                    modelName={this.props.modelName}
                                />
                                <div>
                                    <InputLabel htmlFor="input-text">cerca dipendenti o artigiani ...</InputLabel>
                                    <Input
                                        id="input-text"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        }
                                        onChange={(e) => {
                                            this.setState({ searchText: e.target.value })

                                            var total = this.props.modelList[this.props.modelName + "_count"]
                                            this.onChangeInputNumData(total)

                                        }}
                                        value={this.state.searchText}
                                        className={classNames(classes.formControlM)}
                                    />
                                </div>


                                <Pagination
                                    onChange={(e, page) => { e.preventDefault(); this.onChangePagination(page) }}
                                    page={this.state.page}
                                    count={this.props.modelList
                                        ? Math.ceil(this.props.modelList[this.props.modelName + "_count"] / this.state.numData)
                                        : 3}
                                />
                                <TextField
                                    id="standard-basic"
                                    label="number of datas"
                                    value={this.state.numData}
                                    onChange={(e) => { e.preventDefault(); this.onChangeInputNumData(e.target.value) }}
                                />
                                <Typography variant="h6" >total:{this.props.modelList[this.props.modelName + "_count"]}</Typography>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <div >
                                <TableContainer className={classes.container} >
                                    <Table stickyHeader aria-label="a dense table"  >
                                        <TableHead className={classes.tableHead} >
                                            <TableRow >
                                                <TableCell key={'hd_dipendenti'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Dipendenti/Artigiani</TableCell>
                                                <TableCell key={'hd_TipoPag'} align="left" style={{ backgroundColor: '#BDBDBD' }} >TipoPag</TableCell>
                                                <TableCell key={'hd_Importo'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Importo</TableCell>
                                                <TableCell key={'hd_Assegno'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Assegno/Bonifico</TableCell>
                                                <TableCell key={'hd_IBAN'} align="left" style={{ backgroundColor: '#BDBDBD' }} >IBAN</TableCell>
                                                <TableCell key={'hd_DataScad'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Data Scad</TableCell>
                                                <TableCell key={'hd_DataPag'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Data Pag</TableCell>
                                                <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }} ></TableCell>
                                                <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {this.props.modelList[this.props.modelName].map((m) => {

                                                var showData = false;

                                                if (this.state.searchText.trim() == '') {
                                                    showData = true;
                                                } else {

                                                    if (m['Dipendenti'] != null &&
                                                        m['Dipendenti']['Nome'].toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }

                                                    if (m['Dipendenti'] != null &&
                                                        m['Dipendenti']['Cognome'] != null &&
                                                        m['Dipendenti']['Cognome'].toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }

                                                    if (m['Dipendenti'] != null &&
                                                        m['Dipendenti']['Cognome'] != null &&
                                                        (m['Dipendenti']['Nome'] + ' ' + m['Dipendenti']['Cognome']).toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }

                                                    if (m['Artigiani'] != null &&
                                                        m['Artigiani']['Nome'].toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }

                                                    if (m['Artigiani'] != null &&
                                                        m['Artigiani']['Cognome'] != null &&
                                                        m['Artigiani']['Cognome'].toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }

                                                    if (m['Artigiani'] != null &&
                                                        m['Artigiani']['Cognome'] != null &&
                                                        (m['Artigiani']['Nome'] + ' ' + m['Artigiani']['Cognome']).toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }


                                                }


                                                if (showData) {
                                                    return (
                                                        <TableRow key={m.id} className={classes.row}>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Artigiani'] != null
                                                                    ? m['Artigiani']['Cognome'] != null
                                                                        ? m['Artigiani']['Nome'] + ' ' + m['Artigiani']['Cognome']
                                                                        : m['Artigiani']['Nome']
                                                                    : null}
                                                                {m['Dipendenti'] != null
                                                                    ? m['Dipendenti']['Cognome'] != null
                                                                        ? m['Dipendenti']['Nome'] + ' ' + m['Dipendenti']['Cognome']
                                                                        : m['Dipendenti']['Nome']
                                                                    : null}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['TipoPag']}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Importo']}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Assegno']}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Artigiani'] != null
                                                                    ? m['Artigiani']['IBAN'] != null
                                                                        ? m['Artigiani']['IBAN']
                                                                        : 'no data'
                                                                    : null}
                                                                {m['Dipendenti'] != null
                                                                    ? m['Dipendenti']['IBAN'] != null
                                                                        ? m['Dipendenti']['IBAN']
                                                                        : 'no data'
                                                                    : null}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {StrTool.createStrDD_MM_YYYY(m['DataScad'])}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {StrTool.createStrDD_MM_YYYY(m['DataPag'])}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell, classes.cell_short,)}
                                                            >
                                                                <CrudModalForUserUpdateDettagliModalita
                                                                    modelProps={this.props.modelProps}
                                                                    modelName={this.props.modelName}
                                                                    modelValue={m} />
                                                            </TableCell>
                                                            <TableCell
                                                                align="right"
                                                                className={classNames(classes.cell, classes.cell_short)}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (window.confirm("deleting data id : " + m['id'])) {
                                                                        this.props.submitDelete(
                                                                            this.props.socket,
                                                                            this.props.modelProps,
                                                                            this.state.page,
                                                                            this.state.numData,
                                                                            m.id,
                                                                            this.props.modelProps,
                                                                            this.props.logininfo.id
                                                                        )
                                                                    }
                                                                }}>
                                                                <DeleteForeverIcon className={classNames(classes.cell_clickable)} />
                                                            </TableCell>
                                                        </TableRow>

                                                    )



                                                } else {

                                                    return null;
                                                }


                                            })}


                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Grid >
                    </Grid >
                </Grid>

            </Grid>





        );
    }
}


const mapStateToProps = (state) => {
    return {
        modelList: state.model,
        socket: state.webSocket.socket,
        logininfo: state.auth.logininfo

    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        getModelList: (getModelProps, page, numData, access_token) => {
            dispatch(ModelAction.apiGet(getModelProps, page, numData, access_token))
        },
        getModelCount: (getModelProps, access_token) => {
            dispatch(ModelAction.apiGetCount(getModelProps, access_token))
        },
        submitDelete: (socket, getModelProps, page, numData, id, deleteModelProps, access_token) => {
            dispatch(ModelAction.apiDelete(socket, getModelProps, page, numData, id, deleteModelProps, access_token))
        },

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CrudForUserDettagliModalita));