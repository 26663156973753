import React, { Component } from 'react';

import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import * as SommaAction from '../../../store/actions/SommaAction'
import * as DateTool from '../../../util/DateTool'

import * as StrTool from '../../../util/StrTool'


import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';

import Paper from '@material-ui/core/Paper';
import { styles } from '../../css/commonstyles'





//現在日時　年　月 ※constructorで設定


class Somma extends Component {



    constructor(props) {
        super(props);


        this.state = null;
        this.state = {
            arrMonth: [],
            year_from: null,
            month_from: null,
            year_to: null,
            month_to: null,
            checkvalid: true  //期間が有効か
        };

    }


    componentDidMount() {

        //年optioin用

        const italianDateArr = DateTool.returnItalianTimeAsArray()

        //現在日時　年　月
        const year = Number(italianDateArr[0]);
        const month = Number(italianDateArr[1]);

        this.setState({ arrMonth: [[year, month]] })
        this.setState({ year_from: year })
        this.setState({ year_to: year })
        this.setState({ month_from: month })
        this.setState({ month_to: month })

        var year_option = [];
        var op = 2020;
        while (op <= year) {
            year_option.push(op);
            op = op + 1;
        }

        this.setState({ yearOptionFrom: year_option })
        this.setState({ yearOptionTo: year_option })

        //一か月分を表示
        this.props.getModelListArtigiani(year, month, year, month, this.props.logininfo.id);
        this.props.getModelListDipendenti(year, month, year, month, this.props.logininfo.id);
        this.props.getModelListSpeseDitta(year, month, year, month, this.props.logininfo.id);
    }



    checkTargetMonth(targetYear, targetMonth, date) {

        var year = null;
        var month = null;

        if (date != null) {
            year = Number(date.substring(0, 4))
            month = Number(date.substring(5, 7))
        }


        if (year == targetYear &&
            month == targetMonth) {

            return true;

        } else {

            return false;
        }


    }

    getDate(date) {
        console.log('getDate', date)
        console.log('getDate', date.substring(8, 10))
        return Number(date.substring(8, 10));
    }


    handleChangeYearFrom(e) {
        this.setState({ year_from: e.target.value })
        this.setArrMonth(e.target.value, this.state.month_from, this.state.year_to, this.state.month_to)
    }

    handleChangeMonthFrom(e) {
        this.setState({ month_from: e.target.value })
        this.setArrMonth(this.state.year_from, e.target.value, this.state.year_to, this.state.month_to)
    }

    handleChangeYearTo(e) {
        this.setState({ year_to: e.target.value })
        this.setArrMonth(this.state.year_from, this.state.month_from, e.target.value, this.state.month_to)
    }

    handleChangeMonthTo(e) {
        this.setState({ month_to: e.target.value })
        this.setArrMonth(this.state.year_from, this.state.month_from, this.state.year_to, e.target.value)
    }

    setArrMonth(year_from, month_from, year_to, month_to) {

        console.log('setArrMonth', year_from, month_from, year_to, month_to)

        var valid = true
        //前後関係の確認
        if (year_from > year_to) {
            alert('Anno non valido');
            valid = false;
        }

        if (year_from == year_to) {

            if (month_from > month_to) {
                alert('Mese non valido');
                valid = false;
            }
        }

        this.setState({ checkvalid: valid })


        if (valid) {

            //データ取得
            this.props.getModelListArtigiani(year_from, month_from, year_to, month_to, this.props.logininfo.id);
            this.props.getModelListDipendenti(year_from, month_from, year_to, month_to, this.props.logininfo.id);
            this.props.getModelListSpeseDitta(year_from, month_from, year_to, month_to, this.props.logininfo.id);

            var arr = []

            while (year_from < year_to || (year_from == year_to && month_from <= month_to)) {

                arr.push([year_to, month_to])

                if (month_to == 1) {
                    month_to = 12;
                    year_to = year_to - 1;
                } else {
                    month_to = month_to - 1;
                }

            }

            this.setState({ arrMonth: arr })
        }





    }





    render() {

        const { classes } = this.props;




        return (

            <div className={classes.backgroundStylefafafa} >
                <div className={classes.spacingStyle3} >
                    <Grid
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                    >

                        <Grid item xs={12}  >

                            <Grid
                                container
                                direction="row"
                                justify="space-around"
                                alignItems="center"
                            >
                                <Grid item xs={1}  >
                                    <div><h4>Da : </h4></div>
                                </Grid>
                                <Grid item xs={1}  >
                                    <div>
                                        <FormControl sx={{ m: 1, minWidth: 100 }}>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={this.state.year_from}
                                                onChange={(e) => { this.handleChangeYearFrom(e) }}
                                            >
                                                {this.state.yearOptionFrom &&
                                                    this.state.yearOptionFrom.map(yo => {
                                                        return (
                                                            <MenuItem value={yo}>{yo}</MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={1}  >
                                    <div>
                                        <FormControl sx={{ m: 1, minWidth: 60 }}>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={this.state.month_from}
                                                onChange={(e) => { this.handleChangeMonthFrom(e) }}
                                            >
                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(mo => {
                                                    return (
                                                        <MenuItem value={mo}>{mo}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>

                                </Grid>
                                <Grid item xs={1}  >

                                </Grid>
                                <Grid item xs={1}  >
                                    <div><h4>A : </h4></div>
                                </Grid>
                                <Grid item xs={1}  >
                                    <div>
                                        <FormControl sx={{ m: 1, minWidth: 100 }}>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={this.state.year_to}
                                                onChange={(e) => { this.handleChangeYearTo(e) }}
                                            >
                                                {this.state.yearOptionFrom &&
                                                    this.state.yearOptionFrom.map(yo => {
                                                        return (
                                                            <MenuItem value={yo} selected={this.state.year_to == yo}>{yo}</MenuItem>
                                                        )
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={1}  >
                                    <div>
                                        <FormControl sx={{ m: 1, minWidth: 60 }}>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={this.state.month_to}
                                                onChange={(e) => { this.handleChangeMonthTo(e) }}
                                            >
                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(mo => {
                                                    return (
                                                        <MenuItem value={mo}>{mo}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={5}  >

                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}  >
                            {this.state.checkvalid &&
                                this.state.arrMonth.map(m => {

                                    var year = m[0]
                                    var month = m[1]

                                    var sumArtigiani = null;
                                    var sumDipendenti = null;
                                    var sumSpeseDitta = null;


                                    var sumArtigiani_num = 0;
                                    var sumDipendenti_num = 0;
                                    var sumSpeseDitta_num = 0;

                                    var dateArr = []


                                    var date = 1
                                    while (date <= DateTool.getLastDateOfMonth(year, month)) {
                                        dateArr.push(date)
                                        date++;
                                    }

                                    var data_Artigiani_Arr = []
                                    var data_Dipendenti_Arr = []
                                    var data_SpeseDitta_Arr = []


                                    this.props.dataArtigiani.forEach((e) => {

                                        if (this.checkTargetMonth(year, month, e.DataPag)) {
                                            sumArtigiani_num = sumArtigiani_num + e.Importo


                                            data_Artigiani_Arr.push({
                                                date: this.getDate(e.DataPag),
                                                importo_Dipendenti: null,
                                                importo_Artigiani: e.Importo,
                                                importo_SpeseDitta: null,
                                                descrizione: e.Artigiani.Cognome != null
                                                    ? e.Artigiani.Nome + ' ' + e.Artigiani.Cognome
                                                    : e.Artigiani.Nome
                                            })


                                        }
                                    })

                                    this.props.dataDipendenti.forEach((e) => {

                                        if (this.checkTargetMonth(year, month, e.DataPag)) {
                                            sumDipendenti_num = sumDipendenti_num + e.Importo

                                            data_Dipendenti_Arr.push({
                                                date: this.getDate(e.DataPag),
                                                importo_Dipendenti: e.Importo,
                                                importo_Artigiani: null,
                                                importo_SpeseDitta: null,
                                                descrizione: e.Dipendenti.Cognome != null
                                                    ? e.Dipendenti.Nome + ' ' + e.Dipendenti.Cognome
                                                    : e.Dipendenti.Nome
                                            })
                                        }

                                    })

                                    this.props.dataSpeseDitta.forEach((e) => {
                                        if (this.checkTargetMonth(year, month, e.Data)) {
                                            sumSpeseDitta_num = sumSpeseDitta_num + e.Importo

                                            data_SpeseDitta_Arr.push({
                                                date: this.getDate(e.Data),
                                                importo_Dipendenti: null,
                                                importo_Artigiani: null,
                                                importo_SpeseDitta: e.Importo,
                                                descrizione: e.descrizione != null
                                                    ? e.TipoSpesa + ' ' + e.descrizione
                                                    : e.TipoSpesa
                                            })
                                        }

                                    })


                                    console.log('data_Artigiani_Arr', data_Artigiani_Arr)
                                    console.log('data_Dipendenti_Arr', data_Dipendenti_Arr)
                                    console.log('data_SpeseDitta_Arr', data_SpeseDitta_Arr)

                                    sumArtigiani = sumArtigiani_num;
                                    sumDipendenti = sumDipendenti_num;
                                    sumSpeseDitta = sumSpeseDitta_num;

                                    return (
                                        <div>
                                            <div><h3>{StrTool.convertMonth(month)} {year}</h3></div>

                                            <div >
                                                <TableContainer className={classes.container} >
                                                    <Table stickyHeader aria-label="a dense table"  >
                                                        <TableHead className={classes.tableHead} >
                                                            <TableRow >
                                                                <TableCell key={'hd_Somma'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Somma</TableCell>
                                                                <TableCell key={'hd_Dipendenti'} align="right" style={{ backgroundColor: '#BDBDBD' }} >Dipendenti</TableCell>
                                                                <TableCell key={'hd_Artigiani'} align="right" style={{ backgroundColor: '#BDBDBD' }} >Artigiani</TableCell>
                                                                <TableCell key={'hd_SpeseDitta'} align="right" style={{ backgroundColor: '#BDBDBD' }} >SpeseDitta</TableCell>
                                                                <TableCell key={'hd_SpeseDitta'} align="right" style={{ backgroundColor: '#BDBDBD' }} ></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody >
                                                            <TableCell key={'hd_RagioneSociale'} align="left" style={{ backgroundColor: 'white' }} >
                                                                {sumDipendenti == null || sumArtigiani == null || sumSpeseDitta == null
                                                                    ? <CircularProgress />
                                                                    : <p>{sumDipendenti + sumArtigiani + sumSpeseDitta}</p>}
                                                            </TableCell>
                                                            <TableCell key={'hd_Nome'} align="right" style={{ backgroundColor: 'white' }} >
                                                                {sumDipendenti == null
                                                                    ? <CircularProgress />
                                                                    : <p>{sumDipendenti}</p>}
                                                            </TableCell>
                                                            <TableCell key={'hd_Cognome'} align="right" style={{ backgroundColor: 'white' }} >
                                                                {sumArtigiani == null
                                                                    ? <CircularProgress />
                                                                    : <p>{sumArtigiani}</p>}
                                                            </TableCell>
                                                            <TableCell key={'hd_RagioneSociale'} align="right" style={{ backgroundColor: 'white' }} >
                                                                {sumSpeseDitta == null
                                                                    ? <CircularProgress />
                                                                    : <p>{sumSpeseDitta}</p>}
                                                            </TableCell>
                                                            <TableCell key={'hd_RagioneSociale'} align="right" style={{ backgroundColor: 'white' }} >

                                                            </TableCell>

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>

                                            <div >
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography style={{ color: '#C9C9C9' }} >Dettagli</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>

                                                        <TableContainer component={Paper}>
                                                            <Table className={classes.table} size="small" aria-label="a dense table">
                                                                <TableHead className={classes.tableHead}>
                                                                    <TableRow>
                                                                        <TableCell align="left" style={{ backgroundColor: '#C9C9C9' }}>Data</TableCell>
                                                                        <TableCell align="right" style={{ backgroundColor: '#C9C9C9' }}>Dipendenti</TableCell>
                                                                        <TableCell align="right" style={{ backgroundColor: '#C9C9C9' }}>Artigiani</TableCell>
                                                                        <TableCell align="right" style={{ backgroundColor: '#C9C9C9' }}>SpeseDitta</TableCell>
                                                                        <TableCell align="right" style={{ backgroundColor: '#C9C9C9' }}>descrizione</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                {dateArr.map((date) => {


                                                                    return (

                                                                        <TableBody>{
                                                                            data_Dipendenti_Arr.map((d) => {

                                                                                if (d.date == date) {
                                                                                    return (
                                                                                        <TableRow key={d.date}>
                                                                                            <TableCell align="left">{d.date}</TableCell>
                                                                                            <TableCell align="right">{d.importo_Dipendenti}</TableCell>
                                                                                            <TableCell align="right">{d.importo_Artigiani}</TableCell>
                                                                                            <TableCell align="right">{d.importo_SpeseDitta}</TableCell>
                                                                                            <TableCell align="right">{d.descrizione}</TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                }

                                                                            })
                                                                        }
                                                                            {data_Artigiani_Arr.map((d) => {

                                                                                if (d.date == date) {
                                                                                    return (
                                                                                        <TableRow key={d.date}>
                                                                                            <TableCell align="left">{d.date}</TableCell>
                                                                                            <TableCell align="right">{d.importo_Dipendenti}</TableCell>
                                                                                            <TableCell align="right">{d.importo_Artigiani}</TableCell>
                                                                                            <TableCell align="right">{d.importo_SpeseDitta}</TableCell>
                                                                                            <TableCell align="right">{d.descrizione}</TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                }

                                                                            })

                                                                            }
                                                                            {
                                                                                data_SpeseDitta_Arr.map((d) => {

                                                                                    if (d.date == date) {
                                                                                        return (
                                                                                            <TableRow key={d.date}>
                                                                                                <TableCell align="left">{d.date}</TableCell>
                                                                                                <TableCell align="right">{d.importo_Dipendenti}</TableCell>
                                                                                                <TableCell align="right">{d.importo_Artigiani}</TableCell>
                                                                                                <TableCell align="right">{d.importo_SpeseDitta}</TableCell>
                                                                                                <TableCell align="right">{d.descrizione}</TableCell>
                                                                                            </TableRow>
                                                                                        )
                                                                                    }

                                                                                })
                                                                            }
                                                                        </TableBody>
                                                                    )
                                                                })}
                                                            </Table>
                                                        </TableContainer>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <div><h4> </h4></div>


                                        </div>
                                    )
                                })}
                        </Grid>
                    </Grid>
                </div>
            </div >

        );
    }
}

const mapStateToProps = (state) => {
    return {

        socket: state.webSocket.socket,
        logininfo: state.auth.logininfo,
        dataArtigiani: state.somma.data_for_somma_Artigiani,
        dataDipendenti: state.somma.data_for_somma_Dipendenti,
        dataSpeseDitta: state.somma.data_for_somma_SpeseDitta,

    }

}

const mapDispatchToProps = (dispatch) => {

    return {

        getModelListArtigiani: (from_year, from_month, to_year, to_month, access_token) => {
            dispatch(SommaAction.apiGetSommaArtigiani(from_year, from_month, to_year, to_month, access_token))
        },
        getModelListDipendenti: (from_year, from_month, to_year, to_month, access_token) => {
            dispatch(SommaAction.apiGetSommaDipendenti(from_year, from_month, to_year, to_month, access_token))
        },
        getModelListSpeseDitta: (from_year, from_month, to_year, to_month, access_token) => {
            dispatch(SommaAction.apiGetSommaSpeseDitta(from_year, from_month, to_year, to_month, access_token))
        }
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Somma));