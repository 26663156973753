import axios from 'axios';
import * as LoopbackCall from '../../util/LoopbackCall';
import * as StrTool from '../../util/StrTool';

import * as NumberTool from '../../util/NumberTool';
import { calcSaldo } from '../../model/Stipendi';




export const apiGet = (userId, page, numData, access_token) => {

    return (dispatch) => {

        axios.post(LoopbackCall.url + 'MemberApi/get_stipendi',
            null, {
            params: {
                userId,
                page,
                numData,
                access_token
            }
        }).then((response) => {

            if (response.status == 200) {
                dispatch({
                    type: "GET_ALL_Stipendi",
                    value: response.data
                })

            }
        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            console.log("get_stipendi失敗", error)
        })
    }
}






export const apiGetCount = (userId, access_token) => {

    return (dispatch) => {

        axios.post(LoopbackCall.url + 'MemberApi/get_count_stipendi',
            null, {
            params: {
                userId,
                access_token
            }
        }).then((response) => {

            if (response.status == 200) {
                dispatch({
                    type: "GET_COUNT_Stipendi",
                    value: response.data['count']
                })
            }

        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            console.log("get_count_stipendi失敗", error)
        })

    }
}


/*

POST / StaffApi / post_or_put_presenza

presenza
access_token
*/
export const apiPostOrPut = (userId, page, numData, formValue, access_token) => {

    console.log('ここの　apiPostOrPut formValue', formValue)


    return (dispatch) => {


       
        var s = calcSaldo(
            formValue['Stipendio'], 
        formValue['Acconto1'],
        formValue['Acconto2'],
        formValue['Acconto3'],
        formValue['Acconto4'],
        formValue['Acconto5'],
        formValue['AccontoConfermato1'],
        formValue['AccontoConfermato2'],
        formValue['AccontoConfermato3'],
        formValue['AccontoConfermato4'],
        formValue['AccontoConfermato5'])

        
        console.log('s',s)

        axios.post(LoopbackCall.url + 'MemberApi/post_or_put_stipendi',
            {
                "id": formValue["id"],
                "Dal": formValue["Dal"],
                "Al": formValue["Al"],
                "Stipendio": formValue["Stipendio"],
                "DataCalcoloStipendio": formValue["DataCalcoloStipendio"],
                "DescrizioneStipendio": formValue["DescrizioneStipendio"],
                "Acconto1": formValue["Acconto1"] == '' ? null : formValue["Acconto1"],
                "AccontoConfermato1": formValue["AccontoConfermato1"],
                "Acconto2": formValue["Acconto2"] == '' ? null : formValue["Acconto2"],
                "AccontoConfermato2": formValue["AccontoConfermato2"],
                "Acconto3": formValue["Acconto3"] == '' ? null : formValue["Acconto3"],
                "AccontoConfermato3": formValue["AccontoConfermato3"],
                "Acconto4": formValue["Acconto4"] == '' ? null : formValue["Acconto4"],
                "AccontoConfermato4": formValue["AccontoConfermato4"],
                "Acconto5": formValue["Acconto5"] == '' ? null : formValue["Acconto5"],
                "AccontoConfermato5": formValue["AccontoConfermato5"],
                "Saldo": s,
                "DataPag": formValue["DataPag"],
                "PagatoConfermato": formValue["PagatoConfermato"],
                "Descrizione": formValue["Descrizione"],
                "DipendentiId": formValue["DipendentiId"],
                "UserId": formValue["UserId"],
            },
            {
                params: {
                    //presenza: formValue,
                    access_token
                }
            }).then((response) => {


                axios.post(LoopbackCall.url + 'MemberApi/get_count_stipendi',
                    null, {
                    params: {
                        userId,
                        access_token
                    }
                }).then((response) => {

                    if (response.status == 200) {

                        dispatch({
                            type: "GET_COUNT_Stipendi",
                            value: response.data.count
                        })


                        axios.post(LoopbackCall.url + 'MemberApi/get_stipendi',
                            null, {
                            params: {
                                userId,
                                page,
                                numData,
                                access_token
                            }
                        }).then((response) => {

                            if (response.status == 200) {
                                dispatch({
                                    type: "GET_ALL_Stipendi",
                                    value: response.data
                                })
                            } else {
                                dispatch({
                                    type: "GET_ALL_Stipendi",
                                    value: []
                                })
                            }

                        }).catch((error) => {
                            if (error.response.status === 401) {
                                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                                dispatch({
                                    type: "ACCESSTOKEN_EXPIRED"
                                })
                            }
                            console.log("get_stipendi失敗", error)
                            dispatch({
                                type: "GET_ALL_Presenze",
                                value: []
                            })
                        })
                    } else {
                        dispatch({
                            type: "GET_COUNT_Presenze",
                            value: 0
                        })
                    }

                }).catch((error) => {

                    if (error.response.status === 401) {
                        console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                        dispatch({
                            type: "ACCESSTOKEN_EXPIRED"
                        })
                    }
                    console.log("get_count_stipendi失敗", error)
                })




            }).catch((error) => {

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("登録失敗", error)
            })



    }
}



export const apiDelete = (userId, numData, stipendiId, access_token) => {
    return (dispatch) => {


        axios.post(LoopbackCall.url + 'MemberApi/delete_stipendi_by_id',
            null,
            {
                params: {
                    stipendiId,
                    access_token
                }
            }).then((response) => {


                axios.post(LoopbackCall.url + 'MemberApi/get_count_stipendi',
                    null, {
                    params: {
                        userId,
                        access_token
                    }
                }).then((response) => {

                    if (response.status == 200) {

                        dispatch({
                            type: "GET_COUNT_Stipendi",
                            value: response.data.count
                        })


                        axios.post(LoopbackCall.url + 'MemberApi/get_stipendi',
                            null, {
                            params: {
                                userId,
                                page: 1,
                                numData,
                                access_token
                            }
                        }).then((response) => {

                            if (response.status == 200) {
                                dispatch({
                                    type: "GET_ALL_Stipendi",
                                    value: response.data
                                })
                            } else {
                                dispatch({
                                    type: "GET_ALL_Stipendi",
                                    value: []
                                })
                            }

                        }).catch((error) => {

                            if (error.response.status === 401) {
                                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                                dispatch({
                                    type: "ACCESSTOKEN_EXPIRED"
                                })
                            }
                            console.log("get_Stipendi失敗", error)
                            dispatch({
                                type: "GET_ALL_Stipendi",
                                value: []
                            })
                        })
                    } else {
                        dispatch({
                            type: "GET_COUNT_Stipendi",
                            value: 0
                        })
                    }

                }).catch((error) => {

                    if (error.response.status === 401) {
                        console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                        dispatch({
                            type: "ACCESSTOKEN_EXPIRED"
                        })
                    }
                    console.log("get_count_Stipendi失敗", error)
                })




            }).catch((error) => {

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("削除失敗", error)
            })



    }
}


