import zIndex from '@material-ui/core/styles/zIndex';
import axios from 'axios';
import * as LoopbackCall from '../../util/LoopbackCall'
import * as StrTool from '../../util/StrTool';
import * as DateTool from '../../util/DateTool';
import CircularProgress from '@mui/material/CircularProgress';


var FileSaver = require('file-saver');

export const apiGetSommaArtigiani = (from_year, from_month, to_year, to_month, access_token) => {


    var from = DateTool.createJSONDateWithoutTimeFromYearMonth(from_year, from_month, 1)

    //次の月の初日
    var to = null
    if (to_month != 12) {
        to = DateTool.createJSONDateWithoutTimeFromYearMonth(to_year, to_month + 1, 1)
    } else {
        to = DateTool.createJSONDateWithoutTimeFromYearMonth(to_year + 1, 1, 1)
    }


    //DettagliModalita
    //SpeseDitta

    return (dispatch) => {

        axios.get(LoopbackCall.url + 'DettagliModalita', {
            params: {
                filter: {
                    where: {
                        and: [
                            { "DataPag": { "gte": from } },
                            { "DataPag": { "lt": to } },
                            { "ArtigianiId": { "neq": null } }
                        ]
                    },
                    include: ["Dipendenti", "Artigiani"],
                    order: ["DataPag DESC"],
                },
                access_token: access_token
            }
        })
            .then((response) => {
                console.log("GET_SOMMA_Artigiani ", response.data)


                if (response.status == 200) {
                    dispatch({
                        type: "GET_SOMMA_Artigiani",
                        value: response.data
                    })


                }
            }).catch((error) => {
                console.log("GET_SOMMAArtigiani ERR", error)

                dispatch({
                    type: "GET_SOMMA_Artigiani",
                    value: []
                })

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
            })
    }
}





export const apiGetSommaDipendenti = (from_year, from_month, to_year, to_month, access_token) => {

    var from = DateTool.createJSONDateWithoutTimeFromYearMonth(from_year, from_month, 1)

    //次の月の初日
    var to = null
    if (to_month != 12) {
        to = DateTool.createJSONDateWithoutTimeFromYearMonth(to_year, to_month + 1, 1)
    } else {
        to = DateTool.createJSONDateWithoutTimeFromYearMonth(to_year + 1, 1, 1)
    }

    return (dispatch) => {

        axios.get(LoopbackCall.url + 'DettagliModalita', {
            params: {
                filter: {
                    where: {
                        and: [
                            { "DataPag": { "gte": from } },
                            { "DataPag": { "lt": to } },
                            { "DipendentiId": { "neq": null } }
                        ]
                    },
                    include: ["Dipendenti",],
                    order: ["DataPag DESC"],
                },
                access_token: access_token
            }
        })
            .then((response) => {
                console.log("GET_SOMMA_Dipendenti ", response.data)


                if (response.status == 200) {
                    dispatch({
                        type: "GET_SOMMA_Dipendenti",
                        value: response.data
                    })


                }
            }).catch((error) => {
                console.log("GET_SOMMA_Dipendenti ERR", error)

                dispatch({
                    type: "GET_SOMMA_Dipendenti",
                    value: []
                })

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
            })
    }
}

export const apiGetSommaSpeseDitta = (from_year, from_month, to_year, to_month, access_token) => {

    var from = DateTool.createJSONDateWithoutTimeFromYearMonth(from_year, from_month, 1)

    //次の月の初日
    var to = null
    if (to_month != 12) {
        to = DateTool.createJSONDateWithoutTimeFromYearMonth(to_year, to_month + 1, 1)
    } else {
        to = DateTool.createJSONDateWithoutTimeFromYearMonth(to_year + 1, 1, 1)
    }

    return (dispatch) => {

        axios.get(LoopbackCall.url + 'SpeseDitta', {
            params: {
                filter: {
                    where: {
                        and: [
                            { "Data": { "gte": from } },
                            { "Data": { "lt": to } }
                        ]
                    },
                    order: ["Data DESC"],
                },
                access_token: access_token
            }
        })
            .then((response) => {
                console.log("GET_SOMMA_SpeseDitta ", response.data)


                if (response.status == 200) {
                    dispatch({
                        type: "GET_SOMMA_SpeseDitta",
                        value: response.data
                    })
                }
            }).catch((error) => {
                console.log("GET_SOMMA ERR", error)

                dispatch({
                    type: "GET_SOMMA_SpeseDitta",
                    value: []
                })

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
            })
    }
}

