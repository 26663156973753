export const modelProps = {

    "modelname": "EmailPreventivi",
    "props": {
        "DataInvio": {
            "type": "date"
        },
        "To": {
            "type": "string"
        },
        "Result": {
            "type": "string"
        },
        "Detail": {
            "type": "string"
        }
    },
    "arrInclude": ["Preventivi"],
    "order": ["DataInvio DESC"],
}
