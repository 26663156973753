export const modelProps = {

    "modelname": "Artigiani",
    "props": {
        "id": {
            "type": "string"
        },
        "Cognome": {
            "type": "string"
        },
        "Nome": {
            "type": "string",
            "inputRequired": true
        },
        "RagioneSociale": {
            "type": "string"
          },
        "Indirizzo": {
            "type": "string"
        },
        "Prov": {
            "type": "string"
        },
        "Localita": {
            "type": "string"
        },
        "CAP": {
            "type": "string"
        },
       
        "CodFisc": {
            "type": "string"
        },
        "PIva": {
            "type": "string"
        },
        "IBAN": {
            "type": "string"
        },
        "SDI": {
            "type": "string"
          },
        "Telefono1": {
            "type": "string"
        },
        "Telefono2": {
            "type": "string"
        },
        "Cel1": {
            "type": "string"
        },
        "Cel2": {
            "type": "string"
        },
        "FAX": {
            "type": "string"
        },
        "EMail": {
            "type": "string"
        },
        
        "LastModifiedBy": {
            "type": "string"
        },
        "LastModifiedAt": {
            "type": "date"
        }
    }

}