import zIndex from '@material-ui/core/styles/zIndex';
import { ChatBubbleOutline } from '@material-ui/icons';
import axios from 'axios';
import * as LoopbackCall from '../../util/LoopbackCall'
import * as StrTool from '../../util/StrTool';


var FileSaver = require('file-saver');

//FileManager用
export const DeleteFile = (socket, fileid, access_token, pagesize, group) => {
    return (dispatch) => {
        axios.post(LoopbackCall.url + `Util/delete_file`,
            null,
            {
                params: {
                    fileid: fileid,
                    access_token: access_token
                },
            }).then((response) => {

                if (response.status == 200) {

                    axios.get(LoopbackCall.url + 'Util/get_file_list',
                        {
                            params: {
                                pageSize: pagesize,
                                pageToken: null,
                                group: group,
                                access_token: access_token,
                            }
                        }).then((response) => {
                            if (response.status == 200) {

                                dispatch({
                                    type: "GET_ALL_GDRIVE_FILES",
                                    value: response.data.files
                                })

                                dispatch({
                                    type: "GET_ALL_GDRIVE_FILES_NEXT_PAGE_TOKEN",
                                    value: response.data.nextPageToken
                                })
                            }
                        }).catch((error) => {
                            console.log(error)
                        })


                }

            }).catch((error) => {

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("download_pdf失敗", error)
            })
    }

}


export const DeleteFileWithCB = (socket, fileid, access_token, getModelProps, page, numData) => {
    return (dispatch) => {
        axios.post(LoopbackCall.url + `Util/delete_file`,
            null,
            {
                params: {
                    fileid: fileid,
                    access_token: access_token
                },
            }).then((response) => {

                if (response.status == 200) {

                    axios.get(LoopbackCall.url + getModelProps.modelname, {
                        params: {
                            filter: {
                                limit: numData,
                                skip: (page - 1) * numData,
                                include: getModelProps.arrInclude,
                                order: getModelProps.order,
                                where:{"Trashed":false}
                            },
                            access_token: access_token
                        }
                    }).then((response) => {
                        if (response.status == 200) {
                            dispatch({
                                type: "GET_ALL_" + getModelProps.modelname,
                                value: response.data
                            })
                        }
                    }).catch((error) => {
                        console.log("GET_ALL_" + getModelProps.modelname + " ERR", error)

                        dispatch({
                            type: "GET_ALL_" + getModelProps.modelname,
                            value: []
                        })
                    })



                }

            }).catch((error) => {
                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("delete_file失敗", error)
            })
    }

}


export const DownloadFile = (socket, filename, fileid, mimeType, access_token) => {
    return (dispatch) => {
        axios.post(LoopbackCall.url + `Util/download_file`,
            null,
            {
                params: {
                    fileid: fileid,
                    filename: filename,
                    mimeType: mimeType,
                    access_token: access_token
                },
                headers: {
                    // 'Content-Type': 'application/json',
                    // 'Accept': 'application/pdf'
                },
                responseType: 'arraybuffer'
            }).then((response) => {

                if (response.status == 200) {
                    console.log("download_pdf成功", response.data);
                    console.log(response.headers);

                    var FileSaver = require('file-saver');
                    FileSaver.saveAs(
                        new Blob(
                            [response.data],
                            //{ type: "application/pdf" } 
                            { type: mimeType }
                        ),
                        filename
                    );

                    /*
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    */
                }

            }).catch((error) => {
                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log("download_pdf失敗", error)
            })
    }

}




export const GetFileList = (socket, access_token, pageSize, pageToken, group) => {

    return (dispatch) => {




        console.log('here')
        axios.get(LoopbackCall.url + 'Util/get_file_list',
            {
                params: {
                    pageSize: pageSize,
                    pageToken: pageToken,
                    group: group,
                    access_token: access_token
                }
            }).then((response) => {
                if (response.status == 200) {

                    console.log("完了")

                    dispatch({
                        type: "GET_ALL_GDRIVE_FILES",
                        value: response.data.files
                    })

                    dispatch({
                        type: "GET_ALL_GDRIVE_FILES_NEXT_PAGE_TOKEN",
                        value: response.data.nextPageToken
                    })
                }
            }).catch((error) => {

                
                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                //console.log("GET_ALL_" + modelName + " ERR", error)

                //dispatch({
                //    type: "GET_ALL_" + modelName,
                //    value: []
                //})
            })
    }
}

export const UploadFile = (socket, file, access_token, pagesize, group) => {

    return (dispatch) => {


        console.log(file)

        let formData = new FormData();
        formData.append("file", file);

        //axios.post(LoopbackCall.url + modelName + '/upload_file',
        //axios.post(LoopbackCall.url + 'container/a/upload',
        axios.post(LoopbackCall.url + 'Util/upload_file',
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                params: {
                    access_token: access_token,
                    group: group
                }
            }).then((response) => {
                if (response.status == 200) {

                    axios.get(LoopbackCall.url + 'Util/get_file_list',
                        {
                            params: {
                                pageSize: pagesize,
                                pageToken: null,
                                group: group,
                                access_token: access_token
                            }
                        }).then((response) => {
                            if (response.status == 200) {

                                dispatch({
                                    type: "GET_ALL_GDRIVE_FILES",
                                    value: response.data.files
                                })

                                dispatch({
                                    type: "GET_ALL_GDRIVE_FILES_NEXT_PAGE_TOKEN",
                                    value: response.data.nextPageToken
                                })
                            }
                        }).catch((error) => {
                            console.log(error)
                        })


                }
            }).catch((error) => {

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log(error)


            })
    }
}


export const UploadFileWithCB = (socket, file, access_token, group, getModelProps, page, numData) => {




    return (dispatch) => {


        console.log(file)

        let formData = new FormData();
        formData.append("file", file);

        //axios.post(LoopbackCall.url + modelName + '/upload_file',
        //axios.post(LoopbackCall.url + 'container/a/upload',
        axios.post(LoopbackCall.url + 'Util/upload_file',
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                params: {
                    access_token: access_token,
                    group: group
                }
            }).then((response) => {
                if (response.status == 200) {

                    axios.get(LoopbackCall.url + getModelProps.modelname, {
                        params: {
                            filter: {
                                limit: numData,
                                skip: (page - 1) * numData,
                                include: getModelProps.arrInclude,
                                order: getModelProps.order,
                                where:{"Trashed":false}
                            },
                            access_token: access_token
                        }
                    }).then((response) => {
                        if (response.status == 200) {
                            dispatch({
                                type: "GET_ALL_" + getModelProps.modelname,
                                value: response.data
                            })
                        }
                    }).catch((error) => {
                        console.log("GET_ALL_" + getModelProps.modelname + " ERR", error)

                        dispatch({
                            type: "GET_ALL_" + getModelProps.modelname,
                            value: []
                        })
                    })


                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log(error)


            })
    }
}