import React, { Component, Children } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { logout } from '../../store/actions/AuthAction';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FaceIcon from '@material-ui/icons/Face';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import HomeIcon from '@material-ui/icons/Home';
import AttachmentIcon from '@material-ui/icons/Attachment';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ConstructionIcon from '@mui/icons-material/Construction';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CalculateIcon from '@mui/icons-material/Calculate';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

const drawerWidth = 250;

const styles = theme => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarMember: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        background: 'linear-gradient(45deg, #BE9401, #feca16)',

    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
        background: 'linear-gradient(45deg, #BE9401, #feca16)'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    toolbarBlack: {
        background: 'linear-gradient(45deg, #feca16, #BE9401)'
    },
    content: {
        flexGrow: 1,

        background: '#fafafa',
        height: '100vh'
    },
    black: {
        color: 'black',
    },
    pageTitle: {
        color: 'black',
    },

    link: {
        '&:hover': {
            color: '#000000',
        },
    }
});




class PageWrapper extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: true,
            anchorEl: null,
        }

        this.closeMenu = this.closeMenu.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.closeUserMenu = this.closeUserMenu.bind(this);
        this.openUserMenu = this.openUserMenu.bind(this);

    }

    closeMenu = () => {
        this.setState({ menuOpen: false })
    };

    openMenu = (event) => {
        this.setState({ menuOpen: true })
    };

    closeUserMenu = () => {
        this.setState({ userMenuOpen: false })
    };

    openUserMenu = (event) => {
        this.setState({ userMenuOpen: true, anchorEl: event.currentTarget })
    };






    render() {

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, this.props.role == 'euroalbek' && {
                        [classes.appBarShift]: this.state.menuOpen,
                    })}
                >
                    <Toolbar className={classes.toolbarBlack}>
                        {this.props.role == 'euroalbek' &&
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.openMenu}
                                edge="start"
                                className={clsx(classes.menuButton,
                                    classes.black,
                                    { [classes.hide]: this.state.menuOpen }
                                )}
                            >
                                <MenuIcon />
                            </IconButton>

                        }

                        <Typography variant="h6" noWrap className={classes.pageTitle}>{this.props.Title}</Typography>
                        {this.props.role == 'member' &&
                            <Button component={RouterLink} to="/presenze" >
                                <EmojiPeopleIcon className={classes.black} /></Button>
                        }
                        {this.props.role == 'member' &&
                            <Button component={RouterLink} to="/stipendi" >
                                <PaymentsIcon className={classes.black} /></Button>
                        }
                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.openUserMenu} >
                            <FaceIcon className={classes.black} /></Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.userMenuOpen)}
                            onClose={this.closeUserMenu}
                        >
                            <MenuItem
                                button
                                component={RouterLink}
                                to="/changepassword"
                                className={classes.link}
                                onClick={this.closeUserMenu}>Cambia password</MenuItem>
                            <MenuItem
                                onClick={(e) => { this.props.logout(e, this.props.logininfo) }}>Esci</MenuItem>
                        </Menu>
                        <Typography variant="h6" noWrap className={classes.black}>{this.props.usernameOrEmail}</Typography>
                        {this.props.role == 'euroalbek' &&
                            <Button component={RouterLink} to="/" >
                                <HomeIcon className={classes.black} /></Button>
                        }

                    </Toolbar>
                </AppBar>
                {this.props.role == 'euroalbek' &&
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: this.state.menuOpen,
                            [classes.drawerClose]: !this.state.menuOpen,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: this.state.menuOpen,
                                [classes.drawerClose]: !this.state.menuOpen,
                            }),
                        }}
                    >
                        <div className={classes.toolbar}>
                            <IconButton onClick={this.closeMenu}>
                                <ChevronLeftIcon className={classes.black} />
                            </IconButton>
                        </div>



                        <List>

                            <Divider />

                            <ListItem button component={RouterLink} to="/clienti" className={classes.link}>
                                <ListItemIcon>
                                    <PermIdentityIcon className={classes.black} />
                                </ListItemIcon>
                                <ListItemText primary="Clienti" />
                            </ListItem>

                            <Divider />
                            <ListItem button component={RouterLink} to="/dipendenti" className={classes.link}>
                                <ListItemIcon>
                                    <EngineeringIcon className={classes.black} />
                                </ListItemIcon>
                                <ListItemText primary="Dipendenti" />
                            </ListItem>
                            <ListItem button component={RouterLink} to="/presenze" className={classes.link}>
                                <ListItemIcon>
                                    <EmojiPeopleIcon className={classes.black} />
                                </ListItemIcon>
                                <ListItemText primary="Presenze" />
                            </ListItem>
                            <ListItem button component={RouterLink} to="/stipendi" className={classes.link}>
                                <ListItemIcon>
                                    <PaymentsIcon className={classes.black} />
                                </ListItemIcon>
                                <ListItemText primary="Stipendi" />
                            </ListItem>

                            <Divider />

                            <ListItem button component={RouterLink} to="/artigiani" className={classes.link}>
                                <ListItemIcon>
                                    <PsychologyIcon className={classes.black} />
                                </ListItemIcon>
                                <ListItemText primary="Artigiani" />
                            </ListItem>



                            <Divider />


                            <ListItem button component={RouterLink} to="/dettaglimodalita" className={classes.link}>
                                <ListItemIcon>
                                    <PaymentIcon className={classes.black} />
                                </ListItemIcon>
                                <ListItemText primary="Dettagli Modalita" />
                            </ListItem>

                            <ListItem button component={RouterLink} to="/speseditta" className={classes.link}>
                                <ListItemIcon>
                                    <ShoppingCartIcon className={classes.black} />
                                </ListItemIcon>
                                <ListItemText primary="Spese Ditta" />
                            </ListItem>


                            <Divider />
                            <ListItem button component={RouterLink} to="/cantieri" className={classes.link}>
                                <ListItemIcon>
                                    <ConstructionIcon className={classes.black} />
                                </ListItemIcon>
                                <ListItemText primary="Cantieri" />
                            </ListItem>
                            <ListItem button component={RouterLink} to="/preventivi" className={classes.link}>
                                <ListItemIcon>
                                    <ReceiptLongIcon className={classes.black} />
                                </ListItemIcon>
                                <ListItemText primary="Preventivi" />
                            </ListItem>
                            <ListItem button component={RouterLink} to="/giornalecantieri" className={classes.link}>
                                <ListItemIcon>
                                    <NoteAltIcon className={classes.black} />
                                </ListItemIcon>
                                <ListItemText primary="Giornale di Cantieri" />
                            </ListItem>


                            <Divider />
                            <ListItem button component={RouterLink} to="/somma" className={classes.link}>
                                <ListItemIcon>
                                    <CalculateIcon className={classes.black} />
                                </ListItemIcon>
                                <ListItemText primary="Somma" />
                            </ListItem>

                            <Divider />
                        </List>




                    </Drawer>}
                <main className={classes.content} >
                    <div className={classes.toolbar} />
                    {this.props.accesstoken_expired
                        ? <div>Si prega di effettuare nuovamente il login</div>
                        : this.props.children}
                </main>
            </div>
        );

    }


}

const mapStateToProps = (state) => {
    return {
        logininfo: state.auth.logininfo,
        usernameOrEmail: state.auth.usernameOrEmail,
        role: state.auth.role,
        accesstoken_expired: state.auth.accesstoken_expired,
    }

}

const mapDispatchToProps = (dispatch) => {

    return {

        logout: (e, logininfo) => {
            e.preventDefault();
            dispatch(logout(logininfo));

        },

    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(PageWrapper));