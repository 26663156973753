import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames'
import { withStyles } from "@material-ui/core/styles";
import * as StipendiAction from '../../store/actions/StipendiAction'

import Grid from '@material-ui/core/Grid';


import Button from '@material-ui/core/Button';
import * as DateTool from '../../util/DateTool'
import * as StrTool from '../../util/StrTool'
import { styles } from '../../component/css/commonstyles'




class RowAccontoForTable extends Component {

    constructor(props) {

        super(props);
        this.state = {};

    }

    createMsgAcconto = (acconto) => {
        return 'Conferma un anticipo di ' + acconto + ' euro?'
    }

    render() {

        const { classes } = this.props;
        return (
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end">
                <Grid item xs={3} >
                    {this.props.m['Acconto' + this.props.index]}
                </Grid>
                <Grid item xs={9} >
                    {this.props.m['Acconto' + this.props.index]
                        ? this.props.m['AccontoConfermato' + this.props.index]
                            ? <span className={classNames(classes.color_blue)} >confermato {StrTool.createStrDD_MM_YYYY(this.props.m['AccontoConfermato' + this.props.index])}</span>
                                : this.props.role == 'euroalbek'
                                ? <span className={classNames(classes.color_red)} >Da confermare</span>
                                : <Button
                                    variant="contained"
                                    className={classes.formControlXS}
                                    onClick={(e) => {

                                        var formValue = this.props.m
                                        formValue["AccontoConfermato" + this.props.index] = DateTool.returnItalianTime();
                                        console.log('formValue', formValue)

                                        if (window.confirm(this.createMsgAcconto(this.props.m['Acconto' + this.props.index]))) {
                                            this.props.submitCreate(
                                                this.props.logininfo.userId,
                                                this.props.page,
                                                this.props.numData,
                                                formValue,
                                                this.props.logininfo.id)
                                        }

                                    }}>Confermo</Button>
                        : null}
                            </Grid>

            </Grid>

                )
    }

}



const mapStateToProps = (state) => {
    return {
                    logininfo: state.auth.logininfo,
                role: state.auth.role
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
                    submitCreate: (userId, page, numData, formValue, access_token) => {
                    dispatch(StipendiAction.apiPostOrPut(userId, page, numData, formValue, access_token))
                },
    }
}

                export default connect(
                mapStateToProps,
                mapDispatchToProps
                )(withStyles(styles, {withTheme: true })(RowAccontoForTable));