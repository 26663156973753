import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as ModelAction from '../../../../../store/actions/ModelAction'
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import * as DateTool from '../../../../../util/DateTool';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Searcher from '../../../../common/Searcher';

import * as StrTool from '../../../../../util/StrTool';

import { modelProps as modelPropsDipendenti } from '../../../../../model/Dipendenti';
import { modelProps as modelPropsCantieri } from '../../../../../model/Cantieri';

import { checkInputRequired } from '../../../../../model/Presenze';


import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import itLocale from "date-fns/locale/it";
import DateFnsUtils from '@date-io/date-fns';


const styles = theme => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    table: {
        // minWidth: 650,
    },
    tableHead: {
        background: "#b9b9b9"
    },

    row: {
        '&:hover': {
            background: "#CACACA"
        }
    },
    cell: {
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    modal: {
        display: 'block',
        position: 'absolute',
        overflow: 'scroll',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 900,
    },
    buttonback: {
        backgroundColor: "#b9b9b9",
        border: '0px solid #000',
        boxShadow: theme.shadows[0],
        padding: theme.spacing(1.5, 1.5, 1.5),

    },
    formControlS: {
        margin: theme.spacing(1),
        width: 120,
    },
    formControlM: {
        margin: theme.spacing(1),
        width: 300
    },
    formControlL: {
        margin: theme.spacing(1),
        width: 800
    },
    grammar: {
        width: 800
    },
    grammarPreview: {
        width: 800
    },
    cell_short: {
        fontSize: "10px",
        width: 50,
    },
    cell_medium: {
        fontSize: "10px",
        width: 100,
    },
    cell_clickable: {
        cursor: "pointer"
    },
    cell_searched: {
        background: "#FA8072"
    },
    gridItem: {
    },
    button: {
        background: 'white',
        borderRadius: 3,
        border: 0,
        color: 'black',
        padding: '0 20px',
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    container: {
        maxHeight: 600,
    },
});

const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
    },
})




var defaultFormValue = {


};

class CrudModalForUserCreateClienti extends Component {

    constructor(props) {

        super();
        this.state = {
            formValue: Object.create(defaultFormValue),
            unfilledRequiredFIeld: true,
            openModal: false,
            invalidEmail: false,
        };


    }

    componentDidMount() {



    }


    handlCreateModalOpen = () => {
        this.setState({
            openModal: true,
            formValue: Object.create(defaultFormValue),
            unfilledRequiredFIeld: true,
            invalidEmail: false,
        })

        //初期値
        this.onChangeInputCommon('Nome', null)
        this.onChangeInputCommon('Cognome', null)
        this.onChangeInputCommon('RagioneSociale', null)
        this.onChangeInputCommon('EMail', null)
        this.onChangeInputCommon('Agenzia', null)
        this.onChangeInputCommon('Iban', null)
        this.onChangeInputCommon('CodFisc', null)
        this.onChangeInputCommon('PIva', null)
        this.onChangeInputCommon('SedeLegaleIndirizzo', null)
        this.onChangeInputCommon('SedeLegaleProv', null)
        this.onChangeInputCommon('SedeLegaleLocalita', null)
        this.onChangeInputCommon('SedeLegaleCAP', null)
        this.onChangeInputCommon('SedeAmministrativaIndirizzo', null)
        this.onChangeInputCommon('SedeAmministrativaProv', null)
        this.onChangeInputCommon('SedeAmministrativaLocalita', null)
        this.onChangeInputCommon('SedeAmministrativaCAP', null)
        this.onChangeInputCommon('Telefono1', null)
        this.onChangeInputCommon('Telefono2', null)
        this.onChangeInputCommon('Cel1', null)
        this.onChangeInputCommon('Cel2', null)
        this.onChangeInputCommon('FAX', null)
        this.onChangeInputCommon('Sito', null)
        this.onChangeInputCommon('Banca', null)

    };



    handleModalClose = () => {
        this.setState({ openModal: false })
    };



    onChangeInputCommon = (key, value) => {


        var newFormValue = this.state.formValue;
        newFormValue[key] = value;
        this.setState({ formValue: newFormValue });



    }


    onChangeInputCommonEmail = (key, value) => {

        var newFormValue = this.state.formValue;
        newFormValue[key] = value == '' ? null : value ; //空文字はnullへ置き換える
        this.setState({ formValue: newFormValue });
    }


    handleDateTimeChange = (datetime, key) => {

        var newFormValue = this.state.formValue;

        if (datetime == null || datetime == "") {
            newFormValue[key] = null;
        } else {
            newFormValue[key] = DateTool.createJSONDateWithoutTime(datetime);
        }
        this.setState({ formValue: newFormValue });

    }


    render() {

        var keys = Object.keys(this.props.modelProps);

        const { classes } = this.props;

        const nums = [];
        for (let i = 1; i <= 50; i++) {
            nums.push(i);
        }

        return (
            <div>
                <List>
                    <ListItem button key="Lessons" onClick={(e) => { e.preventDefault(); this.handlCreateModalOpen() }}>
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary="Aggiunta" />
                    </ListItem>
                </List>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.state.openModal}
                    onClose={this.handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openModal}>
                        <div className={classes.modalpaper}>

                            <Grid container >
                                <Grid item xs={12}  >
                                    <h4 id="transition-modal-title">{this.props.modelName}</h4>
                                </Grid>
                                <Grid item xs={12}  >
                                    <form className={classes.root} noValidate autoComplete="on">
                                        <Grid container justify="left"  >

                                            <Grid item xs={12} key={'form_Nome'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Nome*'
                                                        value={this.state.formValue['Nome']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Nome', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Cognome'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Cognome'
                                                        value={this.state.formValue['Cognome']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Cognome', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_RagioneSociale'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='RagioneSociale'
                                                        value={this.state.formValue['RagioneSociale']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('RagioneSociale', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_EMail'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='EMail'
                                                        value={this.state.formValue['EMail']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommonEmail('EMail', e.target.value)
                                                            this.setState({ invalidEmail: !StrTool.validateEmail(e.target.value) })
                                                        }} /> </FormControl>
                                                {this.state.invalidEmail && <h5 style={{ color: 'red' }}>email non valido</h5>}
                                            </Grid>
                                            <Grid item xs={12} key={'form_Agenzia'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Agenzia'
                                                        value={this.state.formValue['Agenzia']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Agenzia', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Banca'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Banca'
                                                        value={this.state.formValue['Banca']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Banca', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Iban'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Iban'
                                                        value={this.state.formValue['Iban']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Iban', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_CodFisc'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='CodFisc'
                                                        value={this.state.formValue['CodFisc']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('CodFisc', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_PIva'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='PIva'
                                                        value={this.state.formValue['PIva']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('PIva', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_SedeLegaleIndirizzo'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='SedeLegaleIndirizzo'
                                                        value={this.state.formValue['SedeLegaleIndirizzo']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('SedeLegaleIndirizzo', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_SedeLegaleProv'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='SedeLegaleProv'
                                                        value={this.state.formValue['SedeLegaleProv']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('SedeLegaleProv', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_SedeLegaleLocalita'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='SedeLegaleLocalita'
                                                        value={this.state.formValue['SedeLegaleLocalita']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('SedeLegaleLocalita', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_SedeLegaleCAP'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='SedeLegaleCAP'
                                                        value={this.state.formValue['SedeLegaleCAP']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('SedeLegaleCAP', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_SedeAmministrativaIndirizzo'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='SedeAmministrativaIndirizzo'
                                                        value={this.state.formValue['SedeAmministrativaIndirizzo']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('SedeAmministrativaIndirizzo', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_SedeAmministrativaProv'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='SedeAmministrativaProv'
                                                        value={this.state.formValue['SedeAmministrativaProv']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('SedeAmministrativaProv', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_SedeAmministrativaLocalita'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='SedeAmministrativaLocalita'
                                                        value={this.state.formValue['SedeAmministrativaLocalita']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('SedeAmministrativaLocalita', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_SedeAmministrativaCAP'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='SedeAmministrativaCAP'
                                                        value={this.state.formValue['SedeAmministrativaCAP']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('SedeAmministrativaCAP', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Telefono1'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Telefono1'
                                                        value={this.state.formValue['Telefono1']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Telefono1', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Telefono2'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Telefono2'
                                                        value={this.state.formValue['Telefono2']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Telefono2', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Cel1'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Cel1'
                                                        value={this.state.formValue['Cel1']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Cel1', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Cel2'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Cel2'
                                                        value={this.state.formValue['Cel2']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Cel2', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_FAX'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='FAX'
                                                        value={this.state.formValue['FAX']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('FAX', e.target.value)
                                                        }} /> </FormControl> </Grid>
                                            <Grid item xs={12} key={'form_Sito'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Sito'
                                                        value={this.state.formValue['Sito']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Sito', e.target.value)
                                                        }} /> </FormControl> </Grid>

                                            <Grid item xs={12} >
                                                <Button
                                                    variant="contained"
                                                    disabled={this.state.formValue.Nome == null ||
                                                        this.state.formValue.Nome.trim() == '' ||
                                                        (this.state.formValue.Email != '' && this.state.invalidEmail)
                                                        ? true
                                                        : false}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        //最終更新者・日時を追加
                                                        var newFormValue = this.state.formValue;
                                                        newFormValue["LastModifiedBy"] = this.props.logininfo.userId;
                                                        newFormValue["LastModifiedAt"] = DateTool.returnItalianTime();
                                                        this.setState({ formValue: newFormValue });

                                                        this.props.submitCreate(
                                                            this.props.socket,
                                                            this.props.modelProps,
                                                            this.state.page,
                                                            this.state.numData,
                                                            this.state.formValue,
                                                            this.props.modelProps,
                                                            this.props.logininfo.id
                                                        );
                                                        this.handleModalClose();


                                                    }}>Aggiunta</Button>
                                            </Grid>
                                        </Grid >
                                    </form>
                                </Grid>
                            </Grid>



                        </div>
                    </Fade>
                </Modal >

            </div>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        logininfo: state.auth.logininfo,

    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        submitCreate: (socket, getModelProps, page, numData, formValue, postModelProps, access_token) => {
            dispatch(ModelAction.apiPost(socket, getModelProps, page, numData, formValue, postModelProps, access_token))
        },


    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CrudModalForUserCreateClienti));