import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import * as ModelAction from '../../../../../store/actions/ModelAction'
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import * as DateTool from '../../../../../util/DateTool';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Searcher from '../../../../common/Searcher'

import * as NumberTool from '../../../../../util/NumberTool'

import { modelProps as modelPropsDipendenti } from '../../../../../model/Dipendenti';
import { modelProps as modelPropsArtigiani } from '../../../../../model/Artigiani';


import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import itLocale from "date-fns/locale/it";
import DateFnsUtils from '@date-io/date-fns';


const styles = theme => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    table: {
        // minWidth: 650,
    },
    tableHead: {
        background: "#b9b9b9"
    },

    row: {
        '&:hover': {
            background: "#CACACA"
        }
    },
    cell: {
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    modal: {
        display: 'block',
        position: 'absolute',
        overflow: 'scroll',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 900,
    },
    buttonback: {
        backgroundColor: "#b9b9b9",
        border: '0px solid #000',
        boxShadow: theme.shadows[0],
        padding: theme.spacing(1.5, 1.5, 1.5),

    },
    formControlS: {
        margin: theme.spacing(1),
        width: 120,
    },
    formControlM: {
        margin: theme.spacing(1),
        width: 300
    },
    formControlL: {
        margin: theme.spacing(1),
        width: 800
    },
    grammar: {
        width: 800
    },
    grammarPreview: {
        width: 800
    },
    cell_short: {
        fontSize: "10px",
        width: 50,
    },
    cell_medium: {
        fontSize: "10px",
        width: 100,
    },
    cell_clickable: {
        cursor: "pointer"
    },
    cell_searched: {
        background: "#FA8072"
    },
    gridItem: {
    },
    button: {
        background: 'white',
        borderRadius: 3,
        border: 0,
        color: 'black',
        padding: '0 20px',
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    container: {
        maxHeight: 600,
    },
    div100: {
        height: '100%',
        width: '100%',

    },
});

const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
    },
})




var defaultFormValue = {};

class CrudModalForUserUpdateDettagliModalita extends Component {

    constructor(props) {
        super(props);

        var submitDisabled = false

        Object.keys(this.props.modelProps).forEach((k) => {

            if (k != "id") {
                switch (this.props.modelProps[k].type) {
                    case "number":
                        defaultFormValue[k] = 0;
                        return;
                    case "string":
                        if (this.props.modelProps[k].defaultValue) {
                            defaultFormValue[k] = this.props.modelProps[k].defaultValue;
                        } else {
                            defaultFormValue[k] = "";
                        }
                        return;
                    case "date":
                        defaultFormValue[k] = DateTool.createJSONDateWithoutTime(new Date());
                        return;
                    case "time":
                        defaultFormValue[k] = DateTool.createJSONDateWithoutTime(new Date());
                        return;
                    case "select":
                        defaultFormValue[k] = "";
                        return;

                }

                //入力必須項目がある場合は Update　ボタンを非活性にする
                //if (this.props.modelProps[k].inputRequired == true ){
                //    submitDisabled = true
                //}
            }


        })

        this.state = null;
        this.state = {
            formValue: defaultFormValue,
            unfilledRequiredFIeld: false,
            openUpdateModal: false,

        };

    }

    componentDidMount() {

    }



    handlUpdateModalOpen = (model) => {
        console.log("handlUpdateModalOpen", model)

        if (model['Artigiani'] != null) {
            model['Achi'] = 'Artigiani'
        }

        if (model['Dipendenti'] != null) {
            model['Achi'] = 'Dipendenti'
        }

        this.setState({
            openUpdateModal: true,
            formValue: model,
            unfilledRequiredFIeld: false

        })
    };

    handleModalClose = () => {
        this.setState({ openUpdateModal: false })
    };



    onChangeInputCommon = (key, value) => {


        var newFormValue = this.state.formValue;
        newFormValue[key] = value;
        this.setState({ formValue: newFormValue });



        this.checkInputRequired()

    }

    
    onChangeInputNumCommon = (key, value) => {
        this.setState({ formValue: NumberTool.validateAndSetNewFormValue(key, value, this.state.formValue) });
    }

    handleDateTimeChange = (datetime, key) => {

        var newFormValue = this.state.formValue;

        if (datetime == null || datetime == "") {
            newFormValue[key] = null;
        } else {
            newFormValue[key] = DateTool.createJSONDateWithoutTime(datetime);
        }
        this.setState({ formValue: newFormValue });

    }

    checkInputRequired = () => {

        var unfilledRequiredFIeld = false

        if (this.state.formValue.Dipendenti == null) {
            unfilledRequiredFIeld = true
        }

        if (this.state.formValue.Data == null) {
            unfilledRequiredFIeld = true
        }
        if (this.state.formValue.Presenza == null) {
            unfilledRequiredFIeld = true
        }

        this.setState({ unfilledRequiredFIeld: unfilledRequiredFIeld });
    }

    render() {

        var keys = Object.keys(this.props.modelProps);

        const { classes } = this.props;

        return (
            <div className={classes.cell_clickable}>
                <div onClick={(e) => { e.preventDefault(); this.handlUpdateModalOpen(this.props.modelValue) }}>
                    <EditIcon />
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.state.openUpdateModal}
                    onClose={this.handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openUpdateModal}>
                        <div className={classes.modalpaper}>

                            <Grid container >
                                <Grid item xs={12}  >
                                    <h4 id="transition-modal-title">{this.props.modelName}</h4>
                                </Grid>
                                <Grid item xs={5}  >



                                    <Grid item xs={12} key={'radio_a_Chi'} >
                                        <FormControl className={classes.formControlS}>
                                            <FormLabel component="legend">a chi*</FormLabel>
                                            <RadioGroup aria-label='AChi' name='AChi' value={this.state.formValue['Achi']} onChange={(e) => {
                                                e.preventDefault()
                                                this.onChangeInputCommon('Achi', e.target.value)
                                                this.onChangeInputCommon('Artigiani', null)
                                                this.onChangeInputCommon('Dipendenti', null)
                                            }}>
                                                <FormControlLabel key={'key_artigiani'} value="Artigiani" control={<Radio />} label="Artigiani" />
                                                <FormControlLabel key={'key_dipendenti'} value="Dipendenti" control={<Radio />} label="Dipendenti" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>




                                    <Grid item xs={12}  >
                                        {this.state.formValue['Achi'] == 'Artigiani'
                                            ? <Searcher
                                                modelProps={modelPropsArtigiani}
                                                onSelect={(artigiani) => {

                                                    this.onChangeInputCommon('Artigiani', artigiani)


                                                }}
                                                showpropArr={['Nome', 'Cognome']} />
                                            : null}

                                        {this.state.formValue['Achi'] == 'Dipendenti'
                                            ? <Searcher
                                                modelProps={modelPropsDipendenti}
                                                onSelect={(dipendenti) => {

                                                    this.onChangeInputCommon('Dipendenti', dipendenti)


                                                }}
                                                showpropArr={['Nome', 'Cognome']} />
                                            : null}


                                    </Grid>

                                </Grid>
                                <Grid item xs={7}  >
                                    {this.state.formValue['Achi'] == null
                                        ? null
                                        : <form className={classes.root} noValidate autoComplete="on">
                                            <Grid container justify="left"  >
                                                <Grid item xs={12} key={'form_Dipendenti'}  >
                                                    <FormControl className={classes.formControlM}>

                                                        {this.state.formValue['Achi'] == 'Artigiani'
                                                            ? <div>
                                                                <FormLabel component="legend">Artigiani*</FormLabel>
                                                                <h4> {this.state.formValue.Artigiani
                                                                    ? this.state.formValue.Artigiani.Cognome
                                                                        ? this.state.formValue.Artigiani.Nome + ' ' + this.state.formValue.Artigiani.Cognome
                                                                        : this.state.formValue.Artigiani.Nome
                                                                    : null
                                                                }</h4>
                                                            </div>
                                                            : null
                                                        }
                                                        {this.state.formValue['Achi'] == 'Dipendenti'
                                                            ? <div>
                                                                <FormLabel component="legend">Dipendenti*</FormLabel>
                                                                <h4> {this.state.formValue.Dipendenti
                                                                    ? this.state.formValue.Dipendenti.Cognome
                                                                        ? this.state.formValue.Dipendenti.Nome + ' ' + this.state.formValue.Dipendenti.Cognome
                                                                        : this.state.formValue.Dipendenti.Nome
                                                                    : null
                                                                }</h4>
                                                            </div>
                                                            : null
                                                        }


                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}   >
                                                    <FormControl className={classes.formControlM}>

                                                        {this.state.formValue['Achi'] == 'Artigiani'
                                                            ? <div>
                                                                <FormLabel component="legend">IBAN</FormLabel>
                                                                <h5> {this.state.formValue.Artigiani
                                                                    ? this.state.formValue.Artigiani.IBAN
                                                                        ? this.state.formValue.Artigiani.IBAN
                                                                        : 'no data'
                                                                    : null
                                                                }</h5>
                                                            </div>
                                                            : null
                                                        }
                                                        {this.state.formValue['Achi'] == 'Dipendenti'
                                                            ? <div>
                                                                <FormLabel component="legend">IBAN</FormLabel>
                                                                <h5> {this.state.formValue.Dipendenti
                                                                    ? this.state.formValue.Dipendenti.IBAN
                                                                        ? this.state.formValue.Dipendenti.IBAN
                                                                        : 'no data'
                                                                    : null
                                                                }</h5>
                                                            </div>
                                                            : null
                                                        }
                                                    </FormControl>
                                                </Grid>





                                                <Grid item xs={12} key={'form_Presenza'} >
                                                    <FormControl className={classes.formControlM}>
                                                        <FormLabel component="legend">Tipo di Pagamento</FormLabel>
                                                        <RadioGroup aria-label='TipoPag' name='TipoPag' value={this.state.formValue['TipoPag']} onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('TipoPag', e.target.value)
                                                        }}>
                                                            {
                                                                this.props.modelProps.props['TipoPag'].option.map((op) => {
                                                                    return (
                                                                        <FormControlLabel key={'key_' + op.val} value={op.val} control={<Radio />} label={op.label} />
                                                                    )
                                                                })
                                                            }
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} key={'form_Importo'}  >
                                                    <FormControl className={classes.formControlM}>
                                                        <TextField
                                                            id="standard-basic"
                                                            label='Importo*'
                                                            value={this.state.formValue['Importo']}
                                                            onChange={(e) => {
                                                                e.preventDefault()
                                                                this.onChangeInputNumCommon('Importo', e.target.value)
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} key={'form_Assegno'}  >
                                                    <FormControl className={classes.formControlM}>
                                                        <TextField
                                                            id="standard-basic"
                                                            label='Assegno'
                                                            value={this.state.formValue['Assegno']}
                                                            onChange={(e) => {
                                                                e.preventDefault()
                                                                this.onChangeInputCommon('Assegno', e.target.value)
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>





                                                <Grid item xs={12} key={'form_DataScad'}  >
                                                    <FormControl className={classes.formControlM}>
                                                        <MuiThemeProvider theme={customTheme}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                                                                <KeyboardDatePicker
                                                                    margin="normal"
                                                                    id="date-picker-dialog"
                                                                    label='DataScad'
                                                                    format="dd/MM/yyyy"
                                                                    value={this.state.formValue['DataScad']}
                                                                    onChange={(e) => { this.handleDateTimeChange(e, 'DataScad') }}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </MuiThemeProvider>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} key={'form_DataPag'}  >
                                                    <FormControl className={classes.formControlM}>
                                                        <MuiThemeProvider theme={customTheme}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                                                                <KeyboardDatePicker
                                                                    margin="normal"
                                                                    id="date-picker-dialog"
                                                                    label='DataPag'
                                                                    format="dd/MM/yyyy"
                                                                    value={this.state.formValue['DataPag']}
                                                                    onChange={(e) => { this.handleDateTimeChange(e, 'DataPag') }}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </MuiThemeProvider>
                                                    </FormControl>
                                                </Grid>




                                                <Grid item xs={12} >
                                                    <Button variant="contained"
                                                        disabled={(this.state.formValue.Artigiani == null &&
                                                            this.state.formValue.Dipendenti == null) ||
                                                            this.state.formValue.Importo == null ||
                                                            (this.state.formValue.Importo != null && (this.state.formValue.Importo + '').trim() == '')
                                                            ? true
                                                            : false}

                                                        onClick={(e) => {
                                                            e.preventDefault();

                                                            console.log('this.state.formValue.Importo', this.state.formValue.Importo)

                                                            //未入力の必須項目が存在するか
                                                            var unfilledRequiredFIeld = this.checkInputRequired()
                                                            if (unfilledRequiredFIeld) {

                                                            } else {

                                                                //最終更新者・日時を追加
                                                                var newFormValue = this.
                                                                    state.formValue;
                                                                newFormValue["LastModifiedBy"] = this.props.logininfo.userId;
                                                                newFormValue["LastModifiedAt"] = DateTool.returnItalianTime();

                                                                if (this.state.formValue.Importo == '') {
                                                                    newFormValue["Importo"] = null
                                                                } 
                                                                
                                                                this.setState({ formValue: newFormValue });

                                                                this.props.submitUpdate(
                                                                    this.props.socket,
                                                                    this.props.modelProps,
                                                                    this.state.page,
                                                                    this.state.numData,
                                                                    this.state.formValue,
                                                                    this.props.modelProps,
                                                                    this.props.logininfo.id
                                                                );
                                                                this.handleModalClose();
                                                            }



                                                        }}>Aggiorna</Button>
                                                </Grid>
                                            </Grid >
                                        </form>}

                                </Grid>
                            </Grid>
                        </div>
                    </Fade>
                </Modal >
            </div>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        logininfo: state.auth.logininfo

    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        submitUpdate: (socket, getModelProps, page, numData, formValue, putModelProps, access_token) => {
            dispatch(ModelAction.apiPut(socket, getModelProps, page, numData, formValue, putModelProps, access_token))
        },

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CrudModalForUserUpdateDettagliModalita));