import { combineReducers } from 'redux';
import { auth } from './AuthReducer';
import { webSocket } from './WebSocketReducer';
import { model } from './ModelReducers';
import { somma } from './SommaReducers';
import { gdrivefile } from './GDriveFileReducers'

import { stipendi } from './StipendiReducers';
export const reducer = combineReducers(
    {
        model,somma, auth, webSocket, gdrivefile,stipendi
    }
);