import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as ModelAction from '../../../../../store/actions/ModelAction'
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import * as DateTool from '../../../../../util/DateTool';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Searcher from '../../../../common/Searcher'
import TextareaAutosize from '@mui/base/TextareaAutosize';

import { modelProps } from '../../../../../model/Cantieri';

import { checkInputRequired } from '../../../../../model/LavoriEseguiti';


import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import itLocale from "date-fns/locale/it";
import DateFnsUtils from '@date-io/date-fns';


const styles = theme => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    table: {
        // minWidth: 650,
    },
    tableHead: {
        background: "#b9b9b9"
    },

    row: {
        '&:hover': {
            background: "#CACACA"
        }
    },
    cell: {
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    modal: {
        display: 'block',
        position: 'absolute',
        overflow: 'scroll',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 1200,
    },
    buttonback: {
        backgroundColor: "#b9b9b9",
        border: '0px solid #000',
        boxShadow: theme.shadows[0],
        padding: theme.spacing(1.5, 1.5, 1.5),

    },
    formControlS: {
        margin: theme.spacing(1),
        width: 120,
    },
    formControlM: {
        margin: theme.spacing(1),
        width: 300
    },
    formControlL: {
        margin: theme.spacing(1),
        width: 800
    },
    grammar: {
        width: 800
    },
    grammarPreview: {
        width: 800
    },
    cell_short: {
        fontSize: "10px",
        width: 50,
    },
    cell_medium: {
        fontSize: "10px",
        width: 100,
    },
    cell_clickable: {
        cursor: "pointer"
    },
    cell_searched: {
        background: "#FA8072"
    },
    gridItem: {
    },
    button: {
        background: 'white',
        borderRadius: 3,
        border: 0,
        color: 'black',
        padding: '0 20px',
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    container: {
        maxHeight: 600,
    },
});

const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
    },
})




var defaultFormValue = {

    "Data": null,
    "Cantieri": null,
    "LavoriEseguiti": null
};

class CrudModalForUserCreateLavoriEseguiti extends Component {

    constructor(props) {

        super();
        this.state = {
            formValue: Object.create(defaultFormValue),
            unfilledRequiredFIeld: true,
            openCreateModal: false,

        };


    }

    componentDidMount() {



    }


    handlCreateModalOpen = () => {
        this.setState({
            openCreateModal: true,
            formValue: Object.create(defaultFormValue),
            unfilledRequiredFIeld: true
        })

        //初期値
        this.handleDateTimeChange(new Date(), 'Data')
        this.onChangeInputCommon('Cantieri', null)
        this.onChangeInputCommon('LavoriEseguiti', null)

    };



    handleModalClose = () => {
        this.setState({ openCreateModal: false })
    };



    onChangeInputCommon = (key, value) => {


        var newFormValue = this.state.formValue;
        newFormValue[key] = value;
        this.setState({ formValue: newFormValue });



        this.checkInputRequired()

    }

    handleDateTimeChange = (datetime, key) => {

        var newFormValue = this.state.formValue;

        if (datetime == null || datetime == "") {
            newFormValue[key] = null;
        } else {
            newFormValue[key] = DateTool.createJSONDateWithoutTime(datetime);
        }
        this.setState({ formValue: newFormValue });

    }

    checkInputRequired = () => {
        this.setState({ unfilledRequiredFIeld: checkInputRequired(this.state.formValue) });
    }

    render() {

        var keys = Object.keys(this.props.modelProps);

        const { classes } = this.props;

        const nums = [];
        for (let i = 1; i <= 50; i++) {
            nums.push(i);
        }

        return (
            <div>
                <List>
                    <ListItem button key="Lessons" onClick={(e) => { e.preventDefault(); this.handlCreateModalOpen() }}>
                        <ListItemIcon>
                            <AddIcon />
                        </ListItemIcon>
                        <ListItemText primary="Aggiunta" />
                    </ListItem>
                </List>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.state.openCreateModal}
                    onClose={this.handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openCreateModal}>
                        <div className={classes.modalpaper}>

                            <Grid container >
                                <Grid item xs={12}  >
                                    <h4 id="transition-modal-title">Giornale di Cantieri</h4>
                                </Grid>
                                <Grid item xs={3}  >
                                    <Grid item xs={12}  >
                                        <Searcher
                                            modelProps={modelProps}
                                            onSelect={(cantieri) => {

                                                this.onChangeInputCommon('Cantieri', cantieri)

                                            }}
                                            showpropArr={['Descrizione']} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={9}  >
                                    <form className={classes.root} noValidate autoComplete="on">
                                        <Grid container justify="left"  >

                                            <Grid item xs={12} key={'form_Data'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <MuiThemeProvider theme={customTheme}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                id="date-picker-dialog"
                                                                label='Data*'
                                                                format="dd/MM/yyyy"
                                                                value={this.state.formValue['Data']}
                                                                onChange={(e) => { this.handleDateTimeChange(e, 'Data') }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </MuiThemeProvider>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} key={'form_Cantieri'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <FormLabel component="legend">Cantieri*</FormLabel>
                                                    <h4> {this.state.formValue.Cantieri && this.state.formValue.Cantieri.Descrizione}</h4>

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} key={'form_LavoriEseguiti'}  >
                                                <FormControl className={classes.formControlL}>
                                                    <FormLabel component="legend">Lavori Eseguiti* (max 500 letters)</FormLabel>
                                                    <TextareaAutosize
                                                        id="standard-basic"
                                                        aria-label="LavoriEseguiti*"
                                                        minRows={10}
                                                        maxRows={10}
                                                        placeholder="Lavori Eseguiti"
                                                        value={this.state.formValue['LavoriEseguiti']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            var text = ''
                                                            if (e.target.value.length > 500) {
                                                                text = e.target.value.substr(0, 500)
                                                            } else {
                                                                text = e.target.value
                                                            }


                                                            this.onChangeInputCommon('LavoriEseguiti', text)
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={12} >
                                                <Button
                                                    variant="contained"
                                                    disabled={this.state.formValue.Data == null ||
                                                        this.state.formValue.Data.trim() == '' ||
                                                        this.state.formValue.Cantieri == null ||
                                                        this.state.formValue.LavoriEseguiti == null ||
                                                        this.state.formValue.LavoriEseguiti.trim() == ''
                                                        ? true
                                                        : false}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        //最終更新者・日時を追加
                                                        var newFormValue = this.state.formValue;
                                                        newFormValue["LastModifiedBy"] = this.props.logininfo.userId;
                                                        newFormValue["LastModifiedAt"] = DateTool.returnItalianTime();
                                                        this.setState({ formValue: newFormValue });

                                                        console.log('formValue', this.state.formValue)

                                                        this.props.submitCreate(this.props.socket,
                                                            this.props.modelProps,
                                                            this.state.page,
                                                            this.state.numData,
                                                            this.state.formValue,
                                                            this.props.modelProps,
                                                            this.props.logininfo.id
                                                        );
                                                        this.handleModalClose();


                                                    }}>Aggiunta</Button>
                                            </Grid>
                                        </Grid >
                                    </form>
                                </Grid>
                            </Grid>



                        </div>
                    </Fade>
                </Modal >

            </div>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        logininfo: state.auth.logininfo,

    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        submitCreate: (socket, getModelProps, page, numData, formValue, postModelProps, access_token) => {
            dispatch(ModelAction.apiPost(socket, getModelProps, page, numData, formValue, postModelProps, access_token))
        },


    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CrudModalForUserCreateLavoriEseguiti));