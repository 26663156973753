import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import * as EmailAction from '../../store/actions/EmailAction'
import * as ModelAction from '../../store/actions/ModelAction'
import { connect } from 'react-redux';
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import * as DateTool from '../../util/DateTool';
import LinearProgress from '@material-ui/core/LinearProgress';

import AddIcon from '@material-ui/icons/Add';

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextareaAutosize from '@mui/base/TextareaAutosize';

import Searcher from '../common/Searcher';
import * as StrTool from '../../util/StrTool';

import { modelProps as modelPropsArtigiani } from '../../model/Artigiani';
import { modelProps as modelPropsClienti } from '../../model/Clienti';
import { modelProps as modelPropsDipendenti } from '../../model/Dipendenti';

import FormLabel from '@material-ui/core/FormLabel';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';


const styles = theme => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            // width: '25ch',
        },
    },
    table: {
        // minWidth: 650,
    },
    tableHead: {
        background: "#b9b9b9"
    },

    row: {
        '&:hover': {
            background: "#CACACA"
        }
    },
    cell: {
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    modal: {
        display: 'block',
        position: 'absolute',
        overflow: 'scroll',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 900,
    },
    modalpaperS: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 400,
    },
    buttonback: {
        backgroundColor: "#b9b9b9",
        border: '0px solid #000',
        boxShadow: theme.shadows[0],
        padding: theme.spacing(1.5, 1.5, 1.5),

    },
    formControlS: {
        margin: theme.spacing(1),
        width: 120,
    },
    formControlM: {
        margin: theme.spacing(1),
        width: 300
    },
    formControlL: {
        margin: theme.spacing(1),
        width: 800
    },
    grammar: {
        width: 800
    },
    grammarPreview: {
        width: 800
    },
    cell_short: {
        fontSize: "10px",
        width: 50,
    },
    cell_medium: {
        fontSize: "10px",
        width: 100,
    },
    cell_clickable: {
        cursor: "pointer"
    },
    cell_searched: {
        background: "#FA8072"
    },
    gridItem: {
    },
    button: {
        background: 'white',
        borderRadius: 3,
        border: 0,
        color: 'black',
        padding: '0 20px',
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    container: {
        maxHeight: 600,
    },

    buttonSend: { width: 270, height: 50 }
});

const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
    },
})




var defaultFormValue = {};

class SelectToModal extends Component {

    constructor(props) {
        super(props);

        var submitDisabled = false

        this.state = {
            openModal: false,
            ToType: null,
            current_ToCategory: null,
            To_name: null,
            To_email: null,
            To_editting: false,
            invalidEmail: false,

        };

    }

    componentDidMount() {

    }


    handlModalOpen = () => {
        this.setState({
            openModal: true,
            ToType: null,
            current_ToCategory: null,
            To_name: null,
            To_email: null,
            To_editting: false,
            invalidEmail: false,

        })
    };



    handleModalClose = () => {
        this.setState({ openModal: false })
    };

    setEmailAndName(model) {

        if (model == null) {
            this.setState({
                To_email: null,
                To_name: null
            })
        } else {
            this.setState({
                To_email:
                    model.EMail == null
                        ? 'non e stato registrato'
                        : model.EMail,
                To_name:
                    model.Cognome == null
                        ? model.Nome
                        : model.Nome + ' ' + model.Cognome
            })
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                {!this.state.To_editting &&
                    <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                            e.preventDefault();
                            this.handlModalOpen();
                            this.setState({ To_editting: true })
                        }} >
                        <PersonAddAlt1Icon /> {' Aggiungi indirizzo'}
                    </Button>}

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.state.openModal}
                    onClose={this.handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openModal}>
                        <div className={classes.modalpaperS}>

                            <form className={classes.root} noValidate autoComplete="on">
                                <Grid container justify="left" direction="row"  >
                                    <Grid item xs={12} >
                                        <Grid container justify="left" direction="column" key={'form_add_to'} >
                                            <div>
                                                <FormControl className={classes.formControlS}>
                                                    <FormLabel component="legend" > To * </FormLabel>
                                                    < RadioGroup aria-label='current_ToCategory' name='current_ToCategory' value={this.state.current_ToCategory} onChange={(e) => {
                                                        e.preventDefault()
                                                        this.setState({ current_ToCategory: e.target.value })
                                                        this.setState({ invalidEmail: false })
                                                        this.setEmailAndName(null)
                                                    }}>
                                                        <FormControlLabel key={'key_dipendenti'} value="Dipendenti" control={< Radio />} label="Dipendenti" />
                                                        <FormControlLabel key={'key_artigiani'} value="Artigiani" control={< Radio />} label="Artigiani" />
                                                        <FormControlLabel key={'key_clienti'} value="Clienti" control={< Radio />} label="Clienti" />
                                                        <FormControlLabel key={'key_altro'} value="Altro" control={< Radio />} label="Altro" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                            <div>
                                                {this.state.current_ToCategory == 'Dipendenti'
                                                    ? <Searcher
                                                        modelProps={modelPropsDipendenti}
                                                        onSelect={(dipendenti) => {
                                                            this.setEmailAndName(dipendenti)
                                                            this.setState({ invalidEmail: false })
                                                        }}
                                                        showpropArr={['Nome', 'Cognome']} />
                                                    : null}
                                                {this.state.current_ToCategory == 'Artigiani'
                                                    ? <Searcher
                                                        modelProps={modelPropsArtigiani}
                                                        onSelect={(artigiani) => {
                                                            this.setEmailAndName(artigiani)
                                                            this.setState({ invalidEmail: false })
                                                        }}
                                                        showpropArr={['Nome', 'Cognome']} />
                                                    : null}
                                                {this.state.current_ToCategory == 'Clienti'
                                                    ? <Searcher
                                                        modelProps={modelPropsClienti}
                                                        onSelect={(clienti) => {
                                                            this.setEmailAndName(clienti)
                                                            this.setState({ invalidEmail: false })
                                                        }}
                                                        showpropArr={['Nome', 'Cognome']} />
                                                    : null}
                                                {this.state.current_ToCategory == 'Altro'
                                                    ? <FormControl className={classes.formControlM}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label='to'
                                                            variant="outlined"
                                                            value={this.state.To_email}
                                                            onChange={(e) => {
                                                                e.preventDefault()
                                                                this.setState({
                                                                    To_email: e.target.value,
                                                                    To_name: ''
                                                                })

                                                                this.setState({ invalidEmail: !StrTool.validateEmail(e.target.value) })
                                                            }} />
                                                    </FormControl>
                                                    : null}
                                                {this.state.invalidEmail && <h5 style={{ color: 'red' }}>email non valido</h5>}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    < Grid item xs={12} >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" >
                                            <h4>{this.state.To_email}</h4>


                                        </Grid>
                                    </Grid>
                                    < Grid item xs={12} >
                                        <Grid
                                            container
                                            direction="row"
                                            key={'form_add_to_button'}
                                            justifyContent="flex-end"
                                            alignItems="flex-end">
                                            <Button

                                                disabled={this.state.To_email == 'non e stato registrato' ||
                                                    this.state.To_email == null ||
                                                    this.state.To_email == '' ||
                                                    this.state.invalidEmail == true}
                                                variant="contained"
                                                onClick={(e) => {
                                                    this.props.addToArr(this.state.To_email, this.state.To_name)
                                                    this.setState({ To_editting: false })
                                                    this.setState({ openModal: false })


                                                }}> Aggiungi </Button>
                                            < Button variant="contained"
                                                onClick={(e) => {
                                                    this.setState({ To_editting: false })
                                                    this.setState({ openModal: false })
                                                }}> Cancel </Button>
                                        </Grid>
                                    </Grid>


                                </Grid>

                            </form>
                        </div>
                    </Fade>
                </Modal >

            </div >

        );
    }
}


const mapStateToProps = (state) => {
    return {
        logininfo: state.auth.logininfo

    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        sendEmail: (from, to, subject, html, fileid, filename, access_token, cb) => {
            dispatch(EmailAction.sendEmail(from, to, subject, html, fileid, filename, access_token, cb))
        },
        submitCreate: (socket, getModelProps, page, numData, formValue, postModelProps, access_token) => {
            dispatch(ModelAction.apiPost(socket, getModelProps, page, numData, formValue, postModelProps, access_token))

        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SelectToModal));