export const modelProps = {

    "modelname": "Presenze",
    "props": {
        "id": {
            "type": "string"
        },
        "Data": {
            "type": "date",
            "inputRequired": true
        },
        "Presenza": {
            "type": "select",
            "inputRequired": true,
            "option": [
                {
                    "label": "presente",
                    "val": "p"
                },
                {
                    "label": "assente",
                    "val": "a"
                }
            ],
        },
        "Ore": {
            "type": "number"
        },
        "CostoOrario": {
            "type": "number"
        },
        "Descrizione": {
            "type": "string"
        },
        "Confermata": {
            "type": "Boolean"
        },
        "LastModifiedBy": {
            "type": "string"
        },
        "LastModifiedAt": {
            "type": "date"
        },
        "UserId": {
            "type": "number"
        },
        "DipendentiId": {
            "type": "number"
        },
        "CantieriId": {
            "type": "number"
        }
    },
    "arrInclude": ["Dipendenti", "Cantieri"],
    "order": ["Data DESC", "DipendentiId"],
    "DefaultNum": 100


}

export const checkInputRequired = (formValue) => {

    var unfilledRequiredFIeld = false

    if (formValue.Dipendenti == null) {
        unfilledRequiredFIeld = true
    }

    if (formValue.Data == null) {
        unfilledRequiredFIeld = true
    }

    if (formValue.Data == "") {
        unfilledRequiredFIeld = true
    }

    if (formValue.Presenza == null) {
        unfilledRequiredFIeld = true
    }


    return unfilledRequiredFIeld


}