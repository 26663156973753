import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import './component/css/styles.css'
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom'
import Login from './component/pages/Login'
import PageWrapper from './component/common/PageWrapper'


import Cantieri from './component/pages/user/Cantieri'
import Clienti from './component/pages/user/Clienti'
import DettagliModalita from './component/pages/user/DettagliModalita'
import Artigiani from './component/pages/user/Artigiani'
import Dipendenti from './component/pages/user/Dipendenti'
import LavoriEseguiti from './component/pages/user/LavoriEseguiti'
import Presenze from './component/pages/user/Presenze'
import Stipendi from './component/pages/user/Stipendi'
import Preventivi from './component/pages/user/Preventivi'
import SpeseDitta from './component/pages/user/SpeseDitta'
import AdminCantieri from './component/pages/admin/Cantieri'
import AdminClienti from './component/pages/admin/Clienti'
import AdminDettagliModalita from './component/pages/admin/DettagliModalita'
import AdminDipendenti from './component/pages/admin/Dipendenti'
import AdminLavoriEseguiti from './component/pages/admin/LavoriEseguiti'
import AdminPresenze from './component/pages/admin/Presenze'
import AdminStipendi from './component/pages/admin/Stipendi'
import AdminArtigiani from './component/pages/admin/Artigiani'
import AdminSpeseDitta from './component/pages/admin/SpeseDitta'
import Somma from './component/pages/user/Somma'







import FileManager from './component/pages/FileManager'
import PasswordGetVarification from './component/pages/PasswordGetVarification'
import LoginGetParam from './component/pages/LoginGetParam'
import ChangePassword from './component/pages/ChangePassword'


import Dashboard from './component/pages/admin/Dashboard'



import "bootstrap/dist/css/bootstrap.min.css"
import "./component/css/custom.css"


class App extends Component {


  render() {




    return (
      <div>
        {this.props.logininfo != null
          ? <Router>
            <Route exact
              path="/"
              render={() => {
                if(this.props.role == 'euroalbek'){
                  return <PageWrapper Title='Dashboard'><Dashboard /></PageWrapper>
                }else{
                  return <PageWrapper Title='Presenze'><Presenze /></PageWrapper>
                }
              }}
            />

            <Route exact
              path="/cantieri"
              render={() => {
                return <PageWrapper Title='Cantieri'><Cantieri /></PageWrapper>
              }}
            />
            <Route exact
              path="/clienti"
              render={() => {
                return <PageWrapper Title='Clienti'><Clienti /></PageWrapper>
              }}
            />


            <Route exact
              path="/dettaglimodalita"
              render={() => {
                return <PageWrapper Title='Dettagli Modalita'><DettagliModalita /></PageWrapper>
              }}
            />
            <Route exact
              path="/artigiani"
              render={() => {
                return <PageWrapper Title='Artigiani'><Artigiani /></PageWrapper>
              }}
            />
            <Route exact
              path="/dipendenti"
              render={() => {
                return <PageWrapper Title='Dipendenti'><Dipendenti /></PageWrapper>
              }}
            />
            <Route exact
              path="/giornalecantieri"
              render={() => {
                return <PageWrapper Title='Giornale di Cantieri'><LavoriEseguiti /></PageWrapper>
              }}
            />
            <Route exact
              path="/presenze"
              render={() => {
                return <PageWrapper Title='Presenze'><Presenze /></PageWrapper>
              }}
            />



            <Route exact
              path="/stipendi"
              render={() => {
                return <PageWrapper Title='Stipendi'><Stipendi /></PageWrapper>
              }}
            />

            <Route exact
              path="/preventivi"
              render={() => {
                return <PageWrapper Title='Preventivi'><Preventivi /></PageWrapper>
              }}
            />

            <Route exact
              path="/somma"
              render={() => {
                return <PageWrapper Title='Somma'><Somma /></PageWrapper>
              }}
            />

            <Route exact
              path="/admin/artigiani"
              render={() => {
                return <PageWrapper Title='AdminArtigiani'><AdminArtigiani /></PageWrapper>
              }}
            />
            <Route exact
              path="/speseditta"
              render={() => {
                return <PageWrapper Title='SpeseDitta'><SpeseDitta /></PageWrapper>
              }}
            />

            <Route exact
              path="/admin/artigiani"
              render={() => {
                return <PageWrapper Title='AdminArtigiani'><AdminArtigiani /></PageWrapper>
              }}
            />

            <Route exact
              path="/admin/cantieri"
              render={() => {
                return <PageWrapper Title='AdminCantieri'><AdminCantieri /></PageWrapper>
              }}
            />
            <Route exact
              path="/admin/clienti"
              render={() => {
                return <PageWrapper Title='AdminClienti'><AdminClienti /></PageWrapper>
              }}
            />


            <Route exact
              path="/admin/dettaglimodalita"
              render={() => {
                return <PageWrapper Title='AdminDettagliModalita'><AdminDettagliModalita /></PageWrapper>
              }}
            />
            <Route exact
              path="/admin/dipendenti"
              render={() => {
                return <PageWrapper Title='AdminDipendenti'><AdminDipendenti /></PageWrapper>
              }}
            />
            <Route exact
              path="/admin/lavorieseguiti"
              render={() => {
                return <PageWrapper Title='AdminLavoriEseguiti'><AdminLavoriEseguiti /></PageWrapper>
              }}
            />
            <Route exact
              path="/admin/presenze"
              render={() => {
                return <PageWrapper Title='AdminPresenze'><AdminPresenze /></PageWrapper>
              }}
            />
            <Route exact
              path="/admin/stipendi"
              render={() => {
                return <PageWrapper Title='AdminStipendi'><AdminStipendi /></PageWrapper>
              }}
            />

            <Route exact
              path="/admin/speseditta"
              render={() => {
                return <PageWrapper Title='AdminSpeseDitta'><AdminSpeseDitta /></PageWrapper>
              }}
            />




            <Route exact
              path="/filemanager"
              render={() => {
                return <PageWrapper Title='File Manager'><FileManager /></PageWrapper>
              }}
            />
            <Route exact
              path="/changepassword"
              render={() => {
                return <PageWrapper Title='Cambia password'><ChangePassword /></PageWrapper>
              }}
            />
          </Router>
          : <Router>
            <Route exact
              path="/"
              render={() => {
                return <LoginGetParam />
              }}
            />
          </Router>


        }
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    logininfo: state.auth.logininfo,
    role:state.auth.role
  }

}

const mapDispatchToProps = (dispatch) => {

  return {

  }

}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
