import zIndex from '@material-ui/core/styles/zIndex';
import axios from 'axios';
import * as LoopbackCall from '../../util/LoopbackCall'
import * as StrTool from '../../util/StrTool';


var FileSaver = require('file-saver');

export const apiGetALL = (getModelProps, access_token) => {

    var modelName = getModelProps.modelname

    return (dispatch) => {

        axios.get(LoopbackCall.url + modelName, {
            params: {
                filter: {
                    include: getModelProps.arrInclude,
                    order: getModelProps.order,
                },
                access_token: access_token
            }
        })
            .then((response) => {
                if (response.status == 200) {
                    dispatch({
                        type: "GET_ALL_" + modelName,
                        value: response.data
                    })
                }
            }).catch((error) => {
                console.log("GET_ALL_" + modelName + " ERR", error)

                dispatch({
                    type: "GET_ALL_" + modelName,
                    value: []
                })

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
            })
    }
}



export const apiGet = (getModelProps, page, numData, access_token) => {

    var modelName = getModelProps.modelname

    console.log('getModelProps', getModelProps)

    return (dispatch) => {

        axios.get(LoopbackCall.url + modelName, {
            params: {
                filter: {
                    limit: numData,
                    skip: (page - 1) * numData,
                    include: getModelProps.arrInclude,
                    order: getModelProps.order,
                    where: getModelProps.where

                },
                access_token: access_token
            }
        })
            .then((response) => {
                if (response.status == 200) {
                    dispatch({
                        type: "GET_ALL_" + modelName,
                        value: response.data
                    })
                }
            }).catch((error) => {
                console.log("GET_ALL_" + modelName + " ERR", error)

                dispatch({
                    type: "GET_ALL_" + modelName,
                    value: []
                })

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
            })
    }
}


export const apiGetCount = (getModelProps, access_token) => {

    var modelName = getModelProps.modelname

    return (dispatch) => {

        axios.get(LoopbackCall.url + modelName + `/count`,
            {
                params:
                {

                    where: getModelProps.where,
                    access_token: access_token
                }
            })
            .then((response) => {
                if (response.status == 200) {
                    dispatch({
                        type: "GET_COUNT_" + modelName,
                        value: response.data.count
                    })
                }
            }).catch((error) => {
                console.log("GET_COUNT_" + modelName + " ERR", error)

                dispatch({
                    type: "GET_COUNT_" + modelName,
                    value: 0
                })

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
            })
    }
}


export const apiPost = (socket, getModelProps, page, numData, formValue, postModelProps, access_token) => {

    console.log('getModelProps', getModelProps)
    console.log('postModelProps', postModelProps)

    return (dispatch) => {
        axios.post(LoopbackCall.url + postModelProps.modelname, formValue, { params: { access_token: access_token } }).then((response) => {

            if (response.status == 200) {
                /*
                socket.send(JSON.stringify({
                    type: 'createModel',
                    modelName: modelName,
                    currentPage: page,
                    currentNumData: numData
                }));
                */
            }

            var modelName = getModelProps.modelname

            //登録時にリロードせずに件数更新
            console.log("登録時にリロードせずに件数更新")
            axios.get(LoopbackCall.url + modelName + `/count`, { params: { access_token: access_token } })
                .then((response) => {
                    if (response.status == 200) {
                        dispatch({
                            type: "GET_COUNT_" + modelName,
                            value: response.data.count
                        })

                        axios.get(LoopbackCall.url + modelName, {
                            params: {
                                filter: {
                                    limit: numData,
                                    skip: (page - 1) * numData,
                                    include: getModelProps.arrInclude,
                                    order: getModelProps.order,
                                },
                                access_token: access_token
                            }
                        }).then((response) => {
                            if (response.status == 200) {
                                dispatch({
                                    type: "GET_ALL_" + modelName,
                                    value: response.data
                                })
                            }
                        }).catch((error) => {
                            console.log("GET_ALL_" + modelName + " ERR", error)

                            dispatch({
                                type: "GET_ALL_" + modelName,
                                value: []
                            })
                        })
                    }
                }).catch((error) => {
                    console.log("GET_COUNT_" + modelName + " ERR", error)

                    dispatch({
                        type: "GET_COUNT_" + modelName,
                        value: 0
                    })
                })


        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            console.log("登録失敗", error)
        })
    }
}

export const apiPut = (socket, getModelProps, page, numData, formValue, putModelProps, access_token) => {

    return (dispatch) => {
        axios.put(LoopbackCall.url + putModelProps.modelname + `?access_token=` + access_token, formValue).then((response) => {
            if (response.status == 200) {

                var modelName = getModelProps.modelname

                //更新時にリロードせずに内容更新
                console.log("登録時にリロードせずに件数更新")
                axios.get(LoopbackCall.url + modelName + `/count`, { params: { access_token: access_token } })
                    .then((response) => {
                        if (response.status == 200) {
                            dispatch({
                                type: "GET_COUNT_" + modelName,
                                value: response.data.count
                            })

                            axios.get(LoopbackCall.url + modelName, {
                                params: {
                                    filter: {
                                        limit: numData,
                                        skip: (page - 1) * numData,
                                        include: getModelProps.arrInclude,
                                        order: getModelProps.order,
                                    },
                                    access_token: access_token
                                }
                            }).then((response) => {
                                if (response.status == 200) {
                                    dispatch({
                                        type: "GET_ALL_" + modelName,
                                        value: response.data
                                    })
                                }
                            }).catch((error) => {
                                console.log("GET_ALL_" + modelName + " ERR", error)

                                dispatch({
                                    type: "GET_ALL_" + modelName,
                                    value: []
                                })
                            })
                        }
                    }).catch((error) => {
                        console.log("GET_COUNT_" + modelName + " ERR", error)

                        dispatch({
                            type: "GET_COUNT_" + modelName,
                            value: 0
                        })
                    })

            }
        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            console.log("更新失敗", error)
        })
    }
}




export const apiDelete = (socket, getModelProps, page, numData, id, deleteModelProps, access_token) => {
    return (dispatch) => {

        axios.delete(LoopbackCall.url + deleteModelProps.modelname + `/` + id + `?access_token=` + access_token
        ).then((response) => {
            if (response.status == 200) {
                console.log("削除完了");
            }
            /*
            socket.send(JSON.stringify({
                type: 'deleteModel',
                modelName: modelName,
                currentPage: page,
                currentNumData: numData
            }));
            */

            var modelName = getModelProps.modelname

            //登録時にリロードせずに件数更新
            console.log("登録時にリロードせずに件数更新")
            axios.get(LoopbackCall.url + modelName + `/count`, { params: { access_token: access_token } })
                .then((response) => {
                    if (response.status == 200) {
                        dispatch({
                            type: "GET_COUNT_" + modelName,
                            value: response.data.count
                        })

                        axios.get(LoopbackCall.url + modelName, {
                            params: {
                                filter: {
                                    limit: numData,
                                    skip: (page - 1) * numData,
                                    include: getModelProps.arrInclude,
                                    order: getModelProps.order,
                                },
                                access_token: access_token
                            }
                        }).then((response) => {
                            if (response.status == 200) {
                                dispatch({
                                    type: "GET_ALL_" + modelName,
                                    value: response.data
                                })
                            }
                        }).catch((error) => {
                            console.log("GET_ALL_" + modelName + " ERR", error)

                            dispatch({
                                type: "GET_ALL_" + modelName,
                                value: []
                            })
                        })
                    }
                }).catch((error) => {
                    console.log("GET_COUNT_" + modelName + " ERR", error)

                    dispatch({
                        type: "GET_COUNT_" + modelName,
                        value: 0
                    })
                })


        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            console.log("削除失敗", error)
        })
    }
}





export const apiExportJson = (modelName, access_token) => {
    return (dispatch) => {
        //axios.post(LoopbackCall.url + modelName + `/export_json`,

        console.log(`Util` + `/export_json`);
        axios.post(LoopbackCall.url + `Util` + `/export_json`,
            null,
            {
                params: {
                    access_token: access_token,
                    model: modelName
                }
            }
        ).then((response) => {
            if (response.status == 200) {
                FileSaver.saveAs(
                    new Blob(
                        [JSON.stringify(response.data)],
                        { type: "text/plane; charset=utf-8" }),
                    modelName + StrTool.createStrYYYYMMDDHHMMSS(new Date()) + ".json"
                );
            }
        }).catch((error) => {

            if (error.response.status === 401) {
                console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                dispatch({
                    type: "ACCESSTOKEN_EXPIRED"
                })
            }
            console.log("apiExportJsonエラー", error)
        })
    }
}


export const apiCleanImportJson = (socket, modelName, file, access_token) => {
    return (dispatch) => {
        var file_reader = new FileReader();

        file_reader.onload = function (e) {
            var arr = JSON.parse(file_reader.result);
            var arrBody = [];

            arr.forEach((o) => {
                arrBody.push(o);
            })


            axios.post(LoopbackCall.url + `Util` + `/clean_import_json`,
                { arr: arr },
                {
                    params: {
                        access_token: access_token,
                        model: modelName
                    }
                }).then((response) => {
                    if (response.status == 200) {
                        socket.send(JSON.stringify({
                            type: 'updateModel',
                            modelName: modelName,
                            currentPage: 1,
                            currentNumData: 200
                        }));
                    }
                }).catch((err) => {

                    if (err.response.status === 401) {
                        console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                        dispatch({
                            type: "ACCESSTOKEN_EXPIRED"
                        })
                    }
                    console.log("err!!")

                })

        };

        file_reader.readAsText(file);
    }
}



export const apiImportJson = (socket, modelName, file, access_token) => {

    return (dispatch) => {

        console.log("socket,modelName, file=>", socket, modelName, file, access_token)

        var file_reader = new FileReader();

        file_reader.onload = function (e) {
            console.log(file_reader.result);

            var arr = JSON.parse(file_reader.result);

            console.log(arr.length);

            var index = 0;

            loopAxiosPutModel(socket, modelName, arr, index, access_token, dispatch);

        };


        file_reader.readAsText(file);


    }

}


const loopAxiosPutModel = (socket, modelName, arr, index, access_token, dispatch) => {

    console.log('time=>', new Date());

    console.log('Index=>', index);
    console.log('arr.length=>', arr.length)

    if (index == arr.length) {
        return;
    } else {

        var value = arr[index];

        if (value.id == null) {
            //idが設定されていない場合は新規登録
            axios.post(
                LoopbackCall.url + modelName, value,
                { params: { access_token: access_token } }
            ).then((response) => {
                if (response.status == 200) {
                    socket.send(JSON.stringify({
                        type: 'updateModel',
                        modelName: modelName
                    }));
                }

                index++;
                loopAxiosPutModel(socket, modelName, arr, index, access_token);

            }).catch((error) => {

                if (error.response.status === 401) {
                    console.log("ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED ACCESSTOKEN_EXPIRED")
                    dispatch({
                        type: "ACCESSTOKEN_EXPIRED"
                    })
                }
                console.log('error=>', error);
            })
        } else {
            //idが設定されている場合は更新またはそのidで登録
        }


        //delete value.id;


    }

    console.log('time=>', new Date());



}


export const apiExportExcel = (modelName, access_token) => {


    //https://github.com/exceljs/exceljs#fills
    return () => {


        axios.get(LoopbackCall.url + modelName, {
            params: { access_token: access_token }
        })
            .then((response) => {

                console.log("apiExportExcel response.status", response.status)
                if (response.status == 200) {

                    const excel = require("exceljs");
                    let workbook = new excel.Workbook();
                    let worksheet = workbook.addWorksheet(modelName);

                    var columns = [];
                    var col_num = 0;
                    if (response.data.length > 0) {
                        Object.keys(response.data[0]).forEach((key) => {
                            columns.push({ header: key, key: key, width: 12 });
                            col_num++;
                        })
                    }
                    worksheet.columns = columns;

                    var row_num = 1;
                    response.data.forEach((d) => {
                        worksheet.addRow(d);
                        row_num++;
                    })

                    var row_index = 1;
                    while (row_index <= row_num) {
                        var col_index = 1;
                        while (col_index <= col_num) {
                            worksheet.getRow(row_index).getCell(col_index).border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' }
                            };
                            col_index++;
                        }
                        row_index++;
                    }



                    workbook.xlsx.writeBuffer().then(function (data) {

                        FileSaver.saveAs(
                            new Blob(
                                [data],
                                { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                            modelName + StrTool.createStrYYYYMMDDHHMMSS(new Date()) + ".xlsx"
                        );

                    });


                }

            }).catch((error) => {
                console.log("GET_ALL_" + modelName + " ERR", error)
            })

    }

}

export const apiGetById = (modelName, id, access_token) => {

    return (dispatch) => {

        axios.get(LoopbackCall.url + modelName + `/` + id, {
            params: {
                access_token: access_token
            }
        })
            .then((response) => {
                if (response.status == 200) {
                    console.log('type', "GET_BY_ID_" + modelName, response.data)
                    dispatch({
                        type: "GET_BY_ID_" + modelName,
                        value: response.data
                    })
                }
            }).catch((error) => {
                console.log("GET_BY_ID_" + modelName + " ERR", error)
            })
    }
}
