export const modelProps = {
    
    "modelname": "LavoriEseguiti",
    "props": {
        "id": {
            "type": "string"
        },

        "Data": {
            "type": "date"
        },
        "LavoriEseguiti": {
            "type": "string"
        },
        "LastModifiedBy": {
            "type": "string"
        },
        "LastModifiedAt": {
            "type": "date"
        },

        "CantieriId": {
            "type": "number"
        }
    },
    "arrInclude": ["Cantieri","EmailLavoriEseguiti"],
    "order": ["Data DESC"],





}

export const checkInputRequired = (formValue) => {

    var unfilledRequiredFIeld = false
  
    if (formValue.Cantieri == null) {
      unfilledRequiredFIeld = true
    }
  
    if (formValue.Data == null) {
      unfilledRequiredFIeld = true
    }

    if (formValue.LavoriEseguiti == null) {
        unfilledRequiredFIeld = true
      }
  
    return unfilledRequiredFIeld
  
  
  }
  