import React, { Component } from 'react';

import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import * as ModelAction from '../../../../../store/actions/ModelAction'
import * as PresenzeAction from '../../../../../store/actions/PresenzeAction'
import { connect } from 'react-redux';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { createMuiTheme } from '@material-ui/core'
import * as DateTool from '../../../../../util/DateTool';
import * as StrTool from '../../../../../util/StrTool';
import Pagination from '@material-ui/lab/Pagination';
import CrudModalForUserCreatePresenze from './CrudModalForUserCreatePresenze'
import CrudModalForUserUpdatePresenze from './CrudModalForUserUpdatePresenze'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Searcher from '../../../../common/Searcher'
import FormLabel from '@material-ui/core/FormLabel';

import { modelProps as modelPropsDipendenti } from '../../../../../model/Dipendenti';



import { styles } from '../../../../css/commonstyles'
import { SignalCellularNullRounded } from '@material-ui/icons';

class CrudForUserPresenze extends Component {

    constructor(props) {
        super(props);

        var firstdayofmonth = DateTool.returnItalianTimeFirstDayOfMonth() // 2023-09-01T00:00:00.000Z
        console.log('firstdayofmonth★★★★★', firstdayofmonth);

        var thisMonth = DateTool.convertMonth(parseInt(firstdayofmonth.substring(5, 7), 10))



        this.state = null;
        this.state = {
            thisMonth: thisMonth,
            page: 1,
            numData: this.props.modelProps.DefaultNum ? this.props.modelProps.DefaultNum : 200,
            searchedNum: 0,
            searchText: '',
            firstdayofmonth: firstdayofmonth,
            UserId: null

        };



    }

    componentDidMount() {

        var userId = this.setTargetUserId()

        this.props.getModelCount(userId, this.state.firstdayofmonth, this.props.logininfo.id)
        this.props.getModelList(userId, this.state.firstdayofmonth, this.state.page, this.state.numData, this.props.logininfo.id)

    }

    setTargetUserId = () => {

        var userId = null;
        if (this.props.role == 'euroalbek') {
            if (this.state.UserId == null) {
                //ダミー
                userId = -1;
            } else {
                userId = this.state.UserId
            }
        } else if (this.props.role == 'member') {
            userId = this.props.logininfo.userId;
        }
        return userId
    }



    onChangeInputNumData = (e) => {
        e.preventDefault();

        var num = e.target.value;

        var total = this.props.modelList["Presenze_count"]

        if (num >= total) {
            num = total;
        } else {
            //全件１ページ表示字のみ検索可
            this.setState({ searchText: '' })

        }

        this.setState({ numData: num });

        var userId = this.setTargetUserId()

        if (num == null || num == "") {
            this.props.getModelList(userId, this.state.firstdayofmonth, this.state.page, total, this.props.logininfo.id)
        } else {
            this.props.getModelList(userId, this.state.firstdayofmonth, this.state.page, num, this.props.logininfo.id)

        }

    }

    onChangePagination = (page) => {

        var userId = this.setTargetUserId()
        this.setState({ page: page });
        this.props.getModelList(userId, this.state.firstdayofmonth, page, this.state.numData, this.props.logininfo.id)

    }





    render() {

        var keys = Object.keys(this.props.modelProps);

        const { classes } = this.props;

        const nums = [];
        for (let i = 1; i <= 50; i++) {
            nums.push(i);
        }

        return (
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >


                {this.props.role == 'euroalbek' &&
                    <Grid item xs={2}  >
                        <Searcher
                            modelProps={modelPropsDipendenti}
                            onSelect={(dipendenti) => {
                                var userId = dipendenti ? dipendenti['UserId'] : -1
                                this.setState({ 'UserId': userId })
                                this.setState({ 'Dipendenti': dipendenti })
                                this.props.getModelCount(userId, this.state.firstdayofmonth, this.props.logininfo.id)
                                this.props.getModelList(userId, this.state.firstdayofmonth, 1, this.state.numData, this.props.logininfo.id)



                            }}
                            showpropArr={['Nome', 'Cognome']} />
                    </Grid>
                }

                <Grid item xs={this.props.role == 'euroalbek' ? 10 : 12}  >
                    <Grid
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                    >

                        <Grid item xs={12}  >
                            <Grid container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-end"
                                spacing={1}>
                                <Typography variant={this.props.role == 'euroalbek' ? "h4" : "h6"} component="h4">
                                    <Box fontSize="h15.fontsize" m={1} fontStyle="oblique"
                                        className={classNames(classes.cell_clickable)}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            var firstdayoflastmonth = DateTool.returnFirstDayOfLastMonth(this.state.firstdayofmonth)
                                            this.setState({ firstdayofmonth: firstdayoflastmonth })
                                            var thisMonth = DateTool.convertMonth(parseInt(firstdayoflastmonth.substring(5, 7), 10))
                                            this.setState({ thisMonth: thisMonth })
                                            var userId = this.setTargetUserId()
                                            this.props.getModelCount(userId, firstdayoflastmonth, this.props.logininfo.id)
                                            this.props.getModelList(userId, firstdayoflastmonth, 1, this.state.numData, this.props.logininfo.id)
                                        }}>
                                        {'<'}
                                    </Box>
                                </Typography>
                                <Typography variant={this.props.role == 'euroalbek' ? "h4" : "h6"} component="h4">
                                    <Box fontSize="h15.fontsize" m={1} fontStyle="oblique">{this.state.thisMonth}</Box>
                                </Typography>

                                <Typography variant="h6" component="h6">
                                    <Box fontSize="h15.fontsize" m={1} fontStyle="oblique">{this.state.firstdayofmonth.substring(0, 4)}</Box>
                                </Typography>
                                <Typography variant={this.props.role == 'euroalbek' ? "h4" : "h6"} component="h4">
                                    <Box fontSize="h15.fontsize" m={1} fontStyle="oblique"
                                        className={classNames(classes.cell_clickable)}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            var firstdayofNextmonth = DateTool.returnFirstDayOfNextMonth(this.state.firstdayofmonth)
                                            this.setState({ firstdayofmonth: firstdayofNextmonth })
                                            var thisMonth = DateTool.convertMonth(parseInt(firstdayofNextmonth.substring(5, 7), 10))
                                            this.setState({ thisMonth: thisMonth })
                                            var userId = this.setTargetUserId()
                                            this.props.getModelCount(userId, firstdayofNextmonth, this.props.logininfo.id)
                                            this.props.getModelList(userId, firstdayofNextmonth, 1, this.state.numData, this.props.logininfo.id)

                                        }}>
                                        {'>'}
                                    </Box>
                                </Typography>
                                {this.props.role == 'euroalbek' &&
                                    <Typography variant="h6" component="h6">
                                        <Box fontSize="h15.fontsize" m={1} >{this.state.Dipendenti && <div>Totale: {this.props.OreTotale_From_Presenza_Monthly} ore ({this.props.Stipendi_From_Presenza_Monthly} euro)</div>}</Box>
                                    </Typography>
                                }
                                {this.props.role == 'member' &&
                                    <Typography variant="h6" component="h6">
                                        <Box fontSize="h15.fontsize" m={1} >{<div>Totale: {this.props.OreTotale_From_Presenza_Monthly} ore * confermata </div>}</Box>
                                    </Typography>
                                }



                            </Grid>
                        </Grid>

                        <Grid item xs={12}  >
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                            >

                                <CrudModalForUserCreatePresenze
                                    modelProps={this.props.modelProps}
                                    modelName={this.props.modelName}
                                    page={this.state.page}
                                    numData={this.state.numData}
                                    firstdayofmonth={this.state.firstdayofmonth}
                                />


                                <Pagination
                                    onChange={(e, page) => { e.preventDefault(); this.onChangePagination(page) }}
                                    page={this.state.page}
                                    count={this.props.modelList
                                        ? Math.ceil(this.props.modelList["Presenze_count"] / this.state.numData)
                                        : 3}
                                />
                                <TextField
                                    id="standard-basic"
                                    label="number of datas"
                                    value={this.state.numData}
                                    onChange={(e) => { this.onChangeInputNumData(e) }}
                                />
                                <Typography variant="h6" >total:{this.props.modelList["Presenze_count"]}</Typography>



                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <div >
                                <TableContainer className={classes.container} >
                                    <Table stickyHeader aria-label="a dense table"  >
                                        <TableHead className={classes.tableHead} >
                                            <TableRow >

                                                <TableCell key={'hd_data'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Data</TableCell>
                                                {this.props.role == 'euroalbek' &&
                                                    <TableCell key={'hd_dipendenti'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Dipendenti</TableCell>
                                                }
                                                <TableCell key={'hd_cantieri'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Cantieri</TableCell>
                                                <TableCell key={'hd_presenza'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Presenza</TableCell>
                                                <TableCell key={'hd_ore'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Ore</TableCell>
                                                {this.props.role == 'euroalbek' &&
                                                    <TableCell key={'hd_costoorario'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Costo Orario</TableCell>
                                                }
                                                <TableCell key={'hd_descrizione'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Descrizione</TableCell>
                                                <TableCell key={'hd_confermata'} align="left" style={{ backgroundColor: '#BDBDBD' }} >Confermata</TableCell>
                                                <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }} ></TableCell>
                                                <TableCell align="left" style={{ backgroundColor: '#BDBDBD' }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {this.props.modelList['Presenze'].map((m) => {

                                                var showData = false;

                                                if (this.state.searchText.trim() == '') {
                                                    showData = true;
                                                } else {

                                                    if (m['Dipendenti']['Nome'].toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }

                                                    if (m['Dipendenti']['Cognome'] != null &&
                                                        m['Dipendenti']['Cognome'].toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }

                                                    if (m['Dipendenti']['Cognome'] != null &&
                                                        (m['Dipendenti']['Nome'] + ' ' + m['Dipendenti']['Cognome']).toLowerCase().indexOf(this.state.searchText.trim().toLowerCase()) != -1) {
                                                        showData = true;
                                                    }


                                                }


                                                if (showData) {

                                                    return (
                                                        <TableRow key={m.id} className={classes.row}>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {StrTool.createStrDD_MM_YYYY(m['Data'])}
                                                            </TableCell>
                                                            {this.props.role == 'euroalbek' &&
                                                                <TableCell
                                                                    align="left"
                                                                    className={
                                                                        this.state.searchText == '' || m['Dipendenti'] == null
                                                                            ? classNames(classes.cell)
                                                                            : String(m['Dipendenti']['Cognome']).toLowerCase().indexOf(this.state.searchText.toLowerCase()) == -1
                                                                                ? String(m['Dipendenti']['Nome']).toLowerCase().indexOf(this.state.searchText.toLowerCase()) == -1
                                                                                    ? classNames(classes.cell)
                                                                                    : classNames(classes.cell, classes.cell_searched)
                                                                                : classNames(classes.cell, classes.cell_searched)
                                                                    }
                                                                >
                                                                    {m['Dipendenti']['Nome'] + ' ' + m['Dipendenti']['Cognome']}
                                                                </TableCell>
                                                            }
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Cantieri'] && m['Cantieri']['Descrizione']}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Presenza'] == 'p'
                                                                    ? 'presente'
                                                                    : m['Presenza'] == 'a'
                                                                        ? 'assente'
                                                                        : null
                                                                }
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Ore']}
                                                            </TableCell>
                                                            {this.props.role == 'euroalbek' &&
                                                                <TableCell
                                                                    align="left"
                                                                    className={classNames(classes.cell)}
                                                                >
                                                                    {m['CostoOrario']}
                                                                </TableCell>
                                                            }
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Descrizione']}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell)}
                                                            >
                                                                {m['Confermata'] == true
                                                                    ? <Typography variant="h6" style={{ color: 'blue' }}>Si</Typography>
                                                                    : <Typography variant="h6" style={{ color: 'red' }}>No</Typography>}
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className={classNames(classes.cell, classes.cell_short,)}
                                                            >
                                                                <CrudModalForUserUpdatePresenze
                                                                    modelProps={this.props.modelProps}
                                                                    modelName={this.props.modelName}
                                                                    modelValue={m}
                                                                    page={this.state.page}
                                                                    numData={this.state.numData}
                                                                    firstdayofmonth={this.state.firstdayofmonth} />
                                                            </TableCell>
                                                            <TableCell
                                                                align="right"
                                                                className={classNames(classes.cell, classes.cell_short)}
                                                                onClick={(e) => {
                                                                    e.preventDefault();


                                                                    var name = this.props.role == 'euroalbek'
                                                                        ? m['Dipendenti']['Nome'] + ' ' + m['Dipendenti']['Cognome']
                                                                        : ''
                                                                    if (window.confirm("Vuoi eliminare il dato ? : "
                                                                        + StrTool.createStrDD_MM_YYYY(m['Data'])
                                                                        + name)) {

                                                                        this.props.submitDelete(
                                                                            this.props.role == 'euroalbek' ? this.state.UserId : this.props.logininfo.userId,
                                                                            this.state.firstdayofmonth,
                                                                            this.state.numData,
                                                                            m['id'],
                                                                            this.props.logininfo.id)

                                                                    }
                                                                }}>
                                                                <DeleteForeverIcon className={classNames(classes.cell_clickable)} />
                                                            </TableCell>
                                                        </TableRow>

                                                    )



                                                } else {

                                                    return null;
                                                }


                                            })}


                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Grid >

                    </Grid>
                </Grid>
            </Grid>





        );
    }
}


const mapStateToProps = (state) => {
    return {
        modelList: state.model,
        socket: state.webSocket.socket,
        logininfo: state.auth.logininfo,
        role: state.auth.role,
        Stipendi_From_Presenza_Monthly: state.stipendi.Stipendi_From_Presenza_Monthly,
        OreTotale_From_Presenza_Monthly: state.stipendi.OreTotale_From_Presenza_Monthly,

    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        getModelList: (userId, firstdayofmonth, page, numData, access_token) => {
            dispatch(PresenzeAction.apiGet_presenza_by_month(userId, firstdayofmonth, page, numData, access_token))
        },
        getModelCount: (userId, firstdayofmonth, access_token) => {
            dispatch(PresenzeAction.apiGetCount(userId, firstdayofmonth, access_token))
        },
        submitDelete: (userId, firstdayofmonth, numData, presenzaId, access_token) => {
            dispatch(PresenzeAction.apiDelete(userId, firstdayofmonth, numData, presenzaId, access_token))
        },

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CrudForUserPresenze));