export const modelProps = {
    "modelname":"Clienti",
    "props" :{
        "id": {
            "type": "string"
        },
        "RagioneSociale": {
            "label":"Ragione Sociale",
            "type": "string"
        },
        "Cognome": {
            "type": "string"
        },
        "Nome": {
            "type": "string",
            "inputRequired" : true 
        },
        "SedeLegaleIndirizzo": {
            "label":"Indirizzo(Sede Legale)",
            "type": "string"
        },
        "SedeLegaleProv": {
            "label":"Provincia(Sede Legale)",
            "type": "string"
        },
        "SedeLegaleLocalita": {
            "label":"Localita(Sede Legale)",
            "type": "string"
        },
        "SedeLegaleCAP": {
            "label":"CAP(Sede Legale)",
            "type": "string"
        },
        "SedeAmministrativaIndirizzo": {
            "type": "string"
        },
        "SedeAmministrativaProv": {
            "type": "string"
        },
        "SedeAmministrativaLocalita": {
            "type": "string"
        },
        "SedeAmministrativaCAP": {
            "type": "string"
        },
        "CodFisc": {
            "type": "string"
        },
        "PIva": {
            "type": "string"
        },
        "Telefono1": {
            "type": "string"
        },
        "Telefono2": {
            "type": "string"
        },
        "Cel1": {
            "type": "string"
        },
        "Cel2": {
            "type": "string"
        },
        "FAX": {
            "type": "string"
        },
        "EMail": {
            "type": "string"
        },
        "Sito": {
            "type": "string"
        },
        "Banca": {
            "type": "string"
        },
        "Agenzia": {
            "type": "string"
        },
        "Iban": {
            "type": "string"
        },
        "LastModifiedBy": {
            "type": "string"
        },
        "LastModifiedAt": {
            "type": "date"
        }
    }
    
}