import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import * as PresenzeAction from '../../../../../store/actions/PresenzeAction'
import * as StipendiAction from '../../../../../store/actions/StipendiAction'
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import * as DateTool from '../../../../../util/DateTool';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Searcher from '../../../../common/Searcher'
import { checkInputRequired } from '../../../../../model/Stipendi';
import { calcSaldo } from '../../../../../model/Stipendi';
import { modelProps } from '../../../../../model/Dipendenti';
import * as NumberTool from '../../../../../util/NumberTool';
import * as StrTool from '../../../../../util/StrTool';
import RowAccontoForForm from '../../../../common/RowAccontoForForm';
import AutoModeIconcal from '@mui/icons-material/AutoMode';

import CircularProgress from '@material-ui/core/CircularProgress';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import itLocale from "date-fns/locale/it";
import DateFnsUtils from '@date-io/date-fns';


const styles = theme => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    table: {
        // minWidth: 650,
    },
    tableHead: {
        background: "#b9b9b9"
    },

    row: {
        '&:hover': {
            background: "#CACACA"
        }
    },
    cell: {
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    modal: {
        display: 'block',
        position: 'absolute',
        overflow: 'scroll',
    },
    modalpaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 900,
    },
    buttonback: {
        backgroundColor: "#b9b9b9",
        border: '0px solid #000',
        boxShadow: theme.shadows[0],
        padding: theme.spacing(1.5, 1.5, 1.5),

    },
    formControlXS: {
        //margin: theme.spacing(1),
        width: 150,
    },
    formControlS: {
        //margin: theme.spacing(1),
        width: 180,
    },
    formControlM: {
        margin: theme.spacing(1),
        width: 370
    },
    formControlL: {
        margin: theme.spacing(1),
        width: 800
    },
    grammar: {
        width: 800
    },
    grammarPreview: {
        width: 800
    },
    cell_short: {
        fontSize: "10px",
        width: 50,
    },
    cell_medium: {
        fontSize: "10px",
        width: 100,
    },
    cell_clickable: {
        cursor: "pointer"
    },
    cell_searched: {
        background: "#FA8072"
    },
    gridItem: {
    },
    button: {
        background: 'white',
        borderRadius: 3,
        border: 0,
        color: 'black',
        padding: '0 20px',
        '&:hover': {
            background: "#b9b9b9"
        },
    },
    container: {
        maxHeight: 600,
    },
    div100: {
        height: '100%',
        width: '100%',

    }
});

const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#bfbfbf',
            light: '#969696',
            dark: '#616060',
        },
        secondary: {
            main: '#616060',
        },
        text: {
            disabled: 'black',
        },
    },
})




var defaultFormValue = {};

class SimpleCrudModalUpdate extends Component {

    constructor(props) {
        super(props);

        var submitDisabled = false

        Object.keys(this.props.modelProps).forEach((k) => {

            if (k != "id") {
                switch (this.props.modelProps[k].type) {
                    case "number":
                        defaultFormValue[k] = 0;
                        return;
                    case "string":
                        if (this.props.modelProps[k].defaultValue) {
                            defaultFormValue[k] = this.props.modelProps[k].defaultValue;
                        } else {
                            defaultFormValue[k] = "";
                        }
                        return;
                    case "date":
                        defaultFormValue[k] = DateTool.createJSONDateWithoutTime(new Date());
                        return;
                    case "time":
                        defaultFormValue[k] = DateTool.createJSONDateWithoutTime(new Date());
                        return;
                    case "select":
                        defaultFormValue[k] = "";
                        return;

                }

                //入力必須項目がある場合は Update　ボタンを非活性にする
                //if (this.props.modelProps[k].inputRequired == true ){
                //    submitDisabled = true
                //}
            }


        })

        this.state = null;
        this.state = {
            formValue: defaultFormValue,
            unfilledRequiredFIeld: false,
            openUpdateModal: false,



        };

    }

    componentDidMount() {

    }



    handlUpdateModalOpen = (model) => {
        console.log("handlUpdateModalOpen", model)

        this.setState({
            openUpdateModal: true,
            formValue: model,
            unfilledRequiredFIeld: false

        })
    };

    handleModalClose = () => {
        this.setState({ openUpdateModal: false })
    };



    onChangeInputCommon = (key, value) => {


        var newFormValue = this.state.formValue;
        newFormValue[key] = value;
        this.setState({ formValue: newFormValue });



        this.checkInputRequired()

    }


    onChangeInputNumCommon = (key, value) => {
        this.setState({ formValue: NumberTool.validateAndSetNewFormValue(key, value, this.state.formValue) });
    }


    handleDateTimeChange = (datetime, key) => {

        var newFormValue = this.state.formValue;

        if (datetime == null || datetime == "") {
            newFormValue[key] = null;
        } else {
            newFormValue[key] = DateTool.createJSONDateWithoutTime(datetime);
        }
        this.setState({ formValue: newFormValue });

    }

    checkInputRequired = () => {
        this.setState({ unfilledRequiredFIeld: checkInputRequired(this.state.formValue) });
    }


    checkDipendentiDalAl = () => {
        var disabled = false;

        if (this.state.formValue['Dipendenti'] == null) {
            disabled = true
        }
        if (this.state.formValue['Dal'] == null) {
            disabled = true
        }
        if (this.state.formValue['Dal'] == '') {
            disabled = true
        }
        if (this.state.formValue['Al'] == null) {
            disabled = true
        }
        if (this.state.formValue['Al'] == '') {
            disabled = true
        }

        return disabled
    }


    createMsgAcconto = (acconto) => {
        return 'Conferma un anticipo di ' + acconto + ' euro?'
    }

    //承認済みのAccontoを計算する
    calcSaldo = (Stipendio, Acconto1, Acconto2, Acconto3, Acconto4, Acconto5,
        AccontoConfermato1, AccontoConfermato2, AccontoConfermato3, AccontoConfermato4, AccontoConfermato5) => {
        
        
        this.onChangeInputCommon('Saldo', calcSaldo(Stipendio, Acconto1, Acconto2, Acconto3, Acconto4, Acconto5,
            AccontoConfermato1, AccontoConfermato2, AccontoConfermato3, AccontoConfermato4, AccontoConfermato5)) //数値チェックは不要
    }


    render() {

        var keys = Object.keys(this.props.modelProps);

        const { classes } = this.props;

        return (
            <div className={classes.cell_clickable}>
                <div onClick={(e) => { e.preventDefault(); this.handlUpdateModalOpen(this.props.modelValue) }}>
                    <EditIcon />
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.state.openUpdateModal}
                    onClose={this.handleModalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openUpdateModal}>
                        <div className={classes.modalpaper}>

                            <Grid container >
                                <Grid item xs={12}  >
                                    <h4 id="transition-modal-title">{this.props.modelName}</h4>
                                </Grid>


                                <Grid item xs={12}  >
                                    <form className={classes.root} noValidate autoComplete="on">
                                        <Grid container justify="left"  >
                                            <Grid item xs={12} key={'form_Dipendenti'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <FormLabel component="legend">Dipendenti*</FormLabel>
                                                    <h4> {this.state.formValue.Dipendenti && this.state.formValue.Dipendenti.Nome + ' ' + this.state.formValue.Dipendenti.Cognome}</h4>

                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={12} key={'form_Dal'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <MuiThemeProvider theme={customTheme}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                id="date-picker-dialog"
                                                                label='Dal'
                                                                format="dd/MM/yyyy"
                                                                value={this.state.formValue['Dal']}
                                                                onChange={(e) => { this.handleDateTimeChange(e, 'Dal') }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </MuiThemeProvider>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} key={'form_Al'}  >
                                                <FormControl className={classes.formControlM}>
                                                    <MuiThemeProvider theme={customTheme}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                id="date-picker-dialog"
                                                                label='Al'
                                                                format="dd/MM/yyyy"
                                                                value={this.state.formValue['Al']}
                                                                onChange={(e) => { this.handleDateTimeChange(e, 'Al') }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </MuiThemeProvider>
                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={12} >
                                                <Button
                                                    variant="contained"
                                                    disabled={this.checkDipendentiDalAl()}
                                                    className={classes.formControlM}
                                                    onClick={(e) => {

                                                        this.setState({ apiCall: 'start' })
                                                        this.setState({ apiCallerror: null })

                                                        this.props.getStipendiFromPresenza(this.state.formValue['UserId'],
                                                            this.state.formValue['Dal'],
                                                            this.state.formValue['Al'],
                                                            this.props.logininfo.id,
                                                            (bool, existEmptydata, totalhLav, costoOrarioMsg, Stipendio) => {

                                                                if (bool) {

                                                                    var m = 'Ore di lavoro:' + totalhLav + ' Costo orario:' + costoOrarioMsg

                                                                    this.onChangeInputNumCommon('Stipendio', Stipendio)
                                                                    this.onChangeInputCommon('DescrizioneStipendio', m)
                                                                    this.onChangeInputCommon('DataCalcoloStipendio', DateTool.returnItalianTime())
                                                                    this.calcSaldo(Stipendio, this.state.formValue['Acconto1'], this.state.formValue['Acconto2'], this.state.formValue['Acconto3'], this.state.formValue['Acconto4'], this.state.formValue['Acconto5'], this.state.formValue['AccontoConfermato1'], this.state.formValue['AccontoConfermato2'], this.state.formValue['AccontoConfermato3'], this.state.formValue['AccontoConfermato4'], this.state.formValue['AccontoConfermato5'])



                                                                } else {

                                                                    //ほんとのエラー
                                                                    this.onChangeInputNumCommon('Stipendio', 0)
                                                                    this.onChangeInputCommon('DescrizioneStipendio', 'Si è verificato un errore durante il calcolo dello stipendio.')

                                                                    this.onChangeInputNumCommon('Saldo', '')   //残高クリア
                                                                }
                                                                this.setState({ existEmptydata: existEmptydata })
                                                                this.setState({ apiCall: 'end' })
                                                            })


                                                    }}>Calcolo dello stipendio</Button>

                                            </Grid>



                                            {this.state.apiCall == 'start'
                                                ? <div>
                                                    <CircularProgress /><br /><br /><br />
                                                </div>
                                                : <div>



                                                    <Grid item xs={12} key={'form_Stipendio'} className={classes.formControlM} >
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            alignItems="flex-end">
                                                            <Grid item xs={6} >
                                                                <FormControl className={classes.formControlS}>
                                                                    <TextField
                                                                        id="standard-basic"
                                                                        label='Stipendio*'
                                                                        value={this.state.formValue['Stipendio']}
                                                                        onChange={(e) => {
                                                                            e.preventDefault()

                                                                            //Stipendioの手入力
                                                                            this.onChangeInputNumCommon('Stipendio', e.target.value)
                                                                            this.onChangeInputCommon('DataCalcoloStipendio', null)   //残高クリア
                                                                            this.onChangeInputCommon('DescrizioneStipendio', null)   //残高クリア
                                                                            this.calcSaldo(e.target.value, this.state.formValue['Acconto1'], this.state.formValue['Acconto2'], this.state.formValue['Acconto3'], this.state.formValue['Acconto4'], this.state.formValue['Acconto5'], this.state.formValue['AccontoConfermato1'], this.state.formValue['AccontoConfermato2'], this.state.formValue['AccontoConfermato3'], this.state.formValue['AccontoConfermato4'], this.state.formValue['AccontoConfermato5'])
                                                                            this.setState({ existEmptydata: null })

                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={6} >
                                                                <FormControl className={classes.formControlS}>
                                                                    {this.state.formValue['DataCalcoloStipendio'] &&
                                                                        <TextField
                                                                            id="standard-basic"
                                                                            label='Data di calcolo'
                                                                            value={StrTool.createStrDD_MM_YYYY(this.state.formValue['DataCalcoloStipendio'])}
                                                                            disabled
                                                                        />

                                                                    }

                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item xs={12} className={classes.formControlM} >
                                                        {this.state.formValue['DescrizioneStipendio']}
                                                    </Grid>
                                                    {this.state.existEmptydata &&
                                                        <Grid item xs={12} className={classes.formControlM} >
                                                            {this.state['existEmptydata']}
                                                        </Grid>
                                                    }


                                                </div>}

                                            <RowAccontoForForm
                                                index={1}
                                                formValue={this.state.formValue}
                                                onChangeInputNumCommon={(k, v) => { this.onChangeInputNumCommon(k, v) }}
                                                calcSaldo={(v) => {
                                                    this.calcSaldo(this.state.formValue['Stipendio'],
                                                        v,
                                                        this.state.formValue['Acconto2'],
                                                        this.state.formValue['Acconto3'],
                                                        this.state.formValue['Acconto4'],
                                                        this.state.formValue['Acconto5'], 
                                                        this.state.formValue['AccontoConfermato1'], 
                                                        this.state.formValue['AccontoConfermato2'], 
                                                        this.state.formValue['AccontoConfermato3'], 
                                                        this.state.formValue['AccontoConfermato4'], 
                                                        this.state.formValue['AccontoConfermato5'])
                                                }
                                                }
                                            />
                                            <RowAccontoForForm
                                                index={2}
                                                formValue={this.state.formValue}
                                                onChangeInputNumCommon={(k, v) => { this.onChangeInputNumCommon(k, v) }}
                                                calcSaldo={(v) => {
                                                    this.calcSaldo(this.state.formValue['Stipendio'],
                                                        this.state.formValue['Acconto1'],
                                                        v,
                                                        this.state.formValue['Acconto3'],
                                                        this.state.formValue['Acconto4'],
                                                        this.state.formValue['Acconto5'],
                                                        this.state.formValue['AccontoConfermato1'], 
                                                        this.state.formValue['AccontoConfermato2'], 
                                                        this.state.formValue['AccontoConfermato3'], 
                                                        this.state.formValue['AccontoConfermato4'], 
                                                        this.state.formValue['AccontoConfermato5'])
                                                }
                                                }
                                            />
                                            <RowAccontoForForm
                                                index={3}
                                                formValue={this.state.formValue}
                                                onChangeInputNumCommon={(k, v) => { this.onChangeInputNumCommon(k, v) }}
                                                calcSaldo={(v) => {
                                                    this.calcSaldo(this.state.formValue['Stipendio'],
                                                        this.state.formValue['Acconto1'],
                                                        this.state.formValue['Acconto2'],
                                                        v,
                                                        this.state.formValue['Acconto4'],
                                                        this.state.formValue['Acconto5'],
                                                        this.state.formValue['AccontoConfermato1'], 
                                                        this.state.formValue['AccontoConfermato2'], 
                                                        this.state.formValue['AccontoConfermato3'], 
                                                        this.state.formValue['AccontoConfermato4'], 
                                                        this.state.formValue['AccontoConfermato5'])
                                                }
                                                }
                                            />
                                            <RowAccontoForForm
                                                index={4}
                                                formValue={this.state.formValue}
                                                onChangeInputNumCommon={(k, v) => { this.onChangeInputNumCommon(k, v) }}
                                                calcSaldo={(v) => {
                                                    this.calcSaldo(this.state.formValue['Stipendio'],
                                                        this.state.formValue['Acconto1'],
                                                        this.state.formValue['Acconto2'],
                                                        this.state.formValue['Acconto3'],
                                                        v,
                                                        this.state.formValue['Acconto5'],
                                                        this.state.formValue['AccontoConfermato1'], 
                                                        this.state.formValue['AccontoConfermato2'], 
                                                        this.state.formValue['AccontoConfermato3'], 
                                                        this.state.formValue['AccontoConfermato4'], 
                                                        this.state.formValue['AccontoConfermato5'])
                                                }
                                                }
                                            />
                                            <RowAccontoForForm
                                                index={5}
                                                formValue={this.state.formValue}
                                                onChangeInputNumCommon={(k, v) => { this.onChangeInputNumCommon(k, v) }}
                                                calcSaldo={(v) => {
                                                    this.calcSaldo(this.state.formValue['Stipendio'],
                                                        this.state.formValue['Acconto1'],
                                                        this.state.formValue['Acconto2'],
                                                        this.state.formValue['Acconto3'],
                                                        this.state.formValue['Acconto4'],
                                                        v,
                                                        this.state.formValue['AccontoConfermato1'], 
                                                        this.state.formValue['AccontoConfermato2'], 
                                                        this.state.formValue['AccontoConfermato3'], 
                                                        this.state.formValue['AccontoConfermato4'], 
                                                        this.state.formValue['AccontoConfermato5'])
                                                }
                                                }
                                            />

                                            <Grid item xs={12} key={'form_Saldo'}  >
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-end"
                                                    className={classes.formControlM}>
                                                    <Grid item xs={6} >
                                                        <FormControl className={classes.formControlS}>
                                                            <TextField
                                                                id="standard-basic"
                                                                label='Saldo'
                                                                value={this.state.formValue['Saldo']}
                                                                disabled

                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6} >

                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} >
                                                <Grid container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-end"
                                                    className={classes.formControlM}>
                                                    <Grid item xs={6} key={'form_DataPag'}  >
                                                        <FormControl className={classes.formControlS}>
                                                            <MuiThemeProvider theme={customTheme}>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                                                                    <KeyboardDatePicker
                                                                        margin="normal"
                                                                        id="date-picker-dialog"
                                                                        label='Data di pagamento'
                                                                        format="dd/MM/yyyy"
                                                                        value={this.state.formValue['DataPag']}
                                                                        onChange={(e) => { this.handleDateTimeChange(e, 'DataPag') }}
                                                                        KeyboardButtonProps={{
                                                                            'aria-label': 'change date',
                                                                        }}
                                                                        disabled={this.state.formValue['PagatoConfermato'] ? true : false} //confermatoの場合は修正できない
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </MuiThemeProvider>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}  >
                                                        {this.state.formValue['PagatoConfermato']

                                                            ? <FormControl className={classes.formControlS}>
                                                                <TextField
                                                                    id="standard-basic"
                                                                    label='Confermato'
                                                                    value={StrTool.createStrDD_MM_YYYY(this.state.formValue['PagatoConfermato'])}
                                                                    disabled
                                                                />
                                                            </FormControl>
                                                            : this.state.formValue.DataPag != null && this.state.formValue.DataPag
                                                                ? <div>Da confermare</div>
                                                                : null}

                                                    </Grid>




                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} className={classes.formControlM} >
                                                <FormControl>
                                                    <TextField
                                                        id="standard-basic"
                                                        label='Descrizione'
                                                        value={this.state.formValue['Descrizione']}
                                                        onChange={(e) => {
                                                            e.preventDefault()
                                                            this.onChangeInputCommon('Descrizione', e.target.value)
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} >
                                                <Button
                                                    variant="contained"
                                                    disabled={this.state.unfilledRequiredFIeld}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        var newFormValue = this.state.formValue;

                                                        if (this.state.formValue.Acconto == '') {
                                                            newFormValue["Acconto"] = null
                                                        }

                                                        if (this.state.formValue.Saldo == '') {
                                                            newFormValue["Saldo"] = null
                                                        }


                                                        this.setState({ formValue: newFormValue });

                                                        console.log('formValue', this.state.formValue)

                                                        this.props.submitCreate(
                                                            this.props.logininfo.userId,
                                                            this.state.page,
                                                            this.state.numData,
                                                            this.state.formValue,
                                                            this.props.logininfo.id)
                                                        this.handleModalClose();


                                                    }}>Aggiorna</Button>
                                            </Grid>
                                        </Grid >
                                    </form>
                                </Grid>
                            </Grid>



                        </div>


                    </Fade>
                </Modal >
            </div>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        logininfo: state.auth.logininfo,
        role: state.auth.role

    }
}

const mapDispatchToProps = (dispatch) => {

    return {

        submitCreate: (userId, page, numData, formValue, access_token) => {
            dispatch(StipendiAction.apiPostOrPut(userId, page, numData, formValue, access_token))
        },
        getStipendiFromPresenza: (userId, dal, al, access_token, cb) => {
            dispatch(PresenzeAction.apiGet_stipendi_from_presenza(userId, dal, al, access_token, cb))
        },

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SimpleCrudModalUpdate));