export const convertMonth = (num) => {


    var month = '';

    switch (num) {
        case 1:
            month = 'Gennaio';
            break;
        case 2:
            month = 'Febbraio';
            break;
        case 3:
            month = 'Marzo';
            break;
        case 4:
            month = 'Aprile';
            break;
        case 5:
            month = 'Maggio';
            break;
        case 6:
            month = 'Giugno';
            break;
        case 7:
            month = 'Luglio';
            break;
        case 8:
            month = 'Agosto';
            break;
        case 9:
            month = 'Settembre';
            break;
        case 10:
            month = 'Ottobre';
            break;
        case 11:
            month = 'Novembre';
            break;
        case 12:
            month = 'Dicembre';
            break;

    }
    return month;
}


export const createStrYYYYMMDDHHMMSS = (date) => {

    var d = date;
    var str = "_" + d.getFullYear();
    var arr = [(d.getMonth() + 1), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()]

    arr.forEach((e) => {
        if (e < 10) {
            str = str + "0" + e;
        } else {
            str = str + e;
        }
    })
    return str;
}

export const createStrYYYY_MM_DD = (date) => {

    if (null == date) {
        return ""
    } else {
        var d = new Date(date);
        var str = d.getFullYear();
        var arr = [(d.getMonth() + 1), d.getDate()]

        arr.forEach((e) => {
            if (e < 10) {
                str = str + "-0" + e;
            } else {
                str = str + "-" + e;
            }
        })
        return str;
    }


}

export const createStrDD_MM_YYYY = (date) => {

    if (null == date) {
        return ""
    } else {
        var d = new Date(date);
        var str = ""
        var arr = [d.getDate(), (d.getMonth() + 1), d.getFullYear()]

        var first = true
        arr.forEach((e) => {
            if (first) {
                first = false;
            } else {
                str = str + "-"
            }

            if (e < 10) {
                str = str + "0" + e;
            } else {
                str = str + "" + e;
            }
        })
        return str;
    }


}


export const validateEmail = (inputValue) =>{

    var result = false;

    const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if(regex.test(inputValue)){
        result = true;
    }

    if(inputValue == ''){
        result = true;
    }

    return result;
}