import { Decimal } from 'decimal.js';

export const calcMultiple = (num1, num2) => {

    console.log('calcMultiple', num1, num2)

    let d1 = new Decimal(num1);
    let d2 = new Decimal(num2);

    console.log('calcMultiple', d1.mul(d2).toNumber())

    return d1.mul(d2).toNumber();
}



export const calcSubtraction = (num1, num2) => {

    if(num1 == null || num1 == ''){
        num1 = 0;
    }
    if(num2 == null|| num2 == ''){
        num2 = 0;
    }

    let d1 = new Decimal(num1);
    let d2 = new Decimal(num2);

    return d1.sub(d2).toNumber();
}

export const calcAdd = (num1, num2) => {

    console.log('calcAdd', num1, num2)

    let d1 = new Decimal(num1);
    let d2 = new Decimal(num2);

    return d1.add(d2).toNumber();
}



export const validateAndSetNewFormValue = (key, value, formValue) => {

    //数値だとchkValue.matchでエラーとなるため
    var chkValue = value + ''

    var oldvalue = formValue[key]
    if (oldvalue == null) {
        oldvalue = ''
    }

    var newFormValue = formValue;

    if (chkValue == "" ||
        chkValue.match(/^[0-9]+$/) ||
        chkValue.match(/^[0-9]+\.[0-9]+$/) ||
        chkValue.match(/^[0-9]+\.$/)) {

        console.log('数値許容')

        //CheckNumが符号の付いていない小数の場合の処理
        newFormValue[key] = value;
    } else {
        //許容しない
        newFormValue[key] = oldvalue;
    }


    console.log('newFormValue validateAndSetNewFormValue', newFormValue)
    return newFormValue

}
